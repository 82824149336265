import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {
  getSavedOfferObject,
  removeSelectedOfferItem,
  savedOfferCount,
  selectedOfferItems,
} from "../../store/actions/savedOfferAction";
import { withSnackbar } from "notistack";
import imageStyles from "../../styling/imageStyles";
import { Typography } from "@mui/material";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import { updatePageNumber } from "../../store/actions/filterFormDataAction";
import {
  getOfferDetailsObject,
  getOfferDetailsSuccess,
} from "../../store/actions/offerDetailsAction";
import ImagePopUp from "./imagePopUp";
import ReactPaginatePagination from "./pagination";
import ApiCalls from "../../components/ApiList/apiCalls";
import {
  exportOffersList,
  exportSavedOffersList,
  removeItemFromExportOffersList,
  removeItemFromExportSavedOffersList,
} from "../../store/actions/exportSavedOfferAction";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  removeCartSelectedOfferItem,
  removeSavedOfferObject,
  selectedCartOfferItems,
} from "../../store/actions/removeSavedOfferAction";

import { Grid } from "@mui/material";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import addToSaveOfferIcon from "../../images/addToSaveOffer.svg";
import addedToSavedOfferIcon from "../../images/addedToSavedOffer.svg";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import {
  compareOfferList,
  removeItemFromCompareOffersList,
} from "../../store/actions/compareOfferAction";
import compareButtonIconMainScreeen from "../../images/compareButtonIconMainScreeen.svg";
import compareButtonIconMainScreeenFilled from "../../images/compareButtonIconMainScreeenFilled.svg";
import Tooltip from "@mui/material/Tooltip";
import "./offerData.css";
import { updateSavedOfferCount } from "../../store/actions/userManagerAction";
import Loader from "../CommonFiles/Loader";

const ApiList = new ApiCalls();
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
class GetImage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      open: false,
      selected: false,
      loading: false,
      displayAttribute:"",
    };
  }
  componentDidMount() {
    document.removeEventListener("removecount", this.onChange);
    document.addEventListener("removecount", this.onChange.bind(this));
  }
  onChange(ev) {
    this.props.updateSavedOfferCount(ev.detail.removeCount);
  }
  // componentDidUpdate(prevProps) {
  //   //const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
  //   if (
  //     this.props.formData.pageno !== prevProps.formData.pageno ||
  //     this.props.formData.sorting !== prevProps.formData.sorting
  //   ) {
  //     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   }
  // }

  handleClickOpen = async (offerImageId) => {
    await this.props.getOfferDetailsObject(offerImageId);
    this.setState({ open: true });
  };

  handleClose = (boolValue) => {
    this.setState({ open: false });
    this.props.getOfferDetailsSuccess({});
    this.setState({ loading: false });
  };

  handleCheckBoxForOffers = async (event, item) => {
    if (event.target.checked) {
      this.props.selectedOfferItems(item.id);
      this.props.exportOffersList(item);
    } else {
      this.props.removeSelectedOfferItem(item.id);
      this.props.removeItemFromExportOffersList(item);
    }
  };

  handleCheckBoxForSavedOffers = (event, item) => {
    if (event.target.checked) {
      this.props.exportSavedOffersList(item);
      this.props.selectedCartOfferItems(item);
    } else {
      this.props.removeItemFromExportSavedOffersList(item);
      this.props.removeCartSelectedOfferItem(item);
    }
  };

  itemForExport = () => {
    const itemForExportList = this.props.offerData.filter(
      (offer) => offer.id === this.props.offerDetails.objOffferDetail.id
    );
    return itemForExportList;
  };

  savedOffersChecked = (itemId) => {
    const selectedOfferItemIds = this.props.savedOffersForRemoveList.map(
      (offer) => offer.id
    );
    if (selectedOfferItemIds.includes(itemId)) {
      return true;
    }
    return false;
  };

  splitDate = (date) => {
    const dateValues = date.split("/");
    const dateUptoMonth = dateValues[0] + "/" + dateValues[1];
    return dateUptoMonth;
  };

  render() {
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const {
      formData,
      offerData,
      viewBy,
      savedOffer,
      getSavedOfferObject,
      enqueueSnackbar,
      selectedItems,
      offerDetails,
      savedOffersList,
      savedOfferDataSorting,
      compareOfferData,
      cartOfferData,
      pageNumber,
      cartLoading,
    } = this.props;
    if (offerData) {
      const CompareDataIds = this.props.compareOfferData.map(
        (offer) => offer.id || offer.id
      );
      return (
        <>
          <Loader open={this.state.loading} />
          {offerData.length && this.props.offerDetails ? (
            <div ref={this.myRef} className="offer-data-main-grid">
              <Grid container spacing={2} align="center">
                {offerData?.map((offer) => (
                  <Grid key={offer.id} item xs={2.4}>
                    <Card
                      sx={{
                        height: !viewBy ? 297 : 199,
                        position: "relative",
                        border: "0.75px solid #CACACA",
                        boxShadow:
                          "0px 0px 13.1364px -2.31819px rgba(0, 0, 0, 0.18)",
                      }}
                    >
                      <div style={imageStyles.imageListDivStyle}>
                        {prmissionData && prmissionData.isAllowOfferCompare ? (
                          <Tooltip
                            title={
                              !CompareDataIds.includes(offer.id)
                                ? "Add to compare"
                                : "Remove from compare"
                            }
                            TransitionProps={{ timeout: 200 }}
                          >
                            <div
                              className="Image-CompareDiv"
                              style={{
                                backgroundColor: !CompareDataIds.includes(
                                  offer.id
                                )
                                  ? "#FFF"
                                  : "#0070C0",
                                borderRadius: "2px",
                              }}
                              onClick={async () => {
                                if (!CompareDataIds.includes(offer.id)) {
                                  if (compareOfferData.length <= 3) {
                                    this.props.compareOfferList(offer);
                                  } else {
                                    const message =
                                      "Only 4 offers can be selected";
                                    enqueueSnackbar(message, {
                                      variant: "error",
                                    });
                                  }
                                } else {
                                  this.props.removeItemFromCompareOffersList(
                                    offer.id
                                  );
                                }

                                this.setState({
                                  selected: !this.state.selected,
                                });
                              }}
                            >
                              {CompareDataIds.includes(offer.id) ? (
                                <img src={compareButtonIconMainScreeenFilled} />
                              ) : (
                                <img src={compareButtonIconMainScreeen} />
                              )}
                            </div>
                          </Tooltip>
                        ) : null}

                        {/* )} */}
                        <div>
                          <Checkbox
                            sx={{
                              "&:hover": {
                                color: "#E4EEFF",
                                backgroundColor: "#E4EEFF",
                              },
                              "& .MuiSvgIcon-root": {
                                color: "#0070C0",
                                height: "21px",
                                width: "21px",
                              },
                              backgroundColor: "#FFF",
                            }}
                            style={imageStyles.checkBoxStyling}
                            checked={
                              !savedOffersList
                                ? selectedItems.includes(offer.id)
                                : this.savedOffersChecked(offer.id)
                            }
                            onChange={(event) => {
                              if (!savedOffersList) {
                                this.handleCheckBoxForOffers(event, offer);
                              } else {
                                this.handleCheckBoxForSavedOffers(event, offer);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <CardMedia
                        onClick={() => {
                          this.setState({ loading: true });
                          this.setState({ displayAttribute: offer.attributes.length>0?offer.attributes:"NA" });
                          this.handleClickOpen(offer.id);
                        }}
                        sx={{ objectFit: "contain", cursor: "pointer" }}
                        component="img"
                        height="199"
                        image={
                          savedOffersList
                            ? `${offer.image}`
                            : `${ApiList.getImageUrl() + offer.image}`
                        }
                        alt="green iguana"
                      />
                      {!this.props.viewBy ? (
                        <>
                          <CardContent style={{ padding: "0px" }}>
                            <Tooltip title={offer.nameEn}>
                              <p className="Image-Offer-Name">
                                {offer.nameEn.length > 52
                                  ? `${offer.nameEn.substring(0, 52)}...`
                                  : `${offer.nameEn}`}
                              </p>
                            </Tooltip>

                            <Grid
                              align="left"
                              container
                              sx={{
                                paddingLeft: "10px",
                                position: "absolute",
                                bottom: "45px",
                                left: "0px",
                                height: "28px",
                              }}
                            >
                              <Grid item xs={6}>
                                <span style={{ display: "inline-grid" }}>
                                  {offer.regularPrice > 0 ? (
                                    <span className="Image-Regular-Price">
                                      {offer.currency}&nbsp;
                                      {offer.regularPrice}
                                    </span>
                                  ) : (
                                    <span className="Image-Regular-Price"></span>
                                  )}
                                  {offer.promoPrice > 0 ? (
                                    <span className="Image-Promo-Price">
                                      {offer.currency}&nbsp;
                                      {offer.promoPrice}
                                    </span>
                                  ) : (
                                    <span className="Image-Promo-Price"></span>
                                  )}
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  marginTop: ".6rem",
                                }}
                              >
                                <div className="Image-Discount-ribbon-design"></div>

                                {offer.discount ? (
                                  <p className="Image-Discount-ribbon-background">
                                    <span className="Image-Discount-ribbon-text">
                                      {Math.ceil(offer.discount)}% Off
                                    </span>
                                  </p>
                                ) : null}
                              </Grid>
                            </Grid>
                          </CardContent>
                          <Grid
                            container
                            className="offer-grid-retailer-logo-section"
                          >
                            <Grid item xs={1.5}>
                              <img
                                style={{ width: "27px", height: "27px" }}
                                className="Image-logo"
                                src={
                                  savedOffersList
                                    ? `${offer.logo}`
                                    : `${
                                        ApiList.getRetailerImageUrl() +
                                        offer.logo
                                      }`
                                }
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <p className="Image-OfferDate">
                                {`From ${this.splitDate(
                                  offer.offerStartDate
                                )}  To  ${this.splitDate(offer.offerEndDate)}`}
                              </p>
                            </Grid>

                            <Grid item xs={1.5}>
                              {!this.props.savedOffersList ? (
                                <>
                                  <img
                                    className="offer-data-add-to-save-offer-or-remove-icon"
                                    src={
                                      Number(offer.shopCartId) === 0
                                        ? addToSaveOfferIcon
                                        : addedToSavedOfferIcon
                                    }
                                    onClick={async () => {
                                      const condition =
                                        Number(offer.shopCartId) === 0;
                                      if (condition) {
                                        await getSavedOfferObject(offer.id);
                                        await this.props.getOfferByUserPermission(
                                          "main",
                                          true
                                        );
                                      } else {
                                        this.props.removeSavedOfferObject(
                                          offer.id
                                        );
                                        await this.props.getOfferByUserPermission(
                                          "main",
                                          true
                                        );
                                      }

                                      const message = condition
                                        ? `${offer.nameEn} Added to Saved Offer Successfully`
                                        : `${offer.nameEn} Removed from Saved Offers.`;
                                      enqueueSnackbar(message, {
                                        variant: condition
                                          ? "success"
                                          : "error",
                                      });
                                    }}
                                  />
                                </>
                              ) : (
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={async () => {
                                    await this.props.removeSavedOfferObject(
                                      offer.id
                                    );
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {JSON.stringify(this.props.offerDetails) != "{}" ? (
                offerDetails.objOffferDetail ? (
                  <ImagePopUp
                    savedOfferGrid={false}
                    handleClickOpen={this.handleClickOpen}
                    open={this.state.open}
                    offerDetails={offerDetails}
                    offerDetailsLoading={this.props.offerDetailsLoading}
                    savedOffer={savedOffer}
                    getSavedOfferObject={getSavedOfferObject}
                    enqueueSnackbar={enqueueSnackbar}
                    handleClose={this.handleClose}
                    itemForExport={this.itemForExport()}
                    attribute={this.state.displayAttribute}
                  />
                ) : null
              ) : null}
            </div>
          ) : (
            <div
              style={{
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <h1>
                <b>No Data Found</b>
              </h1>
            </div>
          )}
        </>
      );
    }
    return (
      <Typography sx={{ mt: 5 }} variant="subtitle1" align="center">
        No Data Found
      </Typography>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    savedOffer: state.savedOfferData.savedOfferObject.saveOfferObj,
    selectedItems: state.savedOfferData.selectedOfferItems,
    savedOffersForRemoveList: state.removeSavedOffer.selectedCartOfferItems,
    viewBy: state.userPermissionOfferData.viewBy,
    offerDetails: state.getOfferDetails.offerDetails,
    offerDetailsLoading: state.getOfferDetails.loading,
    exportOfferItems: state.exportSavedOffer.exportOfferList,
    exportSavedOfferItems: state.exportSavedOffer.exportSavedOffersList,
    removeSavedOfferResponseCount:
      state.removeSavedOffer.removeOfferObject.saveOfferCount,
    savedOfferCountAfterRemove:
      state.removeSavedOffer.removeOfferObject.saveOfferCount,
    loading: state.removeSavedOffer.loading,
    offerCount: state.savedOfferData.savedOfferCount,
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    loadingByOfferPermission: state.userPermissionOfferData.loading,
    compareOfferData: state.compareOffer.compareOfferList,
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
    cartLoading: state.postSavedOfferData.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSavedOfferObject: (itemId) => dispatch(getSavedOfferObject(itemId)),
    selectedOfferItems: (itemId) => dispatch(selectedOfferItems(itemId)),
    removeSelectedOfferItem: (itemId) =>
      dispatch(removeSelectedOfferItem(itemId)),
    updatePageNumber: (newValue) => dispatch(updatePageNumber(newValue)),
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission("main", true)),
    getOfferDetailsObject: (offerId) =>
      dispatch(getOfferDetailsObject(offerId)),
    exportOffersList: (offerItem) => dispatch(exportOffersList(offerItem)),
    exportSavedOffersList: (offerItem) =>
      dispatch(exportSavedOffersList(offerItem)),
    removeItemFromExportOffersList: (item) =>
      dispatch(removeItemFromExportOffersList(item)),
    removeItemFromExportSavedOffersList: (item) =>
      dispatch(removeItemFromExportSavedOffersList(item)),
    selectedCartOfferItems: (savedOfferItem) =>
      dispatch(selectedCartOfferItems(savedOfferItem)),
    removeCartSelectedOfferItem: (offerItem) =>
      dispatch(removeCartSelectedOfferItem(offerItem)),
    removeSavedOfferObject: (itemId) =>
      dispatch(removeSavedOfferObject(itemId)),
    savedOfferCount: (count) => dispatch(savedOfferCount(count)),
    postSavedOfferObject: (postObj) =>
      dispatch(postSavedOfferObject(postObj, true)),
    compareOfferList: (offerItem) => dispatch(compareOfferList(offerItem)),
    removeItemFromCompareOffersList: (offer) =>
      dispatch(removeItemFromCompareOffersList(offer)),
    getOfferDetailsSuccess: (offer) => dispatch(getOfferDetailsSuccess(offer)),
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GetImage));
