import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_VIEW,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const fetchProfileDataRequest = () => {
  return {
    type: GET_PROFILE_REQUEST,
  };
};

export const fetchProfileDataSuccess = (profileData) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profileData,
  };
};

export const fetchProfileDataFailure = (error) => {
  return {
    type: GET_PROFILE_FAILURE,
    payload: error,
  };
};
export const updateProfileView = (profileView) => {
  return {
    type: UPDATE_PROFILE_VIEW,
    payload: profileView,
  };
};
export const getProfileData = (loginId) => {
  return (dispatch) => {
    dispatch(fetchProfileDataRequest);
    ApiList.getProfile(loginId)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const ProfileData = response.data;
        dispatch(fetchProfileDataSuccess(ProfileData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(fetchProfileDataFailure(error.message));
      });
  };
};
