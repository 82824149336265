import React, { Component } from "react";
import { withSnackbar } from "notistack";
import ApiCalls from "../ApiList/apiCalls";
import changePasswordIcon from "../../images/changePasswordIcon.svg";
import validator from "validator";

const ApiList = new ApiCalls();
const userData = JSON.parse(localStorage.getItem("userData"));
class ForgetPassword extends Component {
  constructor(props) {
    super(props);
  }
  state = { currentPassword: "", newPassword: "", confirmPassword: "" };

  validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = (event) => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      const message = "Please Enter Same Password";
      this.props.enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    }
    if (currentPassword === newPassword) {
      const message = "Current and new password is not same";
      this.props.enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    }
    if (this.validate(newPassword)) {
      ApiList.getPasswordCheck(currentPassword).then((response) => {
        if (response.data.status) {
          const postObj = {
            userId: userData.userId,
            password: currentPassword,
            newPassword: newPassword,
          };
          ApiList.resetPassword(postObj).then(
            (response) => {
              if (
                typeof response.data.status != "undefined" &&
                !response.data.status
              ) {
                this.props.enqueueSnackbar(response.data.message, {
                  variant: "error",
                });
              }
              if (!response.data) {
                const message = "Incorrect Password";
                this.props.enqueueSnackbar(message, {
                  variant: "error",
                });
              } else {
                const message = "Password Changed Successfully.";
                this.props.enqueueSnackbar(message, {
                  variant: "success",
                });
                this.setState({
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                });
              }
            },
            (error) => {
              const message = error;
              this.props.enqueueSnackbar(message, {
                variant: "error",
              });
            }
          );
        } else {
          const message = response.data.message;
          this.props.enqueueSnackbar(message, {
            variant: "error",
          });
          return;
        }
      });
    } else {
      const message = "Please Enter a new valid Password";
      this.props.enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    }
  };

  handleSignout = () => {
    localStorage.setItem("Flag", JSON.stringify(false));
    localStorage.setItem("userData", "{}");
    window.location.href = "/logout";
    localStorage.removeItem("userPermissionData");
  };

  render() {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <div className="change-password-avatar-div">
              <img src={changePasswordIcon} />
              <span className="change-password-heading">Change Password</span>
              <br />
            </div>
            <br />
            <div className="change-password-main-section">
              <div className="change-pass-div">
                <p className="change-pass-sub-heading">
                  Current Password<span style={{ color: "red" }}>*</span>:
                </p>
                <input
                  className="change-pass-value"
                  name="currentPassword"
                  value={currentPassword}
                  type="password"
                  placeholder="Current Password"
                  required={true}
                  onChange={(e) => {
                    this.setState({ currentPassword: e.target.value });
                  }}
                />
              </div>
              <div className="new-password-div">
                <p className="new-password-sub-heading">
                  New Password<span style={{ color: "red" }}>*</span>:
                </p>

                <input
                  className="new-password-value"
                  name="newPassword"
                  type="password"
                  value={newPassword}
                  required={true}
                  placeholder="New Password"
                  onChange={(e) => {
                    this.setState({ newPassword: e.target.value });
                  }}
                />
              </div>
              <div className="confirm-password-div">
                <p className="confirm-password-sub-heading">
                  Confirm Password:
                </p>
                <input
                  className="confirm-password-value"
                  name="confirmPassword"
                  value={confirmPassword}
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    this.setState({ confirmPassword: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="change-pass-button-section">
            <button
              className="change-pass-sign-out"
              onClick={this.handleSignout}
            >
              Sign Out
            </button>
            <button
              className="change-pass-update-change"
              variant="contained"
              type="submit"
            >
              Update Change
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withSnackbar(ForgetPassword);
