import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMinToMaxSelectedWeekNumber,
  getSelectedWeekNumbers,
  getWeekNumberObject,
  removeSelectedWeekNumbers,
} from "../../store/actions/getWeekNumberAction";
import moment from "moment/moment";
import {
  updateFromDate,
  updateToDate,
} from "../../store/actions/filterFormDataAction";
import UpdateJson from "../CommonFiles/UpdateJson";
class WeekNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minweek: "",
      maxweek: "",
    };
  }

  updatingDate = async (maxNumber, checked) => {
    const selectedNumbers = this.props.selectedWeekNumbers.map((week) =>
      Number(week)
    );
    var selectionDirection = 1; // 0 for down side and 1 for upside

    if (selectedNumbers.length > 0) {
      const minWeekNumber = selectedNumbers.reduce((a, b) => Math.min(a, b));
      const maxWeekNumber = selectedNumbers.reduce((a, b) => Math.max(a, b));
      if (maxNumber <= minWeekNumber) {
        selectionDirection = 1;
      } else {
        selectionDirection = 0;
      }

      this.setState({ minweek: minWeekNumber });
      this.setState({ maxweek: maxWeekNumber });
      const weekNumberValues = this.props.weekNumbers.map(
        (item) => item.weakNumber
      );
      const indexOfMinWeekNumber = weekNumberValues.indexOf(minWeekNumber);
      if (selectedNumbers.includes(maxNumber)) {
        var indexOfMaxWeekNumber = weekNumberValues.indexOf(maxWeekNumber);
      } else {
        if (selectionDirection == 0) {
          var indexOfMaxWeekNumber = weekNumberValues.indexOf(maxNumber);
        } else {
          var indexOfMaxWeekNumber = weekNumberValues.indexOf(maxWeekNumber);
        }
      }
      debugger;
      const minToMaxSelectedWeekNumber = weekNumberValues.slice(
        indexOfMinWeekNumber,
        checked
          ? indexOfMaxWeekNumber + 1
          : selectionDirection == 0
          ? indexOfMaxWeekNumber
          : indexOfMaxWeekNumber + 1
      );
      const minValue = minToMaxSelectedWeekNumber.reduce((a, b) =>
        Math.min(a, b)
      );
      const maxValue = minToMaxSelectedWeekNumber.reduce((a, b) =>
        Math.max(a, b)
      );
      const minWeekNumberObject = this.props.weekNumbers.find(
        (item) => item.weakNumber == minValue
      );
      const maxWeekNumberObject = this.props.weekNumbers.find(
        (item) => item.weakNumber == maxValue
      );
      UpdateJson([
        {
          key: "fromDate",
          value: moment(new Date(minWeekNumberObject.fromDate)).format(
            "DD/MM/YYYY"
          ),
        },
        {
          key: "toDate",
          value: moment(new Date(maxWeekNumberObject.toDate)).format(
            "DD/MM/YYYY"
          ),
        },
      ]);
      this.props.handleSelectedWeekNumer(minToMaxSelectedWeekNumber.toString());
      await this.props.getMinToMaxSelectedWeekNumber(
        minToMaxSelectedWeekNumber
      );
    } else {
      this.setState({ minweek: "" });
      this.setState({ maxweek: "" });
      this.props.handleSelectedWeekNumer("");
      UpdateJson([
        {
          key: "fromDate",
          value: moment(new Date()).format("DD/MM/YYYY"),
        },
        {
          key: "toDate",
          value: moment(new Date(this.getPreviousDate())).format("DD/MM/YYYY"),
        },
      ]);
    }
  };
  getPreviousDate = (date = new Date()) => {
    const previousDate = new Date(date.getTime());
    previousDate.setDate(date.getDate() - 6);
    return previousDate;
  };
  handleWeekChange = async (event) => {
    const { checked, value } = event.target;
    if (checked) {
      var maxNumber;
      await this.props.getSelectedWeekNumbers(Number(value));
      maxNumber = Number(value);
      this.updatingDate(maxNumber, checked);
    } else {
      await this.props.removeSelectedWeekNumbers(Number(value));
      maxNumber = Number(value);
      this.updatingDate(maxNumber, checked);
    }
  };

  weekNumbers = () => {
    const elements = [];
    for (let index = 0; index < this.props.weekNumbers.length; index++) {
      const element = this.props.weekNumbers[index];
      elements.push(
        <FormControlLabel
          checked={this.props.selectedWeekNumbers.includes(element.weakNumber)}
          value={element.weakNumber}
          onChange={this.handleWeekChange}
          key={index}
          sx={{ borderBottom: "1px solid #D8D8D8", paddingBottom: "5px" }}
          className="week-number"
          control={<Checkbox />}
          label={element.weakNumberName}
        />
      );
    }
    return elements;
  };
  render() {
    const { weekNumbers, selectedWeekNumbers, formData } = this.props;
    return (
      <>
        {weekNumbers.length ? (
          <FormGroup className="week-number-form-control" sx={{ ml: 1 }}>
            {this.weekNumbers()}
          </FormGroup>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    weekNumbers: state.getWeekNumber.weekNumbers,
    selectedWeekNumbers: state.getWeekNumber.selectedWeekNumbers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWeekNumberObject: (newValue) => dispatch(getWeekNumberObject(newValue)),
    getSelectedWeekNumbers: (weekNumber) =>
      dispatch(getSelectedWeekNumbers(weekNumber)),
    removeSelectedWeekNumbers: (weekNumber) =>
      dispatch(removeSelectedWeekNumbers(weekNumber)),
    getMinToMaxSelectedWeekNumber: (weekNumber) =>
      dispatch(getMinToMaxSelectedWeekNumber(weekNumber)),
    updateFromDate: (newValue) => dispatch(updateFromDate(newValue)),
    updateToDate: (newValue) => dispatch(updateToDate(newValue)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WeekNumber);
