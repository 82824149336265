const noDataFoundStyling = {
  backgorundStyling: {
    width:"1058px",
    margin: "16px 0px 0px 8px",
    height: "540px",
    background: "linear-gradient(90.14deg, #A7E8A5 -1.44%, #73C0CE 103.87%)",
    opacity: 0.6,
    border: "1px solid #CDCDCD",
    borderRadius: "10px",
  },
  divStyling: {
    transform: "translate(0,70%)",
  },
  bannerTextStyling: {
    marginTop: "16%",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "48px",
    lineHeight: "116.7%",
    color: "#5C5C5C",
  },
  bannerContentStyling: {
    textAlign: "center",

    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "133.4%",
    color: "#5C5C5C",
  },
};
export default noDataFoundStyling;
