import ApiCalls from "../../components/ApiList/apiCalls";
import {
  FETCH_ALL_DATA_BY_PERMISSION_REQUEST,
  FETCH_ALL_DATA_BY_PERMISSION_SUCCESS,
  FETCH_ALL_DATA_BY_PERMISSION__FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getDataByPermission = (userId, isTnC, loading = false) => {
  return (dispatch) => {
    if (loading) {
      dispatch(fetchGetDataByPermissionRequest());
    }
    ApiList.getDataByPermission(userId, isTnC)
      .then(async (response) => {
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
        const allData = response.data;
        await dispatch(fetchGetDataByPermissionSuccess(allData));
        const customEvent = new CustomEvent("dataOnPermission", {
          detail: {
            categoryList: allData.categoryList,
            subCategoryList: allData.subcategoryList,
            itemList: allData.itemList,
          },
        });
        document.dispatchEvent(customEvent);
      })
      .catch((error) => {
        dispatch(fetchGetDataByPermissionFailure(error.message));
      });
  };
};

export const fetchGetDataByPermissionRequest = () => {
  return {
    type: FETCH_ALL_DATA_BY_PERMISSION_REQUEST,
  };
};

export const fetchGetDataByPermissionSuccess = (dataByPermission) => {
  return {
    type: FETCH_ALL_DATA_BY_PERMISSION_SUCCESS,
    payload: dataByPermission,
  };
};

export const fetchGetDataByPermissionFailure = (error) => {
  return {
    type: FETCH_ALL_DATA_BY_PERMISSION__FAILURE,
    payload: error,
  };
};
