import {
  GET_SAVED_OFFER_OBJECT_REQUEST,
  GET_SAVED_OFFER_OBJECT_SUCCESS,
  GET_SAVED_OFFER_OBJECT_FAILURE,
  SELECTED_OFFER_ITEMS,
  CLEAR_SELECTED_OFFER_ITEMS,
  REMOVE_SELECTED_OFFER_ITEMS,
  REMOVE_ALL_OFFERS,
  SELECT_ALL_OFFERS,
  SAVED_OFFERS_COUNT,
  UPDATE_SAVED_OFFER_SORTING,
} from "../actionTypes";

const initialState = {
  savedOfferObject: {},
  loading: false,
  selectedOfferItems: [],
  error: "",
  savedOfferCount: 0,
  savedOfferSorting: { sortType: 5, sortOrder: "L" },
};

const getSavedOfferObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAVED_OFFER_OBJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_SAVED_OFFER_OBJECT_SUCCESS:
      return {
        ...state,
        savedOfferCount: action.payload.saveOfferObj.saveOfferCount,
        loading: false,
        savedOfferObject: action.payload,
        error: "",
      };

    case GET_SAVED_OFFER_OBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        savedOfferObject: {},
        error: action.payload,
      };

    case SELECTED_OFFER_ITEMS:
      const selectedItems = new Set([
        ...state.selectedOfferItems,
        action.payload,
      ]);
      return {
        ...state,
        loading: false,
        selectedOfferItems: [...selectedItems],
        error: "",
      };

    case REMOVE_SELECTED_OFFER_ITEMS:
    
      const index = [...state.selectedOfferItems].indexOf(action.payload);
      let selectedOffersList = [...state.selectedOfferItems];
      selectedOffersList.splice(index, 1);
      return {
        ...state,
        loading: false,
        selectedOfferItems: selectedOffersList,
        error: "",
      };

    case CLEAR_SELECTED_OFFER_ITEMS:
      return { 
        ...state, 
        selectedOfferItems:[] 
      };

    case SELECT_ALL_OFFERS:
      const offerData = action.payload;
      const offers = offerData.map((offer) => offer.id);
      return {
        ...state,
        selectedOfferItems: offers,
      };
    case REMOVE_ALL_OFFERS:
      return {
        ...state,
        selectedOfferItems: [],
      };
    case SAVED_OFFERS_COUNT:
      return {
        ...state,
        savedOfferCount: action.payload,
      };
    case UPDATE_SAVED_OFFER_SORTING: {
      const newsortingvalues = { ...action.payload };
      return {
        ...state,
        savedOfferSorting: newsortingvalues,
      };
    }
    default:
      return state;
  }
};

export default getSavedOfferObjectReducer;
