import React, { Component } from "react";
import ApiCalls from "../ApiList/apiCalls";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import UpdateJson from "../CommonFiles/UpdateJson";
import { connect } from "react-redux";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";

const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const ApiList = new ApiCalls();
const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));

const { allowedRange } = DateRangePicker;

class CalendarView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  componentDidMount() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (userFilterData) {
      this.setState({
        value: [
          new Date(userFilterData.fromDate.split("/").reverse().join("/")),
          new Date(userFilterData.toDate.split("/").reverse().join("/")),
        ],
      });
    } else {
      const initialUserFilterData = ApiList.getInitialState();
      this.setState({
        value: [
          new Date(
            initialUserFilterData.fromDate.split("/").reverse().join("/")
          ),
          new Date(initialUserFilterData.toDate.split("/").reverse().join("/")),
        ],
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.flag !== this.props.flag && prevProps.flag == false) {
      const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
      if (userFilterData) {
        this.setState({
          value: [
            new Date(userFilterData.fromDate.split("/").reverse().join("/")),
            new Date(userFilterData.toDate.split("/").reverse().join("/")),
          ],
        });
      }
    }
  }
  render() {
    return (
      <>
        <section
          className="from-date-dropdown"
          style={{
            visibility: this.props.flag ? "visible" : "hidden",
            width: this.props.flag ? "250px" : "0px",
          }}
        >
          <DateRangePicker
            placeholder="Select Date Range"
            format="dd/MM/yyyy"
            style={{ width: "100%" }}
            ranges={[]}
            shouldDisableDate={allowedRange(
              prmissionData.validFrom,
              prmissionData.validTo
            )}
            cleanable={false}
            editable={false}
            character={" - "}
            value={this.state.value}
            onOk={() => {
              if (window.location.pathname == "/offers") {
                this.props.getOfferByUserPermission();
              } else if (window.location.pathname == "/flyers") {
                this.props.getFlyersPageDataObject(true);
              }
            }}
            onChange={(range) => {
              this.setState({ value: range });
              UpdateJson([
                {
                  key: "fromDate",
                  value: moment(new Date(range[0])).format("DD/MM/YYYY"),
                },
                {
                  key: "toDate",
                  value: moment(new Date(range[1])).format("DD/MM/YYYY"),
                },
              ]);
            }}
          />
        </section>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: (src) =>
      dispatch(getOfferByUserPermission(src, true)),
    getFlyersPageDataObject: (flag) => dispatch(getFlyersPageDataObject(flag)),
  };
};

export default connect(null, mapDispatchToProps)(CalendarView);
