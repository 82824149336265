import {
  SEARCH_SUGGESTION_REQUEST,
  SEARCH_SUGGESTION_SUCCESS,
  SEARCH_SUGGESTION_FAILURE,
  SEARCH_SUGGESTION_FLAG,
} from "../actionTypes";
  
  const initialState = {
    loading: false,
    searchSuggestionData: [],
    suggestionFlag: 0,
    error: "",
  };
  
  const getSearchSuggestionDataReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case  SEARCH_SUGGESTION_REQUEST:
        return {
          ...state,
          loading:true,
          error: "",
        };
  
      case  SEARCH_SUGGESTION_SUCCESS:
        return {
          ...state,
          loading: false,
          searchSuggestionData: action.payload,
          error: "",
        };
      case SEARCH_SUGGESTION_FAILURE:
        return {
          ...state,
          loading: false,
          searchSuggestionData: [],
          error: action.payload,
        };
      case SEARCH_SUGGESTION_FLAG:
        return {
          ...state,
          suggestionFlag: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getSearchSuggestionDataReducer;
  