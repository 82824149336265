import React, { Component } from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import ApiCalls from "../ApiList/apiCalls";
const ApiList = new ApiCalls();

class FlyerDetailGridView extends Component {
  render() {
    const {
      flyerPagesList,
      updateCurrentPage,
      flyerDetailGridView,
      flyerDetail,
    } = this.props;
    return (
      <section className="flyer-detail-grid-view-main-container">
        <Grid container columnSpacing={9} align="center">
          <Grid item xs={3} sx={{ mb: 4 }}>
            <Card
              sx={{
                minHeight: 287,
                width: 182,
                border: "1px solid #AAAAAA",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <CardMedia
                sx={{
                  objectFit: "contain",
                  width: "152px",
                  marginTop: "20px",
                }}
                component="img"
                height="177"
                image={`${ApiList.getRetailerImageUrl()}${
                  flyerDetail.RetailerLogo
                }`}
              />

              <div className="flyer-page-retailer-name">
                <p style={{ fontSize: "14px" }}>
                  {flyerDetail.RetailerName}
                  <br />
                  {flyerPagesList.length === 1
                    ? `${flyerPagesList.length} Page`
                    : `${flyerPagesList.length} Pages`}
                </p>
              </div>
            </Card>
          </Grid>

          {this.props.flyerPagesList.map((flyerPage) => (
            <Grid key={flyerPage.Id} item xs={3} sx={{ mb: 4 }}>
              <Card
                onClick={() => {
                  this.props.handleGridView(flyerPage.PageNo);
                  updateCurrentPage(flyerPage.PageNo - 1);
                  flyerDetailGridView(false);
                }}
                sx={{
                  minHeight: 287,
                  width: 182,
                  border: "1px solid #AAAAAA",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <CardMedia
                  sx={{
                    objectFit: "contain",
                    width: "182px",
                    marginTop: "20px",
                  }}
                  component="img"
                  height="247"
                  image={`${ApiList.getFlyerImageUrl()}${flyerPage.FlyerImage}`}
                  alt={flyerPage}
                />
                <div className="grid-view-page-number-div">
                  <span className="grid-view-page-numbe-span">
                    {flyerPage.PageNo}
                  </span>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </section>
    );
  }
}

export default FlyerDetailGridView;
