import noDataFoundStyling from "../styling/noDataFoundStyling";

function NoDataFound({ loading }) {
  return (
    <div style={noDataFoundStyling.backgorundStyling}>
      <div style={noDataFoundStyling.divStyling}>
        <h1 style={noDataFoundStyling.bannerTextStyling}>
          {loading ? <b>LOADING...</b> : <b>NO DATA FOUND</b>}
        </h1>
      </div>
    </div>
  );
}

export default NoDataFound;
