import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
    GET_SAVE_SEARCH_BY_ID_REQUEST,
   GET_SAVE_SEARCH_BY_ID_SUCCESS,
   GET_SAVE_SEARCH_BY_ID_FAILURE,
} from "../actionTypes";


const ApiList = new ApiCalls();
export const getSaveSearchByIdRequest = () => {
    return {
        type: GET_SAVE_SEARCH_BY_ID_REQUEST,

    };
}

export const getSaveSearchByIdSuccess = (SaveSearchIdData) => {
    return {
        type: GET_SAVE_SEARCH_BY_ID_SUCCESS,
        payload: SaveSearchIdData,
    };
};

export const getSaveSearchByIdFailure = (error) => {
    return {
        type: GET_SAVE_SEARCH_BY_ID_FAILURE,
        payload: error,
    };
};


export const getSaveSearchByIdData = (saveSearchId) => {
    return (dispatch) => {
        dispatch(getSaveSearchByIdRequest());
        ApiList.getSaveSearchById(saveSearchId)
            .then((response) => {
                 const newToken = response.headers.token;
                 const userData = JSON.parse(localStorage.getItem("userData"));
                 const newUserData = { ...userData, token: newToken };
                 localStorage.setItem("userData", JSON.stringify(newUserData));
                const SaveSearchIdData = response.data;
                dispatch(getSaveSearchByIdSuccess(SaveSearchIdData));
            })
            .catch((error) => {
                UnauthorisedUser(error.response.status);
                dispatch(getSaveSearchByIdFailure(error.message));
            });
    };
};
