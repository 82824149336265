import React, { Component } from "react";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import ApiCalls from "../../components/ApiList/apiCalls";
import { removeItemFromCompareOffersList } from "../../store/actions/compareOfferAction";
import { savedOfferCount } from "../../store/actions/savedOfferAction";
import { withSnackbar } from "notistack";
import Loader from "../CommonFiles/Loader";
import { connect } from "react-redux";
import compareCrossIcon from "../../images/compareCrossIcon.svg";

const ApiList = new ApiCalls();
class CompareDetailTable extends Component {
  constructor(props) {
    super(props);
  }

  savedOfferButtonText = (offerItem) => {
    const element = this.props.offerData.find(
      (offer) => offer.id === offerItem.id || offer.id === offerItem.id
    );
    return Number(element.shopCartId) === 0;
  };
  componentDidMount() {
    document.addEventListener("removecount", this.onChange.bind(this));
  }
  onChange(ev) {
    this.props.savedOfferCount(ev.detail.removeCount);
  }
  render() {
    const {
      formData,
      compareOfferData,
      savedOfferDataSorting,
      cartOfferData,
      offerData,
      loadingByOfferPermission,
    } = this.props;
    if (JSON.stringify(cartOfferData) != "{}") {
      let savedOffer = [];
      cartOfferData?.map((item) =>
        item.savedOffers.map((offer) => savedOffer.push(offer))
      );
    }
    if (compareOfferData && compareOfferData.length) {
      return (
        <>
          <Loader open={loadingByOfferPermission} />
          <section>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ padding: "0px" }}>
                    <div
                      style={{
                        border: "1px solid #017E8F",
                        borderRight: "none",
                        height: "265px",
                        width: "261px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {compareOfferData.length > 1 && (
                        <div className="compare-page-cross-button">
                          <img
                            src={compareCrossIcon}
                            onClick={() => {
                              this.props.removeItemFromCompareOffersList(
                                compareOfferData[0].id
                              );
                            }}
                          />
                        </div>
                      )}
                      <img
                        style={{
                          height: "240px",
                          width: "185px",
                          objectFit: "contain",
                          marginTop: "11px",
                        }}
                        src={`${
                          ApiList.getImageUrl() + compareOfferData[0].image
                        }?w=20&h=20&fit=crop&auto=format`}
                      />
                    </div>
                  </TableCell>
                  <TableCell sx={{ padding: "0px" }}>
                    <div
                      style={{
                        border: "1px solid #017E8F",
                        borderRight: "none",
                        height: "265px",
                        width: "261px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {compareOfferData[1] && (
                        <div
                          className="compare-page-cross-button"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={compareCrossIcon}
                            onClick={() => {
                              this.props.removeItemFromCompareOffersList(
                                compareOfferData[1].id
                              );
                            }}
                          />
                        </div>
                      )}
                      {compareOfferData[1] && (
                        <>
                          <img
                            style={{
                              height: "240px",
                              width: "185px",
                              objectFit: "contain",
                              marginTop: "11px",
                            }}
                            src={`${
                              ApiList.getImageUrl() + compareOfferData[1].image
                            }?w=20&h=20&fit=crop&auto=format`}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ padding: "0px" }}>
                    <div
                      style={{
                        border: "1px solid #017E8F",
                        borderRight: "none",
                        height: "265px",
                        width: "261px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {compareOfferData[2] && (
                        <div className="compare-page-cross-button">
                          <img
                            src={compareCrossIcon}
                            onClick={() => {
                              this.props.removeItemFromCompareOffersList(
                                compareOfferData[2].id
                              );
                            }}
                          />
                        </div>
                      )}

                      {compareOfferData[2] && (
                        <>
                          <img
                            style={{
                              height: "240px",
                              width: "185px",
                              objectFit: "contain",
                              marginTop: "11px",
                            }}
                            src={`${
                              compareOfferData[2] &&
                              ApiList.getImageUrl() + compareOfferData[2].image
                            }?w=20&h=20&fit=crop&auto=format`}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ padding: "0px" }}>
                    <div
                      style={{
                        border: "1px solid #017E8F",
                        height: "265px",
                        width: "261px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {compareOfferData[3] && (
                        <div className="compare-page-cross-button">
                          <img
                            src={compareCrossIcon}
                            onClick={() => {
                              this.props.removeItemFromCompareOffersList(
                                compareOfferData[3].id
                              );
                            }}
                          />
                        </div>
                      )}

                      {compareOfferData[3] && (
                        <>
                          <img
                            style={{
                              height: "240px",
                              objectFit: "contain",
                              width: "185px",
                              marginTop: "11px",
                            }}
                            src={`${
                              ApiList.getImageUrl() + compareOfferData[3].image
                            }?w=20&h=20&fit=crop&auto=format`}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      borderRadius: "5px 0px 0px 0px",
                    }}
                    className="compare-page-thHeading"
                  >
                    Offer Name
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].nameEn}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].nameEn}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].nameEn}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].nameEn}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ borderRadius: "5px 0px 0px 0px" }}
                    className="compare-page-thHeading"
                  >
                    Retailer
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].retailerName}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].retailerName}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].retailerName}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].retailerName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Brand
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].brand}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].brand}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].brand}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].brand}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Product
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].product}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].product}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].product}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].product}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Packsize
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].packSize}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].packSize}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].packSize}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].packSize}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Regular Price
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].regularPrice}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].regularPrice}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].regularPrice}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].regularPrice}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Promo Price
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].promoPrice}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].promoPrice}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].promoPrice}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].promoPrice}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ borderRadius: "0px 0px 0px 5px" }}
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Validity Period
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].offerStartDate} -{" "}
                    {compareOfferData[0].offerEndDate}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && (
                      <>
                        {compareOfferData[1].offerStartDate} -{" "}
                        {compareOfferData[1].offerEndDate}
                      </>
                    )}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && (
                      <>
                        {compareOfferData[2].offerStartDate} -{" "}
                        {compareOfferData[2].offerEndDate}
                      </>
                    )}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && (
                      <>
                        {compareOfferData[3].offerStartDate} -{" "}
                        {compareOfferData[3].offerEndDate}
                      </>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{ borderRadius: "0px 0px 0px 5px" }}
                    className="compare-page-thHeading"
                    component="th"
                    scope="row"
                  >
                    Flyer Promotion Page Number
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[0].pageNumber}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[1] && compareOfferData[1].pageNumber}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[2] && compareOfferData[2].pageNumber}
                  </TableCell>
                  <TableCell className="compare-page-tbody">
                    {compareOfferData[3] && compareOfferData[3].pageNumber}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </section>
        </>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
    compareOfferData: state.compareOffer.compareOfferList,
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
    loadingByOfferPermission: state.userPermissionOfferData.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeItemFromCompareOffersList: (offer) =>
      dispatch(removeItemFromCompareOffersList(offer)),
    savedOfferCount: (count) => dispatch(savedOfferCount(count)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CompareDetailTable));
