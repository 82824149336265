import React, { Component } from "react";
import Checkbox from "@mui/material/Checkbox";
import duplicateStyles from "../../styling/duplicateStyles";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import UpdateJson from "../CommonFiles/UpdateJson";

class Duplicate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }
  componentDidMount() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ checked: userFilterData.isShowDuplicate });
  }
  handleChange = (e) => {
    const { checked } = e.target;
    this.setState({ checked: checked });
    UpdateJson([{ key: "isShowDuplicate", value: checked }]);
    this.props.getOfferByUserPermission();
  };
  render() {
    return (
      <Tooltip title="View Duplicate Offers">
        <div style={duplicateStyles.buttonSizeStyling}>
          <Checkbox
            sx={{
              padding: "0px",
              "& .MuiSvgIcon-root": {
                color: "#AAA",
                height: "18px",
                width: "18px",
              },
            }}
            onChange={(e) => this.handleChange(e)}
            checked={this.state.checked}
          />
          <span className="duplicate-text">Duplicates</span>
        </div>
      </Tooltip>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission("duplicate", true)),
  };
};
export default connect(null, mapDispatchToProps)(Duplicate);
