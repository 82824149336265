import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  FLYERS_PAGE_LIST_REQUEST,
  FLYERS_PAGE_LIST_SUCCESS,
  FLYERS_PAGE_LIST_FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getFlyersPageDataRequest = () => {
  return {
    type: FLYERS_PAGE_LIST_REQUEST,
  };
};

export const getFlyersPageDataSuccess = (flyersData) => {
  return {
    type: FLYERS_PAGE_LIST_SUCCESS,
    payload: flyersData,
  };
};

export const getFlyersPageDataFailure = (error) => {
  return {
    type: FLYERS_PAGE_LIST_FAILURE,
    payload: error,
  };
};

export const getFlyersPageDataObject = ( loading = false) => {
    return (dispatch) => {
    if(loading){
      dispatch(getFlyersPageDataRequest());
    }
    ApiList.getFlyersPageData()
      .then((response) => {          
        const flyersPageData = response.data;
        dispatch(getFlyersPageDataSuccess(flyersPageData));
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));

         const userFilterData = JSON.parse(
           localStorage.getItem("userFilterData")
         );
          const customEvent = new CustomEvent("pageSizeChange", {
            detail: {
              pageSize: userFilterData.pageSize,
              pageNo:userFilterData.pageNo,
            },
          });
          document.dispatchEvent(customEvent);
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getFlyersPageDataFailure(error.message));
      });
  };
};
