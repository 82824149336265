import {
  GET_SAVE_SEARCH_REQUEST,
  GET_SAVE_SEARCH_SUCCESS,
  GET_SAVE_SEARCH_FAILURE,
  SAVED_SEARCH_COUNT,
} from "../actionTypes";
  
  const initialState = {
    loading: true,
    saveSearchData: [],
    savedSearchCount: 0,
    error: "",
  };

  const getSaveSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SAVE_SEARCH_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };

      case GET_SAVE_SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          savedSearchCount: action.payload.length,
          saveSearchData: action.payload,
          error: "",
        };

      case GET_SAVE_SEARCH_FAILURE:
        return {
          ...state,
          loading: false,
          saveSearchData: [],
          error: action.payload,
        };
      case SAVED_SEARCH_COUNT:
        return {
          ...state,
          loading: false,
          savedSearchCount: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getSaveSearchReducer;