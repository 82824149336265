import React, { Component } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";
import UpdateJson from "../CommonFiles/UpdateJson";

class FlyersPageSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 20,
    };
  }

  componentDidMount() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ value: userFilterData.pageSize });
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ value: value });
    if (value !== 20) {
      UpdateJson([{ key: "pageNo", value: 1 }]);
    }
    UpdateJson([{ key: "pageSize", value: value }]);
    this.props.getFlyersPageDataObject(true);
  };
  render() {
    const { isDisable } = this.props;
    return (
      <FormControl
        disabled={!isDisable}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          minWidth: 59,
          marginLeft: "5px",
          "& .MuiInputBase-root": {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            color: "#2B2B2B",
          },
        }}
      >
        <Select
          className="show-page-size"
          displayEmpty
          size="small"
          value={this.state.value}
          onChange={this.handleChange}
        >
          <MenuItem value={20}>20</MenuItem>
          {/* <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem> */}
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    flyersState: state.flyersPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFlyersPageDataObject: (flag) => dispatch(getFlyersPageDataObject(flag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlyersPageSize);
