import {
    GET_NOTIFICATION_REQUEST,
     GET_NOTIFICATION_SUCCESS, 
    GET_NOTIFICATION_FAILURE,
  } from "../actionTypes";
  
  const initialState = {
    loading: true,
    notificationmsg:"",
  };

  const getNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };
  
      case GET_NOTIFICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          notificationmsg: action.payload,
          error: "",
        };
  
      case GET_NOTIFICATION_FAILURE:
        return {
          ...state,
          loading: false,
          notificationmsg: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getNotificationReducer;