import {
    SAVE_SEARCH_REQUEST,
    SAVE_SEARCH_SUCCESS,
    SAVE_SEARCH_FAILURE,
} from "../actionTypes";
  
  const initialState = {
    loading: true,
    saveSearchData:{},
    error: "",
  };

  const saveSearchReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_SEARCH_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          
        };
  
      case SAVE_SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          saveSearchData: action.payload,
          error: "",
        };
  
      case SAVE_SEARCH_FAILURE:
        return {
          ...state,
          loading: false,
          saveSearchData: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default saveSearchReducer;