import React, { Component } from "react";
import Box from "@mui/material/Box";
import Footer from "./CommonFiles/Footer";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import { getTnCObject } from "../store/actions/termsAndConditionAction";
import { updateNavView } from "../store/actions/filterFormDataAction";

class TnC extends Component {
  componentDidMount() {
    this.props.getTnCObject();
    this.props.updateNavView(4);
  }

  render() {
    const { TnCData } = this.props;

    if (TnCData) {
      return (
        <section>
          <div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "10px",
                textDecoration: "underline",
              }}
            >
              Subscription Agreement
            </h2>
            {TnCData.tnC_Status.indexOf("</") !== -1 ? (
              <div
                style={{
                  padding: "22px 29px",
                  height: "78vh",
                  overflowY: "auto",
                  backgroundColor: "#ECEFF4",
                }}
                dangerouslySetInnerHTML={{
                  __html: TnCData.tnC_Status.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            ) : (
              TnCData.tnC_Status
            )}
            <Footer />
          </div>
        </section>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    TnCData: state.getTnCObject.termsAndCondition,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTnCObject: () => dispatch(getTnCObject()),
    updateNavView: (count) => dispatch(updateNavView(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TnC);
