const imageStyles = {
  thHeading: {
    backgroundColor: "#017E8F",
    width: "200px",
    height: "30px",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#FFFFFF",
  },

  tbody: {
    width: "364px",
    backgroundColor: "#ECEFF4",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14.06px",
  },
  titleParagraph: {
    background: "#FFFFFF",
    border: "1px solid #AAAAAA",
    borderRadius: "5px",
    paddingLeft: "61px",
    paddingTop: "11px",
  },
  offerInfoPara1: {
    color: "#2B2B2B",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    marginRight: "64px",
  },
  offerInfoPara2: {
    color: "#2B2B2B",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    marginLeft: "64px",
    marginTop:"0px",
  },
  buttonText: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    textTransform: "none",
    fontStyle: "normal",
  },
  imageDivStyling: {
    backgroundColor: "#ECEFF4",
    padding: "22px 29px",
    height: "512px",
    overflowY: "scroll",
  },
  imageCartDivStyling: {
    backgroundColor: "#ECEFF4",
    padding: "0px 22px 29px 29px",
  },

  imageListSize: { width: "100%", height: "100%" },
  imageListItemStyle: {
    border: "1px solid #AAAAAA",
    margin: "0 auto 1rem auto",
    backgroundColor: "#FFF",
  },
  imageListDivStyle: {
    display: "flex",
    justifyContent: "space-between",
    height: "0px",
  },
  compareButtonStyling: {
    cursor: "pointer",
    paddingTop: "0px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: ".7rem",
    backgroundColor: "#6D6D6D",
    color: "#fff",
    textTransform: "none",
    marginTop: "0.1rem",
    marginLeft: "0.1rem",
    height: "2rem",
    transform: "translate(1px,  1px)",
    width: "87px",
    //height: "24px",
    borderRadius: "2px",
  },
  compareButtonText: {
    position: "absolute",
    width: "57px",
    height: "16px",
    left: "15px",
    top: "3px",
    fontStyle: "normal",
    textTransform: "none",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FFFFFF",
  },
  checkBoxStyling: {
    color: "#0070C0",
    padding: "0px",
    position: "absolute",
    top: "1px",
    right: "1px",
    zIndex:"1",
    // transform: "translate(1px, -2px)",
  },
  checkBoxSizeStyling: { "& .MuiSvgIcon-root": { fontSize: 30 } },
  dialogTitleStyling: {
    background: "linear-gradient(90.49deg, #A7E8A5 -3.83%, #73C0CE 102.84%)",
    borderRadius: "5px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
  },
  dialogContentBoxSize: { width: "100%", height: "auto" },
  gridStyling: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#2B2B2B",
  },
  gridImageStyling: {
    width: "300px",
    objectFit: "contain",
    height: "100%",
  },
  boxStyling: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"10px",
  },
  boxDivStyling: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    transform: "translate(0,50%)",
  },
  popupGridStyling: {
    paddingLeft: "41px",
    paddingRight: "21px",
  },
  popupFlyerPageButtonStyling: {
    display: "flex",
    justifyContent: "space-between",
  },
  popupFlyerSizeButtonStyling: {
    "& button": {
      pl: 2.5,
      pr: 2.5,
      pt: 0.5,
      pb: 0.5,
    },
  },
  descriptionDrigStyle: { display: "flex", gap: "0.5rem" },
  spanImageStyle: { height: "35px", width: "45px" },
};

export default imageStyles;
