import {
  OFFER_IMAGE_REQUEST,
  OFFER_IMAGE_SUCCESS,
  OFFER_IMAGE_FAILURE,
  BRAND_COPY_DATA,
  PRODUCT_COPY_DATA,
  PACKSIZE_COPY_DATA,
  ATTRIBUTES_COPY_DATA,
  ATTRIBUTE_COPY_DATA,
  LOADING_ACTIVATE,
  UPDATE_VIEW_BY,
  UPDATE_VIEW,
  TOGGLE_INITIALIZE,
  OFFER_PRICE_COPY_DATA,
  DISCOUNT_RANGE_COPY_DATA
} from "../actionTypes";

const initialState = {
  loading: false,
  initialize: false,
  imageData: null,
  viewBy: 0,
  view: 0,  //  0 means Grid view and 1 means List View of Offers.
  error: "",

  brandCopyData: [],
  productCopyData: [],
  packsizeCopyData: [],
  attributesCopyData: [],
  attributeCopyData: [],
  offerPriceCopyData:[],
  discountRangeCopyData:[]
};

const getOfferByUserPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ACTIVATE:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case OFFER_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case OFFER_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        imageData: action.payload,
        error: "",
      };
    case OFFER_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        imageData: [],
        error: action.payload,
      };

    case BRAND_COPY_DATA:
      return {
        ...state,
        brandCopyData: action.payload,
      };
    case PRODUCT_COPY_DATA:
      return {
        ...state,
        productCopyData: action.payload,
      };

    case PACKSIZE_COPY_DATA:
      return {
        ...state,
        packsizeCopyData: action.payload,
      };

    case ATTRIBUTES_COPY_DATA:
      return {
        ...state,
        attributesCopyData: action.payload,
      };
    case ATTRIBUTE_COPY_DATA:
      return {
        ...state,
        attributeCopyData: action.payload,
      };
    case UPDATE_VIEW_BY:
      return {
        ...state,
        viewBy: action.payload,
      };
    case UPDATE_VIEW:
      return {
        ...state,
        view: action.payload,
      };

    case TOGGLE_INITIALIZE:
      return {
        ...state,
        initialize: action.payload,
      };

      case OFFER_PRICE_COPY_DATA:
        return {
          ...state,
          offerPriceCopyData:action.payload
        };

        case DISCOUNT_RANGE_COPY_DATA:
          return {
            ...state,
            discountRangeCopyData:action.payload
          };

    default:
      return state; 
  }
};

export default getOfferByUserPermissionReducer;
