import {
  FLYERS_PAGE_RENDER,
  FLYERS_DETAIL_PAGE,
  FLYERS_DETAIL_GRID_VIEW,
  UPDATE_FLYERS_CITY,
  UPDATE_FLYERS_RETAILER,
  UPDATE_FLYERS_START_DATE,
  UPDATE_FLYERS_END_DATE,
  UPDATE_FLYERS_PAGESIZE,
  UPDATE_FLYERS_SORTING,
  UPDATE_FLYER_PAGE_NUMBER,
  UPDATE_FLYERS_COUNTRY,
  FLYER_CLEAR_SEARCH,
} from "../actionTypes";
 
export const flyersPageRender = (value) => {
  return {
    type: FLYERS_PAGE_RENDER,
    payload: value,
  };
};

export const updateFlyerDetail = (value) => {
  return {
    type: FLYERS_DETAIL_PAGE,
    payload: value,
  };
};

export const flyerDetailGridView = (value) => {
  return {
    type: FLYERS_DETAIL_GRID_VIEW,
    payload: value,
  };
};
// ****************************************************************

export const updateFlyersCountry = (newValue) => {
  return {
    type: UPDATE_FLYERS_COUNTRY,
    payload: newValue,
  };
};



export const updateFlyersCity = (newValue) => {
  return {
    type: UPDATE_FLYERS_CITY,
    payload: newValue,
  };
};




export const updateFlyersRetailer = (newValue) => {
  return {
    type: UPDATE_FLYERS_RETAILER,
    payload: newValue,
  };
};



export const updateFlyerStartDate = (newValue) => {
  return {
    type: UPDATE_FLYERS_START_DATE,
    payload: newValue,
  };
};


export const updateFlyerEndDate = (newValue) => {
  return {
    type: UPDATE_FLYERS_END_DATE,
    payload: newValue,
  };
};




export const updateFlyersPageSize = (newValue) => {
  return {
    type: UPDATE_FLYERS_PAGESIZE,
    payload: newValue,
  };
}; 



export const updateFlyersSorting = (newValue) => {
  return {
    type: UPDATE_FLYERS_SORTING,
    payload: newValue,
  };
};


export const updateFlyerPageNumber=(pageNumber)=>{
  return{
    type:UPDATE_FLYER_PAGE_NUMBER,
    payload:pageNumber
  }
}
export const clearFlyerPage = () => {
  return {
    type: FLYER_CLEAR_SEARCH,
  };
};

