import { Checkbox } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  removeAllSavedOfferFromExportList,
  selectAllSavedOffersForExport,
} from "../../store/actions/exportSavedOfferAction";
import {
  removeCartAllOffers,
  selectCartAllOffers,
} from "../../store/actions/removeSavedOfferAction";

class SelectAllOffersCart extends Component {
  constructor(props) {
    super(props);
  }
  state = { selected: false };

  handleChange = async (event) => {
    const { checked } = event.target;
    if (checked) {
      await this.props.selectCartAllOffers(this.props.cartOfferData);
      await this.props.selectAllSavedOffersForExport(this.props.cartOfferData);
    } else {
      await this.props.removeCartAllOffers();
      await this.props.removeAllSavedOfferFromExportList();
    }
  };

  render() {
    const {
      cartOfferData,
      selectCartAllOffers,
      removeCartAllOffers,
      selectAllSavedOffersForExport,
      removeAllSavedOfferFromExportList,
      selectedCartOfferItems,
      exportSavedOffersList,
    } = this.props;
    const savedOffer = [];
    cartOfferData &&
      cartOfferData?.map((item) =>
        item.savedOffers.map((offer) => savedOffer.push(offer))
      );

    return (
      <Checkbox
        indeterminate={
          exportSavedOffersList.length > 0 &&
          exportSavedOffersList.length < savedOffer.length
        }
        checked={
          exportSavedOffersList.length === savedOffer.length &&
          savedOffer.length > 0
        }
        disabled={!this.props.cartOfferData.length}
        sx={{
          "& .MuiSvgIcon-root": {
            backgroundColor: "85CEC0",
            color: "#85CEC0",
            height: "22px",
            width: "22px",
          },
          "&.Mui-checked": {
            color: "#85CEC0",
          },
        }}
        onChange={async (event) => {
          const { checked } = event.target;
          if (exportSavedOffersList.length === 0) {
            await selectCartAllOffers(savedOffer);
            await selectAllSavedOffersForExport(savedOffer);
          } else {
            await removeCartAllOffers();
            await removeAllSavedOfferFromExportList();
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
    selectedCartOfferItems: state.removeSavedOffer.savedOfferObjectData,
    exportSavedOffersList: state.exportSavedOffer.exportSavedOffersList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCartAllOffers: (newValue) => dispatch(selectCartAllOffers(newValue)),
    selectAllSavedOffersForExport: (offerList) =>
      dispatch(selectAllSavedOffersForExport(offerList)),
    removeCartAllOffers: () => dispatch(removeCartAllOffers()),
    removeAllSavedOfferFromExportList: () =>
      dispatch(removeAllSavedOfferFromExportList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectAllOffersCart);
