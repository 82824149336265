import React, { Component } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import img01 from "../images/whtasnewimg/img01.png";
import img02 from "../images/whtasnewimg/img02.png";
import img03 from "../images/whtasnewimg/img03.png";
import img04 from "../images/whtasnewimg/img04.png";
import img05 from "../images/whtasnewimg/img05.png";
import img06 from "../images/whtasnewimg/img06.png";
import img08 from "../images/whtasnewimg/img08.png";
import img17 from "../images/whtasnewimg/img17.png";
import img171 from "../images/whtasnewimg/img171.png";
import img18 from "../images/whtasnewimg/img18.png";
import img181 from "../images/whtasnewimg/img181.png";
import img20 from "../images/whtasnewimg/img20.png";
import img21 from "../images/whtasnewimg/img21.png";
import img22 from "../images/whtasnewimg/img22.png";
import img13 from "../images/whtasnewimg/img13.png";
import img14 from "../images/whtasnewimg/img14.png";
import img07 from "../images/whtasnewimg/img07.png";
const userData = JSON.parse(localStorage.getItem("userData"));
const drawerWidth = 240;
var url = window.location.pathname;
const headingh1 = {
  fontSize: "15px",
  paddingBottom: "10px",
  marginBottom: "10px",
  lineHeight: "normal",
  margintop: "10px",
  color: "#084354",
};
const headingh2 = {
  fontSize: "22px",
  paddingBottom: "10px",
  borderBottom: "1px solid #ccc",
  marginBottom: "20px",
  lineHeight: "normal",
  marginTop: "10px",
  color: "#084354",
};
const headingh3 = {
  fontSize: "18px",
  fontWeight: 500,
  marginBottom: "8px",
  color: "#256D83",
};

const headingh4 = {
  fontSize: "15px",
  fontWeight: 400,
  marginBottom: "2px",
  color: "#256D83",
  fontStyle: "italic",
};
const paragraphStyle = {
  marginBottom: "20px",
};
const bodyStyles = {
  fontWeight: 400,
  color: "#343434",
  lineHeight: "26px",
  fontSize: "16px",
  backgroundColor: "#f5f7fa",
};
const listTextStyle = {
  color: "#256D83",
  fontsize: "15px !important",
  paddingLeft: "15px",
};
class UserGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: "",
    };
  }
  handleClick = (section) => {
    this.setState((prevState) => ({
      openSection: prevState.openSection === section ? null : section,
    }));
  };
  componentDidMount() {}
  state = {};
  render() {
    url = window.location.pathname;
    if (!userData) {
         window.location.href =
           "https://analytics.clicflyer.com/Retailer/Retailer/Welcome";
    } else {
      return (
        <section style={{ width: "100%" }}>
          <Box sx={{ display: "flex" }}>
            <Drawer
              sx={{
                width: drawerWidth,
                //height: "20px",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  marginTop: "50px",
                  boxSizing: "border-box",
                  backgroundColor: "#f5f7fa",
                  // maxHeight: "640px",
                  //height: "cal(100vh - 100px)",
                  position: "absolute",
                  height: "90vh",
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <Divider />
              <List>
                <ListItem>
                  <a
                    href={url + "#what-is-offer-bank"}
                    className="dropdown-item"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText
                      style={{ color: "rgb(8, 67, 84)" }}
                      primary="What is Offer Bank?"
                    />
                  </a>
                </ListItem>
                {/* Main Menu Items */}
                <ListItem button onClick={() => this.handleClick("parent")}>
                  <a
                    href={url + "#offers"}
                    className="dropdown-item"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText
                      style={{ color: "rgb(8, 67, 84)" }}
                      primary="Offers"
                    />
                  </a>
                  {this.state.openSection === "parent" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.openSection === "parent"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button>
                      <a
                        href={url + "#filtering-offers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Filtering Offers"
                        />
                      </a>
                    </ListItem>

                    <ListItem button>
                      <a
                        href={url + "#time-period-filters"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Time-Period Filters"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#sorting-offers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Sorting Offers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#searching-offers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Searching Offers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#compare-offers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Compare Offers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#offers-layout"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Offers Layout"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#save-offers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Save Offers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#bookmark-your-filters-and-share"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Bookmark Filters & Share"
                        />
                      </a>
                    </ListItem>
                    {/* <ListItem button>
                    <a
                      href={url + "#export-offers"}
                      class="dropdown-item"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItemText
                        style={listTextStyle}
                        primary="Export Offers"
                      />
                    </a>
                  </ListItem> */}
                    {/* //Manage your Account! */}
                  </List>
                </Collapse>

                <ListItem button onClick={() => this.handleClick("another")}>
                  <a
                    href={url + "#flyers"}
                    className="dropdown-item"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText
                      style={{ color: "rgb(8, 67, 84)" }}
                      primary="Flyers"
                    />
                  </a>
                  {this.state.openSection === "another" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.openSection === "another"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div">
                    <ListItem button>
                      <a
                        href={url + "#filtering-flyers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Filtering Flyers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#time-period-filters"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Time-Period Filters"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#sorting-flyers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Sorting Flyers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#viewing-flyer-pages"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Viewing Flyer Pages"
                        />
                      </a>
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem button onClick={() => this.handleClick("submenu")}>
                  <a
                    href={url + "#manage-your-account"}
                    className="dropdown-item"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText
                      style={{ color: "rgb(8, 67, 84)" }}
                      primary="Manage your Account!"
                    />
                  </a>
                  {this.state.openSection === "submenu" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.openSection === "submenu"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div">
                    <ListItem button>
                      <a
                        href={url + "#view-profile"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="View Profile"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#my-subscription-plan"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="My Subscription Plan"
                        />
                      </a>
                    </ListItem>
                  </List>
                </Collapse>
                {/* 
              <ListItem
                button
                onClick={() => this.handleClick("anotherSubmenu")}
              >
                <ListItemText primary="Manage your Account!" />
                {this.state.openSection === "anotherSubmenu" ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={this.state.openSection === "anotherSubmenu"}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem button>
                    <ListItemText primary="Submenu Item 3" />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Submenu Item 4" />
                  </ListItem>
                </List>
              </Collapse> */}
              </List>
            </Drawer>
            <Box
              component="main"
              //className="customContainer"
              //background.default
              sx={{
                flexGrow: 1,
                bgcolor: "",
                p: 3,
                marginBottom: "-12px",
                //maxHeight: "640px",
                height: "90vh",
              }}
              style={bodyStyles}
            >
              <div
                className="rightCnt"
                style={{ padding: "30px", height: "100%", overflowY: "scroll" }}
              >
                <h1 style={headingh1}>Getting Started</h1>

                <h2 style={headingh2} id="what-is-offer-bank">
                  What is Offer Bank?
                </h2>

                <p style={paragraphStyle}>
                  Offer Bank is a web-based tool designed to serve as a central
                  repository for offers and flyers from various brands promoted
                  across leading retailers.
                </p>
                <ul>
                  <li>
                    Offers are systematically grouped into clear categories,
                    simplifying navigation and insights.
                  </li>
                  <li>
                    It enables users to access it from any location via the web
                    and supports multiple users simultaneously.
                  </li>
                  <li>
                    It ensures hassle-free maintenance of all promotional
                    activities, streamlining the process of offer management for
                    businesses.
                  </li>
                </ul>
                <h2 style={headingh2} id="offers">
                  Offers
                </h2>
                <h3 style={headingh3} id="filtering-offers">
                  Filtering Offers
                </h3>
                <p style={paragraphStyle}>
                  Offers can be filtered on various levels such as country,
                  city, retailers, product hierarchy, discount, and price etc.
                </p>

                <h4 style={headingh4}>Global Filters</h4>

                <p style={paragraphStyle}>
                  <b>Country, City, Retailers</b> are the global filters. Once
                  applied, the global filters remain in effect unless manually
                  changed.
                </p>
                <h4 style={headingh4}>Main Filters</h4>

                <p style={paragraphStyle}>
                  <b>Category, Subcategory, and Item</b> are the main filters.
                  Once applied, the main filter remains in effect until manually
                  changed or the global filters are changed.
                </p>
                <h4 style={headingh4}>Refine Filters</h4>

                <p style={paragraphStyle}>
                  Refine filters have been introduced to provide users with
                  additional filtering options based on the main filter
                  selection. Users can filter by
                  <b>
                    &nbsp;Brands, Products and Pack sizes
                  </b>
                  . These filters will get reset once any change is made in
                  Global Filters or the Main filters.
                </p>
                <h4 style={headingh4}>Refine Sliders</h4>

                <p style={paragraphStyle}>
                  <b>Price and Discount</b> sliders have been added as part of
                  the refine filters. Users can adjust the sliders to set the
                  desired price and discount ranges. These filters will get
                  reset once any change is made in Global Filters.
                </p>
                <img
                  src={img01}
                  style={{ marginBottom: "20px", width: "90%" }}
                  alt=""
                />
                <h3 style={headingh3} id="time-period-filters">
                  Time-Period Filters
                </h3>
                <h4 style={headingh4}>Filter by Date Range</h4>
                <p style={paragraphStyle}>
                  The date picker allows users to select a specific date range
                  to retrieve offers according to their preferences.
                </p>
                <img
                  src={img02}
                  style={{ marginBottom: "20px", width: "50%" }}
                  alt=""
                />
                {/* <h4 style={headingh4}>Filter by Week Number</h4>

                <p style={paragraphStyle}>
                  Users can select a week number to retrieve offer data,
                  providing a more convenient way to navigate through offers. As
                  per ClicFlyer standards, a week is defined as Sunday to
                  Saturday.
                </p>
                <img
                  src={img03}
                  style={{ marginBottom: "20px", width: "50%" }}
                  alt=""
                /> */}
                <h3 style={headingh3} id="sorting-offers">
                  Sorting Offers
                </h3>

                <p style={paragraphStyle}>
                  Users can sort offers based on various parameters.
                </p>
                <ul>
                  <li>
                    <h4 style={headingh4}>Offers New to Old</h4> - Offers can be
                    sorted based on their offer date, from the newest to the
                    oldest.
                  </li>
                  <li>
                    <h4 style={headingh4}>Offer Old to New </h4>- Offers can be
                    sorted based on their offer date, from the oldest to the
                    newest.
                  </li>
                  <li>
                    <h4 style={headingh4}>Price High to Low</h4> - Offers can be
                    sorted based on the promo price, from the highest to the
                    lowest.
                  </li>
                  <li>
                    <h4 style={headingh4}>Price Low to High</h4> - Offers can be
                    sorted based on the promo price, from the lowest to the
                    highest.
                  </li>
                </ul>
                <p style={paragraphStyle}></p>
                <img
                  src={img04}
                  style={{ marginBottom: "20px", width: "90%" }}
                  alt=""
                />
                <h3 style={headingh3} id="searching-offers">
                  Searching Offers
                </h3>
                <p style={paragraphStyle}>
                  <h4 style={headingh4}>Free Search</h4> - Users can perform a
                  free search based on the exact offer name, allowing for more
                  precise results.
                </p>
                <img
                  src={img05}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <p style={paragraphStyle}>
                  <h4 style={headingh4}>Suggestive Search</h4> – Leveraging
                  ClicFlyer's categorization, the suggestive search feature
                  streamlines the offer discovery process. As the user begins to
                  type in the search box, relevant suggestions immediately
                  surface, ensuring a more intuitive and efficient search
                  experience.
                </p>
                <img
                  src={img06}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="compare-offers">
                  Compare Offers
                </h3>
                <p style={paragraphStyle}>
                  Users can compare offers and see how each offer differs in
                  terms of brand, product, pack size, promo mechanic, promo
                  price, and other parameters.
                </p>
                <ul>
                  <li>Users can select up to 4 offers at a time.</li>
                  <li>Users can export offers for detailed analysis</li>
                </ul>
                <img
                  src={img07}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="offers-layout">
                  Offers Layout – List View/Grid View
                </h3>
                <p style={paragraphStyle}>
                  User can view the offers in Grid format as well as List
                  format.
                </p>
                <img
                  src={img08}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="save-offers">
                  Save Offers
                </h3>
                <p style={paragraphStyle}>
                  Users can save any selected offer for later use/viewing.
                </p>
                <img
                  src={img17}
                  style={{ width: "50%", marginBottom: "20px" }}
                  alt=""
                />
                <img
                  src={img171}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="bookmark-your-filters-and-share">
                  Bookmark Your Filters and Share
                </h3>
                <p style={paragraphStyle}>
                  User can save their applied filters for future use. They can
                  also view, share, or delete those saved filters.
                </p>
                <img
                  src={img18}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <img
                  src={img181}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                {/* <h3 style={headingh3} id="export-offers">
                Export Offers
              </h3>
              <p style={paragraphStyle}>
                The user can export the offers in an Excel file that contains
                all the details of each offer.
              </p>
              <img
                src={img09}
                style={{ width: "90%", marginBottom: "20px" }}
                alt=""
              />
              <img
                src={img10}
                style={{ width: "90%", marginBottom: "20px" }}
                alt=""
              /> */}
                <h2 style={headingh2} id="flyers">
                  Flyers
                </h2>
                <h3 style={headingh3} id="filtering-flyers">
                  Filtering Flyers
                </h3>
                <p style={paragraphStyle}>
                  Flyers can only be filtered on various levels such as country,
                  city and retailers also called as global filters.
                </p>
                <h4 style={headingh4}>Global Filters</h4>
                <p style={paragraphStyle}>
                  Country, City, Retailers are the global filters. Once applied,
                  the global filter remains in effect until manually changed.
                </p>
                <img
                  src={img20}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="time-period-filters">
                  Time-Period Filters
                </h3>
                <h4 style={headingh4}>Filter by Date Range</h4>
                <p style={paragraphStyle}>
                  The date picker allows users to select a specific date range
                  to retrieve offers according to their preferences.
                </p>
                <img
                  src={img02}
                  style={{ width: "50%", marginBottom: "20px" }}
                  alt=""
                />
                {/* <h4 style={headingh4}>Filter by Week Number</h4>
                <p style={paragraphStyle}>
                  Users can select a week number to retrieve offer data,
                  providing a more convenient way to navigate through offers. As
                  per ClicFlyer standards, a week is defined as Sunday to
                  Saturday.
                </p>
                <img
                  src={img03}
                  style={{ width: "50%", marginBottom: "20px" }}
                  alt=""
                /> */}
                <h3 style={headingh3} id="sorting-flyers">
                  Sorting Flyers
                </h3>
                <p style={paragraphStyle}>
                  Users can sort flyers in the following orders.
                </p>
                <p style={paragraphStyle}>
                  <h4 style={headingh4}>Flyers New to Old</h4> - Offers can be
                  sorted based on their offer date, from the newest to the
                  oldest.
                </p>
                <p style={paragraphStyle}>
                  <h4 style={headingh4}>Flyers Old to New</h4> - Offers can be
                  sorted based on their offer date, from the oldest to the
                  newest.
                </p>
                <img
                  src={img21}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="viewing-flyer-pages">
                  Viewing Flyer Pages
                </h3>
                <p style={paragraphStyle}>
                  By clicking on a flyer cover image, user can view all the
                  flyer pages in either list view or grid view.
                </p>
                <img
                  src={img22}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h2 style={headingh2} id="manage-your-account">
                  Manage your Account!
                </h2>
                <p style={paragraphStyle}>
                  Our platform offers a seamless way to manage your account
                  details and subscription plans. Here's a quick guide to
                  navigating the account management section:
                </p>
                <h3 style={headingh3} id="view-profile">
                  View Profile
                </h3>
                <p style={paragraphStyle}>
                  The 'View Profile' section provides users with essential
                  account details at a glance, including their username, full
                  name, associated company name, and registered email address.
                </p>
                <img
                  src={img13}
                  style={{ width: "90%", marginBottom: "20px" }}
                  alt=""
                />
                <h3 style={headingh3} id="my-subscription-plan">
                  My Subscription Plan
                </h3>
                <p style={paragraphStyle}>
                  Within the 'My Subscription Plan' section, users can find
                  comprehensive details about their subscription, such as the
                  activation date, expiry date, and current status of their
                  plan. This facilitates efficient subscription management and
                  helps users stay informed about their service period and
                  renewal dates.
                </p>
                <img
                  src={img14}
                  style={{ marginBottom: "20px", width: "90%" }}
                  alt=""
                />
              </div>
            </Box>
          </Box>
        </section>
      );
    }
  }
}

export default connect(null, null)(UserGuide);
