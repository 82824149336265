import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import {
  GET_ADDITIONAL_INFO_REQUEST,
  GET_ADDITIONAL_INFO_SUCCESS,
  GET_ADDITIONAL_INFO_FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const fetchAdditionalDataRequest = () => {
  return {
    type: GET_ADDITIONAL_INFO_REQUEST,
  };
};

export const fetchAdditionalDataSuccess = (additionalData) => {
  return {
    type: GET_ADDITIONAL_INFO_SUCCESS,
    payload: additionalData,
  };
};

export const fetchAdditionalDataFailure = (error) => {
  return {
    type: GET_ADDITIONAL_INFO_FAILURE,
    payload: error,
  };
};

export const getAdditionalInfoData = (loginId) => {
  return (dispatch) => {
    dispatch(fetchAdditionalDataRequest);

    ApiList.getAdditionalInfo(loginId)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const AdditionalData = response.data;

        dispatch(fetchAdditionalDataSuccess(AdditionalData));
      })
      .catch((error) => {
        dispatch(fetchAdditionalDataFailure(error.message));
      });
  };
};
