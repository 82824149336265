import React, { Component } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";
import WeekYear from "./WeekYearSelect";
import WeekNumber from "./WeekNumberSelect";
import { connect } from "react-redux";
import {
  getWeekYearObject,
  updateSelectedYear,
} from "../../store/actions/getWeekYearAction";
import {
  resetFromAndToDate,
  updateCalendarView,
} from "../../store/actions/filterFormDataAction";
import { getSelectedWeekNumbers, getWeekNumberSuccess, removeAllSelectedWeekNumbers } from "../../store/actions/getWeekNumberAction";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";
import UpdateJson from "../CommonFiles/UpdateJson";
import moment from "moment";

class WeekNumberView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  state = { cal: "", selectedWeekNumbers: "", drawerIsOpen: false };

  componentDidMount() {
    this.props.getWeekYearObject();
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleSelectedWeekNumer = (value) => {
    this.setState({ selectedWeekNumbers: value });
  };

  handleChange = (event) => {
    this.props.updateCalendarView(event.target.value);
    this.setState({ cal: event.target.value });
  };
  getPreviousDate = (date = new Date()) => {
    const previousDate = new Date(date.getTime());
    previousDate.setDate(date.getDate() - 6);
    return previousDate;
  };
  render() {
    const { weekYears, weekNumbers } = this.props;
    const { selectedWeekNumbers } = this.state;
    const weekYearsList = weekYears.map((week) => week.year);
    const weekNumbersList = weekNumbers.map((week) => week.weakNumber);
    return (
      <Box
        style={{
          visibility: this.props.flag ? "hidden" : "visible",
          width: this.props.flag ? "0px" : "250px",
        }}
      >
        <FormControl
          className="week-number-view-form-control"
          fullWidth
          sx={{
            "& .MuiSelect-select": {
              maxHeight: "30px",
            },
            width: 250,
            "& .MuiInputBase-root": {
              height: "30px",
            },
            "& .MuiFormControlLabel-root": {
              display: "flex",
              justifyContent: "center",
            },
          }}
          size="small"
        >
          <InputLabel sx={{ fontSize: "14px", lineHeight: "14px" }}>
            {selectedWeekNumbers ? selectedWeekNumbers : "Week Number"}
          </InputLabel>
          <Select
            sx={{
              "& .MuiGrid-root": {
                padding: "0px",
              },
              "& .MuiPaper-root": {
                backgroundColor: "crimson",
                borderRadius: "8px",
              },
              "& .MuiList-root": {
                padding: "0px",
              },
              padding: "0px",
            }}
            open={this.state.open}
            onClose={() => {
              this.handleClose();
            }}
            onOpen={() => {
              this.handleOpen();
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.cal}
            label="Week Number"
            onChange={this.handleChange}
          >
            <section
              style={{
                background: "#FFFFFF",
                minHeight: "290px",
                minWidth: "340px",
              }}
            >
              <div style={{ display: "flex" }}>
                <p className="week-number-year-text">Year</p>
                <p className="week-number-text">Week Number</p>
              </div>
              <Grid container>
                <Grid item xs={3} className="week-number-view-left-grid">
                  <WeekYear
                    weekYearsList={weekYearsList}
                    handleSelectedWeekNumer={this.handleSelectedWeekNumer}
                  />
                </Grid>

                <Grid item xs={9} className="week-number-view-right-grid">
                  <WeekNumber
                    handleSelectedWeekNumer={this.handleSelectedWeekNumer}
                  />
                </Grid>
              </Grid>
            </section>

            <div
              style={{
                width: "120px",
                marginLeft: "70px",
                textAlign: "center",
                display: "flex",
              }}
            >
              <button
                onClick={() => {
                  if (this.props.selectedWeekNumbers.length > 0)
                    this.props.removeAllSelectedWeekNumbers();
                  this.props.updateSelectedYear("");
                  this.handleSelectedWeekNumer("");
                  this.setState({ selectedYear: "" });
                  this.props.getWeekNumberSuccess([]);
                  UpdateJson([
                    {
                      key: "fromDate",
                      value: moment(new Date()).format("DD/MM/YYYY"),
                    },
                    {
                      key: "toDate",
                      value: moment(new Date(this.getPreviousDate())).format(
                        "DD/MM/YYYY"
                      ),
                    },
                  ]);
                }}
                className={
                  "clear-all-button-in-calendar"
                }
              >
                Clear All
              </button>
              <button
                className={
                  this.props.selectedWeekNumbers.length > 0
                  ? "weekyear-button"
                  : "weekyear-button-disable"
                }
                onClick={() => {
                  if (this.props.selectedWeekNumbers.length > 0) {
                    this.setState({ open: false });
                    if (window.location.pathname == "/offers") {
                      this.props.getOfferByUserPermission();
                    } else if (window.location.pathname == "/flyers") {
                      this.props.getFlyersPageDataObject(true);
                    }
                  }
                }}
              >
                Apply
              </button>
            </div>
          </Select>
        </FormControl>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    weekYears: state.getWeekYear.weekYears,
    weekNumbers: state.getWeekNumber.weekNumbers,
    selectedWeekNumbers: state.getWeekNumber.selectedWeekNumbers,
    formData: state.filterForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWeekYearObject: (newValue) => dispatch(getWeekYearObject(newValue)),

    updateCalendarView: (newValue) => dispatch(updateCalendarView(newValue)),
    updateSelectedYear: (newValue) => dispatch(updateSelectedYear(newValue)),
    removeAllSelectedWeekNumbers: (weekNumber) =>
      dispatch(removeAllSelectedWeekNumbers(weekNumber)),
    getWeekNumberSuccess: (weekNumber) =>
      dispatch(getWeekNumberSuccess(weekNumber)),
    resetFromAndToDate: () => dispatch(resetFromAndToDate()),
    getOfferByUserPermission: (src) =>
      dispatch(getOfferByUserPermission(src, true)),
    getFlyersPageDataObject: (flag) => dispatch(getFlyersPageDataObject(flag)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WeekNumberView);
