import {
  ADD_TO_COMPARE_LIST,
  REMOVE_FROM_COMPARE_LIST,
  REMOVE_ALL_FROM_COMPARE_LIST,
} from "../actionTypes";

export const compareOfferList = (offerItemForCompare) => {
  return {
    type: ADD_TO_COMPARE_LIST,
    payload: offerItemForCompare,
  };
};

export const removeItemFromCompareOffersList = (offerItem) => {
  return {
    type: REMOVE_FROM_COMPARE_LIST,
    payload: offerItem,
  };
};

export const removeAllOfferFromCompareList = () => {
  return {
    type: REMOVE_ALL_FROM_COMPARE_LIST,
  };
};
