import React, { Component } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import {
  removeCartAllOffers,
  removeSavedOfferObject,
} from "../../store/actions/removeSavedOfferAction";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import CompareButtomLineCrossIcon from "../../images/CompareBottomLineCrossIcon.png";
import Loader from "../CommonFiles/Loader";
import ApiCalls from "../ApiList/apiCalls";
import { removeAllSavedOfferFromExportList } from "../../store/actions/exportSavedOfferAction";

const ApiList = new ApiCalls();
class Delete extends Component {
  constructor(props) {
    super(props);
  }
  state = { hover: false };

  handleMouseOver = (e) => {
    this.setState({ hover: true });
  };
  handleMouseLeave=()=>{
     this.setState({ hover: false });
  }

  render() {
    let deleteditem = [];
    this.props.data.map((item) => {
      deleteditem.push(item.id);
    });
    let id = deleteditem.toString();
    return (
      <>
        <Loader open={this.props.cartLoading} />
        <Stack direction="row" spacing={2}>
          <button
            onMouseOver={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            variant="outlined"
            style={{
              textTransform: "none",
              cursor: !deleteditem.length ? "context-menu" : "pointer",
              color: !deleteditem.length
                ? "#C4C4C4"
                : this.state.hover
                ? "#FFF"
                : "#e24545",
              border: !deleteditem.length
                ? "1px solid #C4C4C4"
                : "1px solid #e24545",
              maxWidth: "90px",
              width: "100%",
              height: "30px",
              backgroundColor: !deleteditem.length
                ? "#FFF"
                : this.state.hover
                ? "#e24545"
                : "#FFF",
              borderRadius: "5px",
              marginLeft: "20px",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
            }}
            disabled={!deleteditem.length}
            starticon={<img src={CompareButtomLineCrossIcon} alt="" />}
            onClick={async () => {
              if (!deleteditem.length) {
                const message = "No Offers Selected.";
                this.props.enqueueSnackbar(message, {
                  variant: "error",
                });
              } else {
                await ApiList.removeSavedOfferObjectData(id).then(
                  (response) => {
                    const newToken = response.headers.token;
                    const userData = JSON.parse(
                      localStorage.getItem("userData")
                    );
                    const newUserData = { ...userData, token: newToken };
                    localStorage.setItem(
                      "userData",
                      JSON.stringify(newUserData)
                    );
                    const customEvent = new CustomEvent("removecount", {
                      detail: {
                        removeCount: response.data.saveOfferCount,
                      },
                    });
                    document.dispatchEvent(customEvent);
                  }
                );
                this.props.removeCartAllOffers();
                this.props.removeAllSavedOfferFromExportList();
                await this.props.postSavedOfferObject(
                  this.props.savedOfferDataSorting,
                  true
                );
              }
            }}
          >
            Remove
          </button>
        </Stack>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    cartLoading: state.postSavedOfferData.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSavedOfferObject: (itemId) =>
      dispatch(removeSavedOfferObject(itemId)),
    postSavedOfferObject: (postObj) =>
      dispatch(postSavedOfferObject(postObj, true)),
    removeCartAllOffers: () => dispatch(removeCartAllOffers()),
    removeAllSavedOfferFromExportList: () =>
      dispatch(removeAllSavedOfferFromExportList()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Delete));
