import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  REMOVE_SAVED_OFFER_REQUEST,
  REMOVE_SAVED_OFFER_SUCCESS,
  REMOVE_SAVED_OFFER_FAILURE,
  CART_SELECTED_OFFER_ITEMS,
  CART_CLEAR_SELECTED_OFFER_ITEMS,
  CART_REMOVE_SELECTED_OFFER_ITEMS,
  CART_SELECT_ALL_OFFERS,
  CART_REMOVE_ALL_OFFERS,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const removeSavedOfferRequest = () => {
  return {
    type: REMOVE_SAVED_OFFER_REQUEST,
  };
};

export const removeSavedOfferSuccess = (removeOfferObject) => {
  return {
    type: REMOVE_SAVED_OFFER_SUCCESS,
    payload: removeOfferObject,
  };
};

export const removeSavedOfferFailure = (error) => {
  return {
    type: REMOVE_SAVED_OFFER_FAILURE,
    payload: error,
  };
};

export const selectedCartOfferItems = (offerItem) => {
  return {
    type: CART_SELECTED_OFFER_ITEMS,
    payload: offerItem,
  };
};

export const removeCartSelectedOfferItem = (offerItem) => {
  return {
    type: CART_REMOVE_SELECTED_OFFER_ITEMS,
    payload: offerItem,
  };
};

export const clearCartSelectedOfferItems = () => {
  return {
    type: CART_CLEAR_SELECTED_OFFER_ITEMS,
  };
};

export const selectCartAllOffers = (SelectedOfferData) => {
  return {
    type: CART_SELECT_ALL_OFFERS,
    payload: SelectedOfferData,
  };
};

export const removeCartAllOffers = () => {
  return {
    type: CART_REMOVE_ALL_OFFERS,
  };
};

export const removeSavedOfferObject = (itemId, loading = false) => {
  return (dispatch) => {
    if (loading) {
      dispatch(removeSavedOfferRequest());
    }
    ApiList.removeSavedOfferObjectData(itemId)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const removeOfferObjectData = response.data;
        dispatch(removeSavedOfferSuccess(removeOfferObjectData));
         const customEvent = new CustomEvent("removecount", {
           detail: {
             removeCount: response.data.saveOfferCount,
             },
         });
         document.dispatchEvent(customEvent);
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(removeSavedOfferFailure(error.message));
      });
  };
};
