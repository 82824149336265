import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoutes = () => {    
const userData = JSON.parse(localStorage.getItem("userData"));
  return (
    JSON.stringify(userData)!="{}"?<Outlet/>:<Navigate to="/"/>
  );
};

export default ProtectedRoutes;
