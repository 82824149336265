import React, { Component } from "react";
import Carousel from "react-material-ui-carousel";
import ImageItem from "./ImageItem";

class ImageSliderCarousel extends Component {
  render() {
    const { flyerPagesList, currentIndex } = this.props;
    return (
      <>
        <section className="mui-image-slider-main-section">
          <Carousel
            autoPlay={false}
            index={currentIndex}
            navButtonsAlwaysVisible={true}
            onChange={(next, previous) =>{
              this.props.handleChange(next, previous)
            }
            }
          >
            {flyerPagesList.map((flyerPage) => (
              <ImageItem key={flyerPage.Id} flyerPage={flyerPage} />
            ))}
          </Carousel>
        </section>
      </>
    );
  }
}

export default ImageSliderCarousel;
