import {
  GET_APPLY_SAVED_FILTER_REQUEST,
  GET_APPLY_SAVED_FILTER_SUCCESS,
  GET_APPLY_SAVED_FILTER_FAILURE,
  GET_APPLY_SAVED_FLAG,
} from "../actionTypes";

const initialState = {
  loading: true,
  applyFilterData: {},
  savedFilterFlag: 0,
  error: "",
};

const getApplySavedFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLY_SAVED_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLY_SAVED_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        applyFilterData: action.payload,
        error: "",
      };
    case GET_APPLY_SAVED_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_APPLY_SAVED_FLAG:
      return {
        ...state,
        loading: false,
        savedFilterFlag: action.payload,
        error: "",
      };
    default:
      return state;
  }
};

export default getApplySavedFilterReducer;
