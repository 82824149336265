import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_VIEW,
} from "../actionTypes";

const initialState = {
  loading: true,
  profileData: {},
  profileView: 0,
  error: "",
};

const getProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload,
        error: "",
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PROFILE_VIEW:
      return {
        ...state,
        profileView: action.payload,
      };

    default:
      return state;
  }
};

export default getProfileReducer;
