import {
  GET_SAVE_SEARCH_SHARE_REQUEST,
  GET_SAVE_SEARCH_SHARE_SUCCESS,
  GET_SAVE_SEARCH_SHARE_FAILURE,
} from "../actionTypes";

const initialState = {
  loading: true,
  SaveSearchShareData: [],
  error: "",
};

const getSaveSearchShareReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAVE_SEARCH_SHARE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_SAVE_SEARCH_SHARE_SUCCESS:
      return {
        ...state,
        loading: false,
        SaveSearchShareData: action.payload,
        error: "",
      };

    case GET_SAVE_SEARCH_SHARE_FAILURE:
      return {
        ...state,
        loading: false,
        SaveSearchShareData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getSaveSearchShareReducer;
