import {
  ADD_TO_COMPARE_LIST,
  REMOVE_FROM_COMPARE_LIST,
  REMOVE_ALL_FROM_COMPARE_LIST,
} from "../actionTypes";

const initialState = {
  compareOfferList: [],
  error: "",
};

const compareOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_COMPARE_LIST:
      const itemsForCompareOffersList = new Set([
        ...state.compareOfferList,
        action.payload,
      ]);
      return {
        ...state,
        compareOfferList: [...itemsForCompareOffersList],
      };

    case REMOVE_FROM_COMPARE_LIST:
      const newList = state.compareOfferList.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        compareOfferList: newList,
      };
    case REMOVE_ALL_FROM_COMPARE_LIST:
      return {
        ...state,
        compareOfferList: [],
      };

    default:
      return state;
  }
};

export default compareOfferReducer;