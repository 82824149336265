import React, { Component } from "react";
import ReactPaginate from "react-paginate";

class ReactPaginatePagination extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { totalRecords, pagesize, initialPage, handlePageClick } = this.props;
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    return (
      <ReactPaginate
        forcePage={userFilterData.pageNo-1}
        previousLabel="<<"
        nextLabel=">>"
        breakLabel={"..."}
        pageCount={Math.ceil(totalRecords / pagesize)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onClick={(event) => handlePageClick(event)}
        containerClassName={"pagination justify-content-left"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    );
  }
}

export default ReactPaginatePagination;
