import React, { Component } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
class Loader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer }}
        open={this.props.open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default Loader;
