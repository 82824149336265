import React, { Component } from "react";
import MainFilters from "../MainFilter/MainFilter";
import "./TopFilter.css";
import Calendar from "./Calender";
import FlyersAdditionalFilters from "../Flyers/FlyersAdditionalFilters";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
class TopFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarView: true,
    };
  }
  componentDidMount() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (userFilterData)
      this.setState({ calendarView: userFilterData.calendarView });
  }
  render() {
    const { flyersPage } = this.props;
    return (
      <section
        style={
          flyersPage
            ? {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }
            : {}
        }
        className="topFilterSec"
      >
        <div className="customContainer">
          <div className="row">
            <div
              className="col-md-12 flyeraddOn"
              style={
                flyersPage
                  ? {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : {}
              }
            >
              <div
                className="main-section"
                style={{
                  width: flyersPage ? "905px" : "",
                }}
              >
                <div className="first-section">
                  <Calendar />
                </div>
                <div className="sepration-div"></div>

                <div
                  className="second-section"
                  style={{ width: flyersPage ? "867px" : "" }}
                >
                  <MainFilters flyersPage={this.props.flyersPage} />
                </div>
              </div>
              {flyersPage ? <FlyersAdditionalFilters /> : null}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TopFilter;
