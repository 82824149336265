import {
  UPDATE_TO_DATE,
  UPDATE_FROM_DATE,
  UPDATE_CATEGORY,
  UPDATE_CITY,
  UPDATE_COUNTRY,
  UPDATE_ITEM,
  UPDATE_RETAILER,
  UPDATE_SUB_CATEGORY,
  UPDATE_BRAND,
  UPDATE_PRODUCT,
  UPDATE_PACKSIZE,
  UPDATE_PROMOTYPE,
  UPDATE_OFFER_PRICE,
  UPDATE_DISCOUNT_RANGE,
  UPDATE_PREFERENCE,
  UPDATE_SORTING,
  UPDATE_PAGESIZE,
  UPDATE_PAGE_NUMBER,
  CLEAR_SEARCH,
  UPDATE_VIEW_BY,
  UPDATE_ANLYTICS_SEARCHTEXT,
  UPDATE_SUGGESTION_TEXT,
  UPDATE_CALENDAR_VIEW,
  UPDATE_ATTRIBUTE,
  UPDATE_SEARCHNAME,
  UPDATE_DESCRIPTION,
  UPDATE_IS_DUPLICATE,
  UPDATE_SAVED_SEARCH_ID,
  UPDATE_NAV_VIEW,
  RESET_FROM_AND_TO_DATE,
  UPDATE_PRO_VIEW,
  UPDATE_USER_JSON,
} from "../actionTypes";


export const updateUserJson = (newValue) => {
  return {
    type: UPDATE_USER_JSON,
    payload: newValue,
  };
};


export const updateToDate = (newValue) => {
  const tdate = localStorage.getItem("toDate");
  const customEvent = new CustomEvent("todateChange");
  document.dispatchEvent(customEvent);
  return {
    type: UPDATE_TO_DATE,
    payload: tdate,
  };
};

export const updateFromDate = (newValue) => {
  const fdate = localStorage.getItem("fromDate");
  return {
    type: UPDATE_FROM_DATE,
    payload: fdate,
  };
};

export const updateCountry = (newValue) => {
  return {
    type: UPDATE_COUNTRY,
    payload: newValue,
  };
};

export const updateCity = (newValue) => {
  return {
    type: UPDATE_CITY,
    payload: newValue,
  };
};

export const updateRetailer = (newValue) => {
  return {
    type: UPDATE_RETAILER,
    payload: newValue,
  };
};

export const updateCategory = (newValue) => {
  return {
    type: UPDATE_CATEGORY,
    payload: newValue,
  };
};
export const updateSubCategory = (newValue) => {
  return {
    type: UPDATE_SUB_CATEGORY,
    payload: newValue,
  };
};
export const updateItem = (newValue) => {
  return {
    type: UPDATE_ITEM,
    payload: newValue,
  };
};

export const updateBrand = (newValue) => {
  return {
    type: UPDATE_BRAND,
    payload: newValue,
  };
};

export const updateProduct = (newValue) => {
  return {
    type: UPDATE_PRODUCT,
    payload: newValue,
  };
};

export const updatePackSize = (newValue) => {
  return {
    type: UPDATE_PACKSIZE,
    payload: newValue,
  };
};

export const updatePromoType = (newValue) => {
  return {
    type: UPDATE_PROMOTYPE,
    payload: newValue,
  };
};

export const updateOfferPrice = (newValue) => {
  return {
    type: UPDATE_OFFER_PRICE,
    payload: newValue,
  };
};

export const updateDiscountRange = (newValue) => {
  return {
    type: UPDATE_DISCOUNT_RANGE,
    payload: newValue,
  };
};
export const updatePreference = (newValue) => {
  return {
    type: UPDATE_PREFERENCE,
    payload: newValue,
  };
};
export const updateSorting = (newValue) => {
  return {
    type: UPDATE_SORTING,
    payload: newValue,
  };
};
export const updateViewBy = (newValue) => {
  return {
    type: UPDATE_VIEW_BY,
    payload: newValue,
  };
};

export const updatePageSize = (newValue) => {
  return {
    type: UPDATE_PAGESIZE,
    payload: newValue,
  };
};
export const updatePageNumber = (newValue) => {
  return {
    type: UPDATE_PAGE_NUMBER,
    payload: newValue,
  };
};
export const updateAnlyticsSearchText = (newValue) => {
  return {
    type: UPDATE_ANLYTICS_SEARCHTEXT,
    payload: newValue,
  };
};

export const updateSuggestionText = (newValue) => {
  return {
    type: UPDATE_SUGGESTION_TEXT,
    payload: newValue,
  };
};
export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  };
};

export const updateCalendarView=(value)=>{
  return{
    type:UPDATE_CALENDAR_VIEW,
    payload:value
  }
}
export const updateAttribute = (value) => {
  return {
    type: UPDATE_ATTRIBUTE,
    payload: value,
  }; 
}
export const updateSearchName = (value) => {
  return {
    type: UPDATE_SEARCHNAME,
    payload: value,
  }; 
}
export const updateDescription = (value) => {
  return {
    type: UPDATE_DESCRIPTION,
    payload: value,
  };
} 
export const updateIsDuplicate = (value) => {
  return {
    type: UPDATE_IS_DUPLICATE,
    payload: value,
  };
}
export const updateSavedSearchId = (value) => {
  return {
    type: UPDATE_SAVED_SEARCH_ID,
    payload: value,
  };
};
export const updateNavView = (value) => {
  return {
    type: UPDATE_NAV_VIEW,
    payload: value,
  };
};
export const updateProView = (value) => {
  return {
    type: UPDATE_PRO_VIEW,
    payload: value,
  };
};


export const  resetFromAndToDate=()=>{
  return{
    type:RESET_FROM_AND_TO_DATE,
  }
}
