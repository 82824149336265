import {
  SAVED_OFFERS_COUNT,
  UPDATE_PAGE,
  SAVED_FILTER_COUNT,
} from "../actionTypes";
const initialState = {
  isPage: "",
  savedOfferCount:0,
  savedFilterCount: 0,
};

const updateUserManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGE:
      return {
        ...state,
        isPage: action.payload,
      };
    case SAVED_OFFERS_COUNT:
      return {
        ...state,
        savedOfferCount: action.payload,
      };
    case SAVED_FILTER_COUNT:
      return {
        ...state,
        savedFilterCount: action.payload,
      };
    default:
      return state;
  }
};

export default updateUserManagerReducer;
