import {
    POST_SAVED_OFFER_OBJECT_REQUEST,
    POST_SAVED_OFFER_OBJECT_SUCCESS,
    POST_SAVED_OFFER_OBJECT_FAILURE,
  } from "../actionTypes";
  
  const initialState = {
    savedOfferObjectData: {},
    loading: false,
    error: "",
    sorting: { sortType: 5, sortOrder: "L" },
  };
  
  const postSavedOfferObjectReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_SAVED_OFFER_OBJECT_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };
  
      case POST_SAVED_OFFER_OBJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          savedOfferObjectData: action.payload,
          error: "",
        };
  
      case POST_SAVED_OFFER_OBJECT_FAILURE:
        return {
          ...state,
          loading: false,
          savedOfferObjectData: {},
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default postSavedOfferObjectReducer;