import React from "react";

const SavedFilterPagination = ({
  dataPerPage,
  totalEntries,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalEntries / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav style={{ marginTop: "7px" }}>
      <ul className="pagination saved-filters-table-container">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item" style={{ cursor: "pointer" }}>
            <a
              onClick={() => paginate(number)}
              className="page-link"
              style={{
                backgroundColor: currentPage === number ? "#C4C4C4" : "",
                color: currentPage === number ? "#000" : "#000",
                cursor: currentPage === number ? "default" : "pointer",
              }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SavedFilterPagination;
