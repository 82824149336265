const saveCurrentFilterStyles = {
  buttonSizeStyling: { 
    maxHeight: "30px", 
    margin: "7px auto" 
  },
  buttonBackgroundStyling: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    // padding: "8px 12px",
    gap: "6px",
    width: "176px",
    height: "30px",
    background: "#85CEC0",
    borderRadius: "5px",
    whiteSpace: "nowrap",
    textTransform: "none",

    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FFFFFF",
  },
  dialogBackgroundStyling: {
    display: "flex",
    justifyContent: "space-between",
    background: "linear-gradient(90.49deg, #A7E8A5 -3.83%, #73C0CE 102.84%)",
    borderRadius: "5px 5px 0px 0px",
  },
  divStyling: {
    width: "500px",
  },
  saveButtonDialogStyling: {
    height: "34px",
    background: "#2BAE48",
    borderRadius: "5px",
    marginLeft: "13rem",
  },
  dialogBackgroundStylingg: {
    width: "800px",
    left: "82252px",
    top: "-56760px",
    borderradius: "0px",
  },
};
export default saveCurrentFilterStyles;
