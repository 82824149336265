export const FETCH_ALL_DATA_BY_PERMISSION_REQUEST =
  "FETCH_ALL_DATA_BY_PERMISSION_REQUEST";
export const FETCH_ALL_DATA_BY_PERMISSION_SUCCESS =
  "FETCH_ALL_DATA_BY_PERMISSION_SUCCESS";
export const FETCH_ALL_DATA_BY_PERMISSION__FAILURE =
  "FETCH_ALL_DATA_BY_PERMISSION__FAILURE";

// ********************************************************************************************************************

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_CITY = "UPDATE_CITY";
export const UPDATE_RETAILER = "UPDATE_RETAILER";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PACKSIZE = "UPDATE_PACKSIZE";
export const UPDATE_PROMOTYPE = "UPDATE_PROMOTYPE";
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";
export const UPDATE_OFFER_PRICE = "UPDATE_OFFER_PRICE";
export const UPDATE_DISCOUNT_RANGE = "UPDATE_DISCOUNT_RANGE";
export const UPDATE_PREFERENCE = "UPDATE_PREFERENCE";
export const UPDATE_SORTING = "UPDATE_SORTING";
export const UPDATE_PAGESIZE = "UPDATE_PAGESIZE";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const UPDATE_VIEW = "UPDATE_VIEW";
export const UPDATE_VIEW_BY = "UPDATE_VIEW_BY";
export const UPDATE_ANLYTICS_SEARCHTEXT = "UPDATE_ANLYTICS_SEARCHTEXT";
export const UPDATE_SUGGESTION_TEXT = "UPDATE_SUGGESTION_TEXT";
export const UPDATE_CALENDAR_VIEW = "UPDATE_CALENDAR_VIEW";
export const TOGGLE_INITIALIZE = "TOGGLE_INITIALIZE";
export const UPDATE_SEARCHNAME = "UPDATE_SEARCHNAME";
export const UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION";
export const UPDATE_IS_DUPLICATE = "UPDATE_IS_DUPLICATE";
export const UPDATE_SAVED_SEARCH_ID = "UPDATE_SAVED_SEARCH_ID";
export const SAVED_SEARCH_COUNT = "SAVED_SEARCH_COUNT";
export const UPDATE_NAV_VIEW = "UPDATE_NAV_VIEW";
export const RESET_FROM_AND_TO_DATE = "RESET_FROM_AND_TO_DATE";
export const UPDATE_PRO_VIEW = "UPDATE_PRO_VIEW";
export const UPDATE_USER_JSON = "UPDATE_USER_JSON";

// ********************************************************************************************************************

export const OFFER_IMAGE_REQUEST = "OFFER_IMAGE_REQUEST";
export const OFFER_IMAGE_SUCCESS = "OFFER_IMAGE_SUCCESS";
export const OFFER_IMAGE_FAILURE = "OFFER_IMAGE_FAILURE";
export const BRAND_COPY_DATA = "BRAND_COPY_DATA";
export const PRODUCT_COPY_DATA = "PRODUCT_COPY_DATA";
export const PACKSIZE_COPY_DATA = "PACKSIZE_COPY_DATA";
export const ATTRIBUTES_COPY_DATA = "ATTRIBUTES_COPY_DATA";
export const ATTRIBUTE_COPY_DATA = "ATTRIBUTE_COPY_DATA";
export const LOADING_ACTIVATE = "LOADING_ACTIVATE";
export const OFFER_PRICE_COPY_DATA = "OFFER_PRICE_COPY_DATA";
export const DISCOUNT_RANGE_COPY_DATA = "DISCOUNT_RANGE_COPY_DATA";


// ********************************************************************************************************************

export const UPDATE_TO_DATE = "UPDATE_TO_DATE";
export const UPDATE_FROM_DATE = "UPDATE_FROM_DATE";

// ********************************************************************************************************************

export const OFFER_DATA_COPY = "OFFER_DATA_COPY";
export const BRAND_DATA_COPY = "BRAND_DATA_COPY";
export const PRODUCT_DATA_COPY = "PRODUCT_DATA_COPY";

// ********************************************************************************************************************

export const GET_SAVED_OFFER_OBJECT_REQUEST = "GET_SAVED_OFFER_OBJECT_REQUEST";
export const GET_SAVED_OFFER_OBJECT_SUCCESS = "GET_SAVED_OFFER_OBJECT_SUCCESS";
export const GET_SAVED_OFFER_OBJECT_FAILURE = "GET_SAVED_OFFER_OBJECT_FAILURE";
export const SELECTED_OFFER_ITEMS = "SELECTED_OFFER_ITEMS";
export const CLEAR_SELECTED_OFFER_ITEMS = "CLEAR_SELECTED_OFFER_ITEMS";
export const REMOVE_SELECTED_OFFER_ITEMS = "REMOVE_SELECTED_OFFER_ITEMS";
export const SELECT_ALL_OFFERS = "SELECT_ALL_OFFERS";
export const REMOVE_ALL_OFFERS = "REMOVE_ALL_OFFERS";
export const SAVED_OFFERS_COUNT = "SAVED_OFFERS_COUNT";
export const UPDATE_SAVED_OFFER_SORTING = "UPDATE_SAVED_OFFER_SORTING";

// ********************************************************************************************************************
export const EXPORT_SAVED_OFFER_REQUEST = "EXPORT_SAVED_OFFER_REQUEST";
export const EXPORT_SAVED_OFFER_SUCCESS = "EXPORT_SAVED_OFFER_SUCCESS";
export const EXPORT_SAVED_OFFER_FAILURE = "EXPORT_SAVED_OFFER_FAILURE";
export const EXPORT_OFFERS = "EXPORT_OFFERS";
export const EXPORT_SAVED_OFFERS = "EXPORT_SAVED_OFFERS";
export const REMOVE_ITEM_FROM_EXPORT_OFFERS_LIST =
  "REMOVE_ITEM_FROM_EXPORT_OFFERS_LIST";
export const Remove_ITEM_FROM_EXPORT_SAVED_OFFERS_LIST =
  "Remove_ITEM_FROM_EXPORT_SAVED_OFFERS_LIST";
export const SELECT_ALL_OFFERS_FOR_EXPORT = "SELECT_ALL_OFFERS_FOR_EXPORT";
export const SELECT_ALL_SAVED_OFFERS_FOR_EXPORT =
  "SELECT_ALL_SAVED_OFFERS_FOR_EXPORT";
export const REMOVE_ALL_OFFERS_FROM_EXPORT_LIST =
  "REMOVE_ALL_OFFERS_FROM_EXPORT_LIST";
export const REMOVE_ALL_SAVED_OFFERS_FROM_EXPORT_LIST =
  "REMOVE_ALL_SAVED_OFFERS_FROM_EXPORT_LIST";

// ********************************************************************************************************************
export const SEARCH_SUGGESTION_REQUEST = "SEARCH_SUGGESTION_REQUEST";
export const SEARCH_SUGGESTION_SUCCESS = "SEARCH_SUGGESTION_SUCCESS";
export const SEARCH_SUGGESTION_FAILURE = "SEARCH_SUGGESTION_FAILURE";
export const SEARCH_SUGGESTION_FLAG = "SEARCH_SUGGESTION_FLAG";

// ********************************************************************************************************************

export const POST_SAVED_OFFER_OBJECT_REQUEST =
  "POST_SAVED_OFFER_OBJECT_REQUEST";
export const POST_SAVED_OFFER_OBJECT_SUCCESS =
  "POST_SAVED_OFFER_OBJECT_SUCCESS";
export const POST_SAVED_OFFER_OBJECT_FAILURE = "POST_SAVED_OFFER_OBJECT_FAILUR";

// ********************************************************************************************************************

export const GET_OFFER_DETAILS_REQUEST = "GET_OFFER_DETAILS_REQUEST";
export const GET_OFFER_DETAILS_SUCCESS = "GET_OFFER_DETAILS_SUCCESS";
export const GET_OFFER_DETAILS_FAILURE = "GET_OFFER_DETAILS_FAILURE";
export const IMAGE_POP_UP_OPEN = "IMAGE_POP_UP_OPEN";

// ********************************************************************************************************************
export const REMOVE_SAVED_OFFER_REQUEST = "REMOVE_SAVED_OFFER_REQUEST";
export const REMOVE_SAVED_OFFER_SUCCESS = "REMOVE_SAVED_OFFER_SUCCESS";
export const REMOVE_SAVED_OFFER_FAILURE = "REMOVE_SAVED_OFFER_FAILURE";
export const CART_SELECTED_OFFER_ITEMS = "CART_SELECTED_OFFER_ITEMS";
export const CART_CLEAR_SELECTED_OFFER_ITEMS =
  "CART_CLEAR_SELECTED_OFFER_ITEMS";
export const CART_REMOVE_SELECTED_OFFER_ITEMS =
  "CART_REMOVE_SELECTED_OFFER_ITEMS";
export const CART_SELECT_ALL_OFFERS = "CART_SELECT_ALL_OFFERS";
export const CART_REMOVE_ALL_OFFERS = "CART_REMOVE_ALL_OFFERS";

// ********************************************************************************************************************
export const GET_IP_ADDRESS = "GET_IP_ADDRESS";

// ********************************************************************************************************************
export const GET_FLYER_PAGES_REQUEST = "GET_FLYER_PAGES_REQUEST";
export const GET_FLYER_PAGES_SUCCESS = "GET_FLYER_PAGES_SUCCESS";
export const GET_FLYER_PAGES_FAILURE = "GET_FLYER_PAGES_FAILURE";
export const ADD_TO_COMPARE_LIST = "ADD_TO_COMPARE_LIST";
export const REMOVE_FROM_COMPARE_LIST = "REMOVE_FROM_COMPARE_LIST";
export const REMOVE_ALL_FROM_COMPARE_LIST = "REMOVE_ALL_FROM_COMPARE_LIST";



export const  GET_NOTIFICATION_HOME = "GET_NOTIFICATION_HOME";

// ********************************************************************************************************************

export const FLYERS_PAGE_RENDER = "FLYERS_PAGE_RENDER";
export const FLYERS_DETAIL_PAGE = "FLYERS_DETAIL_PAGE";
export const FLYERS_DETAIL_GRID_VIEW = "FLYERS_DETAIL_GRID_VIEW";

export const UPDATE_FLYERS_COUNTRY = "UPDATE_FLYERS_COUNTRY";
export const UPDATE_FLYERS_CITY = "UPDATE_FLYERS_CITY";
export const UPDATE_FLYERS_RETAILER = "UPDATE_FLYERS_RETAILER";
export const UPDATE_FLYERS_START_DATE = "UPDATE_FLYERS_START_DATE";
export const UPDATE_FLYERS_END_DATE = "UPDATE_FLYERS_END_DATE";
export const UPDATE_FLYERS_PAGESIZE = "UPDATE_FLYERS_PAGESIZE";
export const UPDATE_FLYERS_SORTING = "UPDATE_FLYERS_SORTING";
export const UPDATE_FLYER_PAGE_NUMBER = "UPDATE_FLYER_PAGE_NUMBER";

// ********************************************************************************************************************


export const FLYERS_PAGE_LIST_REQUEST="FLYERS_PAGE_LIST_REQUEST"
export const FLYERS_PAGE_LIST_SUCCESS="FLYERS_PAGE_LIST_SUCCESS"
export const FLYERS_PAGE_LIST_FAILURE = "FLYERS_PAGE_LIST_FAILURE";

// ********************************************************************************************************************
export const GET_FLYER_DETAILS_REQUEST="GET_FLYER_DETAILS_REQUEST"
export const GET_FLYER_DETAILS_SUCCESS="GET_FLYER_DETAILS_SUCCESS"
export const GET_FLYER_DETAILS_FAILURE = "GET_FLYER_DETAILS_FAILURE";
export const FLYER_CLEAR_SEARCH = "FLYER_CLEAR_SEARCH";
export const UPDATE_FLYER_DETAILS_CURRENT_PAGE = "UPDATE_FLYER_DETAILS_CURRENT_PAGE";

// ********************************************************************************************************************
 export const GET_WEEK_YEAR_REQUEST = "GET_WEEK_YEAR_REQUEST";
 export const GET_WEEK_YEAR_SUCCESS = "GET_WEEK_YEAR_SUCCESS";
 export const GET_WEEK_YEAR_FAILURE = "GET_WEEK_YEAR_FAILURE";
 export const SELECTED_YEAR = "SELECTED_YEAR";

// ********************************************************************************************************************

export const GET_WEEK_NUMBER_REQUEST = "GET_WEEK_NUMBER_REQUEST";
export const GET_WEEK_NUMBER_SUCCESS = "GET_WEEK_NUMBER_SUCCESS";
export const GET_WEEK_NUMBER_FAILURE = "GET_WEEK_NUMBER_FAILURE";
export const SELECTED_WEEK_NUMBERS = "SELECTED_WEEK_NUMBERS";
export const REMOVE_SELECTED_WEEK_NUMBERS = "REMOVE_SELECTED_WEEK_NUMBERS";
export const MIN_TO_MAX_SELECTED_WEEK_NUMBERS = "MIN_TO_MAX_SELECTED_WEEK_NUMBERS";
export const REMOVE_ALL_SELECTED_WEEK_NUMBER = "REMOVE_ALL_SELECTED_WEEK_NUMBER";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS ="GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
// ********************************************************************************************************************

export const SAVE_SEARCH_REQUEST = "SAVE_SEARCH_REQUEST";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH_FAILURE = "SAVE_SEARCH_FAILURE";

// ********************************************************************************************************************

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const UPDATE_PROFILE_VIEW = "UPDATE_PROFILE_VIEW";


// ********************************************************************************************************************
export const GET_USER_COMMENT_REQUEST = "GET_USER_COMMENT_REQUEST";
export const GET_USER_COMMENT_SUCCESS = "GET_USER_COMMENT_SUCCESS";
export const GET_USER_COMMENT_FAILURE = "GET_USER_COMMENT_FAILURE";



// ********************************************************************************************************************
 export const USER_FILTER_PERMISSION_REQUEST = "USER_FILTER_PERMISSION_REQUEST";
 export const USER_FILTER_PERMISSION_SUCCESS = "USER_FILTER_PERMISSION_SUCCESS";
 export const USER_FILTER_PERMISSION_FAILURE = "USER_FILTER_PERMISSION_FAILURE";



// ********************************************************************************************************************


export const GET_ADDITIONAL_INFO_REQUEST = "GET_ADDITIONAL_INFO_REQUEST";
export const GET_ADDITIONAL_INFO_SUCCESS = "GET_ADDITIONAL_INFO_SUCCESS";
export const GET_ADDITIONAL_INFO_FAILURE = "GET_ADDITIONAL_INFO_FAILURE";

// ********************************************************************************************************************

export const GET_SAVE_SEARCH_VIEW_REQUEST = "GET_SAVE_SEARCH_VIEW_REQUEST";
export const GET_SAVE_SEARCH_VIEW_SUCCESS = "GET_SAVE_SEARCH_VIEW_SUCCESS";
export const GET_SAVE_SEARCH_VIEW_FAILURE = "GET_SAVE_SEARCH_VIEW_FAILURE";

//*****************************************************************************************
export const GET_SAVE_SEARCH_BY_ID_REQUEST ="GET_SAVE_SEARCH_BY_ID_REQUEST";
export const GET_SAVE_SEARCH_BY_ID_SUCCESS = "GET_SAVE_SEARCH_BY_ID_SUCCESS";
export const GET_SAVE_SEARCH_BY_ID_FAILURE = "GET_SAVE_SEARCH_BY_ID_FAILURE";
// ********************************************************************************************************************

export const GET_SAVE_SEARCH_REQUEST = "GET_SAVE_SEARCH_REQUEST";
export const GET_SAVE_SEARCH_SUCCESS = "GET_SAVE_SEARCH_SUCCESS";
export const GET_SAVE_SEARCH_FAILURE = "GET_SAVE_SEARCH_FAILURE";

//************************************************************************************** */

export const GET_TNC_REQUEST = "GET_TNC_FAILURE";
export const GET_TNC_SUCCESS = "GET_TNC_SUCCESS";
export const GET_TNC_FAILURE = "GET_TNC_FAILURE";
export const GET_PRODUCT_DATA = "GET_PRODUCT_DATA";


export const GET_APPLY_SAVED_FILTER_REQUEST = "GET_APPLY_SAVED_FILTER_REQUEST";
export const GET_APPLY_SAVED_FILTER_SUCCESS = "GET_APPLY_SAVED_FILTER_SUCCESS";
export const GET_APPLY_SAVED_FILTER_FAILURE = "GET_APPLY_SAVED_FILTER_FAILURE";
export const GET_APPLY_SAVED_FLAG = "GET_APPLY_SAVED_FLAG";

//****************************************************************************************** */

export const GET_SAVE_SEARCH_SHARE_REQUEST = "GET_SAVE_SEARCH_SHARE_REQUEST";
export const GET_SAVE_SEARCH_SHARE_SUCCESS = "GET_SAVE_SEARCH_SHARE_SUCCESS";
export const GET_SAVE_SEARCH_SHARE_FAILURE = "GET_SAVE_SEARCH_SHARE_FAILURE";

//****************************************************************************************** */
export const SAVED_FILTER_COUNT = "SAVED_FILTER_COUNT";
export const UPDATE_PAGE = "UPDATE_PAGE";

