import React, { Component } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { connect } from "react-redux";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import img01 from "../images/whtasnewimg/img01.png";
import img07 from "../images/whtasnewimg/img07.png";
import img08 from "../images/whtasnewimg/img08.png";
import img02 from "../images/whtasnewimg/img02.png";
import img03 from "../images/whtasnewimg/img03.png";
import img13 from "../images/whtasnewimg/img13.png";
import img14 from "../images/whtasnewimg/img14.png";
const userData = JSON.parse(localStorage.getItem("userData"));

const drawerWidth = 240;
let url = window.location.pathname;

const headingh2 = {
  fontSize: "22px",
  paddingBottom: "10px",
  borderBottom: "1px solid #ccc",
  marginBottom: "20px",
  lineHeight: "normal",
  marginTop: "10px",
  color: "#084354",
};
const headingh3 = {
  fontSize: "18px",
  fontWeight: 500,
  marginBottom: "8px",
  color: "#256D83",
};

const headingh4 = {
  fontSize: "15px",
  fontWeight: 400,
  marginBottom: "2px",
  color: "#256D83",
  fontStyle: "italic",
};
const paragraphStyle = {
  marginBottom: "20px",
};
const bodyStyles = {
  fontWeight: 400,
  color: "#343434",
  lineHeight: "26px",
  fontSize: "16px",
  backgroundColor: "#f5f7fa",
};
const listTextStyle = {
  color: "#256D83",
  fontsize: "15px !important",
  paddingLeft: "15px",
};
class WhatsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSection: "parent",
    };
  }
  componentDidMount() {
    url = window.location.pathname;
  }
  handleClick = (section) => {
    this.setState((prevState) => ({
      openSection: prevState.openSection === section ? null : section,
    }));
  };
  render() {
    if (!userData) {
      window.location.href="https://analytics.clicflyer.com/Retailer/Retailer/Welcome"
      ;
      //  return (
      //   //  <Navigate
      //   //    to="https://analytics.clicflyer.com/Retailer/Retailer/Welcome"
      //   //  />
      //  );
    } else {
      return (
        <section>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer
              sx={{
                width: drawerWidth,
                height: "20px",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  marginTop: "50px",
                  boxSizing: "border-box",
                  backgroundColor: "#f5f7fa",
                  // maxHeight: "640px",
                  height: "cal(100vh - 100px)",
                },
              }}
              variant="permanent"
              anchor="left"
            >
              <Divider />
              <List>
                {/* Main Menu Items */}
                <ListItem button onClick={() => this.handleClick("parent")}>
                  <a
                    href={url + "#what’s-new-in-offer-bank"}
                    className="dropdown-item"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText
                      style={{ color: "rgb(8, 67, 84)" }}
                      primary="What’s new in Offer Bank"
                    />
                  </a>
                  {this.state.openSection === "parent" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.openSection === "parent"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem button>
                      <a
                        href={url + "#improved-filter-options"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Improved Filter Options"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#compare-offers"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Compare Offers"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#switch-offers-layout"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Switch Offers Layout"
                        />
                      </a>
                    </ListItem>
                    {/* <ListItem button>
                    <a
                      href={url + "#export-offers"}
                      class="dropdown-item"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItemText
                        style={listTextStyle}
                        primary="Export Offers"
                      />
                    </a>
                  </ListItem> */}
                    <ListItem button>
                      <a
                        href={url + "#enhanced-date-picker"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Enhanced Date Picker"
                        />
                      </a>
                    </ListItem>
                    <ListItem button>
                      <a
                        href={url + "#manage-your-account"}
                        className="dropdown-item"
                        style={{ textDecoration: "none" }}
                      >
                        <ListItemText
                          style={listTextStyle}
                          primary="Manage your Account!"
                        />
                      </a>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: "",

                p: 3,
                marginBottom: "-12px",
                maxHeight: "92vh",
              }}
              style={bodyStyles}
            >
              <div
                className="rightCnt"
                style={{ padding: "30px", height: "100%", overflowY: "scroll" }}
              >
                <h2 style={headingh2} id="what’s-new-in-offer-bank">
                  What’s new in Offer Bank
                </h2>

                <h3 style={headingh3} id="improved-filter-options">
                  Improved Filter Options
                </h3>
                <p style={paragraphStyle}>
                  Offers can be filtered on various levels such as Country,
                  City, Retailers, Product hierarchy, Discount, and Price etc.
                </p>
                <h4 style={headingh4}>Global Filters</h4>
                <p style={paragraphStyle}>
                  <b>Country, City, Retailers</b> are the global filters. Once
                  applied, the global filters remain in effect unless manually
                  changed.
                </p>
                <h4 style={headingh4}>Main Filters</h4>
                <p style={paragraphStyle}>
                  <b>Category, Subcategory, and Item</b> are the main filters.
                  Once applied, the main filter remains in effect until manually
                  changed or the global filters are changed.
                </p>
                <h4 style={headingh4}>Refine Filters</h4>
                <p style={paragraphStyle}>
                  Refine filters have been introduced to provide users with
                  additional filtering options based on the main filter
                  selection. Users can filter by
                  <b>
                    &nbsp;Brands, Products and Pack sizes
                  </b>
                  . These filters will get reset once any change is made in
                  Global Filters or the Main filters.
                </p>
                <h4 style={headingh4}>Refine Sliders</h4>
                <p style={paragraphStyle}>
                  <b>Price and Discount</b> sliders have been added as part of
                  the refine filters. Users can adjust the sliders to set the
                  desired price and discount ranges. These filters will get
                  reset once any change is made in Global Filters.
                </p>
                <img
                  style={{ marginBottom: "20px", width: "90%" }}
                  src={img01}
                  alt=""
                />
                <h3 style={headingh3} id="compare-offers">
                  Compare Offers
                </h3>
                <p style={paragraphStyle}>
                  Users can compare offers and see how each offer differs in
                  terms of brand, product, pack size, promo mechanic, promo
                  price, and other parameters.
                </p>
                <ul>
                  <li>Users can select up to 4 offers at a time.</li>
                  <li>Users can export offers for detailed analysis</li>
                </ul>

                <img
                  style={{ width: "90%", marginBottom: "20px" }}
                  src={img07}
                  alt=""
                />
                {/* <img src={img071} style={{ width: "80%" }} /> */}
                <h3 style={headingh3} id="switch-offers-layout">
                  Switch Offers Layout – List View & Grid View
                </h3>
                <p style={paragraphStyle}>
                  User can view the offers in Grid format as well as List
                  format.
                </p>
                <img
                  style={{ width: "90%", marginBottom: "20px" }}
                  src={img08}
                  alt=""
                />
                {/* <h3 style={headingh3} id="export-offers">
                Export Offers
              </h3> */}
                {/* <p style={paragraphStyle}>
                The user can export the offers in an Excel file that contains
                all the details of each offer.
              </p>
              <img
                style={{ width: "90%", marginBottom: "20px" }}
                src={img09}
                alt=""
              />
              <img
                style={{ width: "90%", marginBottom: "20px" }}
                src={img10}
                alt=""
              /> */}
                <h3 style={headingh3} id="enhanced-date-picker">
                  Enhanced Date Picker
                </h3>
                <p style={paragraphStyle}>
                  New Offer Bank allows users to pick dates on either by date
                  range or by week number.
                </p>
                <h4 style={headingh4}>Filter by Date Range</h4>
                <p style={paragraphStyle}>
                  The date picker allows users to select a specific date range
                  to retrieve offers according to their preferences.
                </p>
                <img
                  style={{ width: "50%", marginBottom: "20px" }}
                  src={img02}
                  alt=""
                />
                {/* <h4 style={headingh4}>Filter by Week Number</h4>
                <p style={paragraphStyle}>
                  Users can select a week number to retrieve offer data,
                  providing a more convenient way to navigate through offers. As
                  per ClicFlyer standards, a week is defined as Sunday to
                  Saturday.
                </p>
                <img
                  style={{ width: "50%", marginBottom: "20px" }}
                  src={img03}
                  alt=""
                /> */}
                <h3 style={headingh3} id="manage-your-account">
                  Manage your Account!
                </h3>
                <p style={paragraphStyle}>
                  Our platform offers a seamless way to manage your account
                  details and subscription plans. Here's a quick guide to
                  navigating the account management section:
                </p>
                <h4 style={headingh4}>View Profile</h4>
                <p style={paragraphStyle}>
                  The <b>'View Profile'</b> section provides users with
                  essential account details at a glance, including their
                  username, full name, associated company name, and registered
                  email address.
                </p>
                <img
                  style={{ width: "90%", marginBottom: "20px" }}
                  src={img13}
                  alt=""
                />
                <h4 style={headingh4}>My Subscription Plan</h4>
                <p style={paragraphStyle}>
                  Within the <b>'My Subscription Plan'</b> section, users can
                  find comprehensive details about their subscription, such as
                  the activation date, expiry date, and current status of their
                  plan. This facilitates efficient subscription management and
                  helps users stay informed about their service period and
                  renewal dates
                </p>
                <img
                  // style={designimg}
                  style={{ marginBottom: "20px", width: "90%" }}
                  src={img14}
                  alt=""
                />
              </div>
            </Box>
          </Box>
        </section>
      );
    }
  }
}

export default connect(null, null)(WhatsNew);
