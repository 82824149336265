const UpdateJson = (selectedValue) => {
  var newObject = {};
  if (selectedValue.length > 0) {
    selectedValue.map((item) => {
      var existingObject = JSON.parse(localStorage.getItem("userFilterData"));
      newObject = { ...existingObject, [item.key]: item.value };
      localStorage.setItem("userFilterData", JSON.stringify(newObject));
    });
  }
};

export default UpdateJson;
