import {
  FLYERS_PAGE_RENDER,
  FLYERS_DETAIL_PAGE,
  FLYERS_DETAIL_GRID_VIEW,
  UPDATE_FLYERS_CITY,
  UPDATE_FLYERS_RETAILER,
  UPDATE_FLYERS_START_DATE,
  UPDATE_FLYERS_END_DATE,
  UPDATE_FLYERS_PAGESIZE,
  UPDATE_FLYERS_SORTING,
  UPDATE_FLYER_PAGE_NUMBER,
  UPDATE_FLYERS_COUNTRY,
  FLYER_CLEAR_SEARCH,
} from "../actionTypes";
import moment from "moment";

const getPreviousDate = (date = new Date()) => {
  const previousDate = new Date(date.getTime());
  previousDate.setDate(date.getDate() - 6);
  return previousDate;
};


const userData = JSON.parse(localStorage.getItem("userData"));
const fdate = localStorage.getItem("fromDate");
const tdate = localStorage.getItem("toDate");
const initialState = {
  flyersPageRender: false,
  flyerDetailPage: false,
  flyerDetailGridView: false,

  // FORM STATE VARIABLES
  sorting: "N",
  countryId: userData ? String(userData.defaultCountryId) : "",
  cities: "",
  retailers: "",
  startDate: localStorage.getItem("fromDate"),
  //startDate: "01/08/2022",
  endDate: localStorage.getItem("toDate"),
  //endDate: "30/09/2022",
  flyerSeg: "",
  userId: userData ? userData.userId : "",
  packProdID: "1",
  pageSize: 20,
  pageno: 1,
};




const flyersPageRenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLYERS_PAGE_RENDER:
      return {
        ...state,
        flyersPageRender: action.payload,
      };

    case FLYERS_DETAIL_PAGE:
      return {
        ...state,
        flyerDetailPage: action.payload,
      };
    case FLYERS_DETAIL_GRID_VIEW:
      return {
        ...state,
        flyerDetailGridView: action.payload,
      };

    // *****************************************************
    case UPDATE_FLYERS_COUNTRY:
       return {
         ...state,
         countryId: action.payload,
       };

    case UPDATE_FLYERS_CITY:
      return {
        ...state,
        cities: action.payload,
      };

    case UPDATE_FLYERS_RETAILER:
      return {
        ...state,
        retailers: action.payload,
      };

    case UPDATE_FLYERS_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };

    case UPDATE_FLYERS_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };

    case UPDATE_FLYERS_PAGESIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    case UPDATE_FLYERS_SORTING:
      return {
        ...state,
        sorting: action.payload,
      };

    case UPDATE_FLYER_PAGE_NUMBER:
      return {
        ...state,
        pageno: action.payload,
      };
    case FLYER_CLEAR_SEARCH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default flyersPageRenderReducer;
