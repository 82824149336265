import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
    SAVE_SEARCH_REQUEST,
    SAVE_SEARCH_SUCCESS,
    SAVE_SEARCH_FAILURE,
} from "../actionTypes";


const ApiList = new ApiCalls();
export const saveSearchRequest=()=>{
  return {
    type: SAVE_SEARCH_REQUEST,
    
  };
}

export const saveSearchSuccess = (String) => {
  return {
    type: SAVE_SEARCH_SUCCESS,
    payload: String,
  };
};

export const saveSearchFailure = (error) => {
  return {
    type: SAVE_SEARCH_FAILURE,
    payload: error,
  };
};


export const postSaveSearchData = () => {
  return (dispatch) => {
  dispatch(saveSearchRequest());
    ApiList.saveSearchByUser()
      .then((response) => {
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
        localStorage.setItem("savedflag", "{}");
         localStorage.setItem("name_en",'');
        const saveSearchData = response.data;
        dispatch(saveSearchSuccess(saveSearchData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(saveSearchFailure(error.message));
      });
  };
};
