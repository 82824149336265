import React, { Component } from "react";
import { connect } from "react-redux";
import {
  removeAllOfferFromCompareList,
  removeItemFromCompareOffersList,
} from "../../store/actions/compareOfferAction";
import { Link } from "react-router-dom";
import ApiCalls from "../ApiList/apiCalls";
import compareCloseIcon from "../../images/compareCloseIcon.svg";
import UpdateJson from "../CommonFiles/UpdateJson";

const ApiList = new ApiCalls();
class CompareBottomLine extends Component {
  constructor(props) {
    super(props);
  }
  handleClick() {
    window.location.href = "/profile";
  }
  handleClearClick = () => {
    UpdateJson([{ key: "isComapre", value: false }]);
    this.props.removeAllOfferFromCompareList();
  };
  render() {
    const { compareOfferData, savedOfferDataSorting } = this.props;
    return (
      compareOfferData[0] && (
        <section className="compare-bottom-line-section">
          <div className="compare-image-icons">
            {compareOfferData[0] && (
              <>
                <img
                  src={`${ApiList.getImageUrl() + compareOfferData[0].image}`}
                  height="100%"
                  width="100%"
                />
                <img
                  className="compare-image-icons-close-button"
                  src={compareCloseIcon}
                  onClick={() => {
                    this.props.removeItemFromCompareOffersList(
                      compareOfferData[0].id
                    );
                  }}
                />
              </>
            )}
          </div>

          <div className="compare-image-icons">
            {compareOfferData[1] && (
              <>
                <img
                  src={`${ApiList.getImageUrl() + compareOfferData[1].image}`}
                  height="100%"
                  width="100%"
                />
                <img
                  src={compareCloseIcon}
                  className="compare-image-icons-close-button"
                  onClick={() => {
                    this.props.removeItemFromCompareOffersList(
                      compareOfferData[1].id
                    );
                  }}
                />
              </>
            )}
          </div>

          <div className="compare-image-icons">
            {compareOfferData[2] && (
              <>
                <img
                  src={`${ApiList.getImageUrl() + compareOfferData[2].image}`}
                  height="100%"
                  width="100%"
                />
                <img
                  src={compareCloseIcon}
                  className="compare-image-icons-close-button"
                  onClick={() => {
                    this.props.removeItemFromCompareOffersList(
                      compareOfferData[2].id
                    );
                  }}
                />
              </>
            )}
          </div>

          <div className="compare-image-icons">
            {compareOfferData[3] && (
              <>
                <img
                  src={`${ApiList.getImageUrl() + compareOfferData[3].image}`}
                  height="100%"
                  width="100%"
                />
                <img
                  className="compare-image-icons-close-button"
                  onClick={() => {
                    this.props.removeItemFromCompareOffersList(
                      compareOfferData[3].id
                    );
                  }}
                  src={compareCloseIcon}
                />
              </>
            )}
          </div>

          <p className="compare-bottom-line-text">
            select upto 4 offers
            <br /> to compare
          </p>
          {compareOfferData[0] ? (
            <Link
              to="/compare"
              style={{ textDecoration: "none", margin: "auto 0" }}
            >
              <button
                //onClick={()=>{window.location.href = "/compare";}}
                className="compare-bottom-line-compare-button1"
              >
                <span>Compare</span>
              </button>
            </Link>
          ) : (
            <button className="compare-bottom-line-compare-button">
              <span>Compare</span>
            </button>
          )}
          {compareOfferData[0] ? (
            <button
              className="compare-bottom-line-clear-button"
              onClick={this.handleClearClick}
            >
              <span>Clear All</span>
            </button>
          ) : (
            <button className="compare-bottom-line-clear-button1">
              <span>Clear All</span>
            </button>
          )}
        </section>
      )
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    compareOfferData: state.compareOffer.compareOfferList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeAllOfferFromCompareList: () =>
      dispatch(removeAllOfferFromCompareList()),
    removeItemFromCompareOffersList: (offer) =>
      dispatch(removeItemFromCompareOffersList(offer)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompareBottomLine);
