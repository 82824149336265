import React, { Component } from "react";
import { connect } from "react-redux";
import FlyersPageSize from "./FlyersPageSize";
import FlyersSorting from "./FlyersSorting";
import exportIcon from "../../images/exportIcon.svg";
import { withSnackbar } from "notistack";
import ApiCalls from "../ApiList/apiCalls";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

const ApiList = new ApiCalls();
class FlyersAdditionalFilters extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isExportShow: false,
    };
  }
  async handleExportClick() {
    this.props.enqueueSnackbar("Export in progress", {
      variant: "success",
    });

    try {
      const response = await ApiList.getFlyerDataExport();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "flyers.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the temporary anchor element
      if (response) {
        this.props.enqueueSnackbar("Export completed successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      this.props.enqueueSnackbar("Error in Export Data!", {
        variant: "error",
      });
    }
  }
  componentDidMount() {
    document.removeEventListener("flyerBrandOnChange", this.flyerBrandOnChange);
    document.addEventListener(
      "flyerBrandOnChange",
      this.flyerBrandOnChange.bind(this)
    );
  }
  flyerBrandOnChange() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (userFilterData) {
      this.setState({
        isExportShow: userFilterData.flyerbrandIds.length ? true : false,
      });
    }
  }
  render() {
    const { flyersData } = this.props;
    return (
      <section
        className="flyers-additional-filters-main-section"
        id="flyerfilter"
      >
        <div
          onClick={() => {
            this.handleExportClick();
            //  const message = "No Offers Selected.";
            //  this.props.enqueueSnackbar(message, {
            //    variant: "error",
            //  });
          }}
          className="export-flyer-button"
          style={{
            cursor: "pointer",
            maxHeight: "30px",
            marginRight: "10px",
            maxWidth: "92px",
            visibility:
              prmissionData?.isFlyerAudit && this.state.isExportShow
                ? "visible"
                : "hidden",
          }}
          variant="contained"
        >
          <img src={exportIcon} alt="" />
          Export
        </div>
        <FlyersSorting
          isDisable={flyersData.flyersList && flyersData.flyersList.length}
        />
        <div className="flyers-page-size-div">
          <FlyersPageSize
            isDisable={flyersData.flyersList && flyersData.flyersList.length}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flyersData: state.flyersData.flyersPageData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FlyersAdditionalFilters));
