import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import CompareDetailTable from "./CompareTable";
import { connect } from "react-redux";
import exportIcon from "../../images/exportIcon.svg";
import ApiCalls from "../ApiList/apiCalls";
import "./Compare.css";
import { updateNavView } from "../../store/actions/filterFormDataAction";
import { Grid } from "@mui/material";
import { updatePage } from "../../store/actions/userManagerAction";
import UpdateJson from "../CommonFiles/UpdateJson";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

const ApiList = new ApiCalls();
class ComparePage extends Component {
  static displayName = ComparePage.name;
  componentDidMount() {
    this.props.updatePage("compare");
    UpdateJson([{ key: "isComapre", value: true }]);
  }
  componentWillUnmount() {
    this.props.updatePage("");
  }

  handleExport = () => {
    const selectedCompareOfferIds = this.props.compareOfferData
      .map((OfferItem) => OfferItem.id)
      .toString();
    ApiList.getSelectedDataExport({
      offerId: selectedCompareOfferIds,
    }).then((response) => {
      this.setState({ loading: false });
      const filename = response.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      // const newToken = response.headers.token;
      // const userData = JSON.parse(localStorage.getItem("userData"));
      // const newUserData = { ...userData, token: newToken };
      // localStorage.setItem("userData", JSON.stringify(newUserData));
    });
  };

  render() {
    return (
      <Grid container spacing={1} className={"px-4"}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} align="center">
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Compare Offers
          </Typography>
        </Grid>
        {prmissionData && prmissionData.isExport ? (
          <Grid item xs={4} align="right">
            <div
              onClick={this.handleExport}
              sx={{ maxHeight: "30px" }}
              className="export-button"
              style={{
                cursor: "pointer",
              }}
              variant="contained"
            >
              <img src={exportIcon} alt="" />
              Export
            </div>
          </Grid>
        ) : null}
        <br />
        <br />
        <Grid item xs={12}>
          <CompareDetailTable />
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    compareOfferData: state.compareOffer.compareOfferList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePage: (page) => dispatch(updatePage(page)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparePage);
