import { GET_NOTIFICATION_HOME } from "../actionTypes";

  const initialState = {
    loading: true,
    notificationHomemsg:"",
  };

  const getNotificationHomeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATION_HOME:
        return {
            notificationHomemsg: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default getNotificationHomeReducer;