import {
  GET_FLYER_DETAILS_REQUEST,
  GET_FLYER_DETAILS_SUCCESS,
  GET_FLYER_DETAILS_FAILURE,
  UPDATE_FLYER_DETAILS_CURRENT_PAGE,
} from "../actionTypes";

const initialState = {
  flyerDetails: {},
  loading: false,
  error: "",
  currentPageNumber:0
};

const getFlyerDetailsObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLYER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_FLYER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        flyerDetails: action.payload,
        error: "",
      };

    case GET_FLYER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        flyerDetails: {},
        error: action.payload,
      };

    case UPDATE_FLYER_DETAILS_CURRENT_PAGE:
      return {
        ...state,
        currentPageNumber: action.payload,
      };

    default:
      return state;
  }
};

export default getFlyerDetailsObjectReducer;
