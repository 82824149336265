import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  POST_SAVED_OFFER_OBJECT_REQUEST,
  POST_SAVED_OFFER_OBJECT_SUCCESS,
  POST_SAVED_OFFER_OBJECT_FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const postSavedOfferRequest = () => {
  return {
    type: POST_SAVED_OFFER_OBJECT_REQUEST,

  };
};

export const postSavedOfferSuccess = (sortType) => {
  return {
    type: POST_SAVED_OFFER_OBJECT_SUCCESS,
    payload: sortType,
  };
};

export const postSavedOfferFailure = (error) => {
  return {
    type: POST_SAVED_OFFER_OBJECT_FAILURE,
    payload: error,
  };
};

export const postSavedOfferObject = ({ sortType, sortOrder }, loading=false) => { 
  return (dispatch) => {
    if(loading){
      dispatch(postSavedOfferRequest());
    }
    ApiList.postSavedOfferObjectData(sortType, sortOrder)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const savedOfferObjectDataPost = response.data;
        dispatch(postSavedOfferSuccess(savedOfferObjectDataPost));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(postSavedOfferFailure(error.message));
      });
  };
};
