import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { exportSavedOfferObject } from "../../store/actions/exportSavedOfferAction";
import { withSnackbar } from "notistack";
import ApiCalls from "../ApiList/apiCalls";
import Loader from "../CommonFiles/Loader";
import exportButtonIcon from "../../images/exportButtonIcon.svg";
import exportIcon from "../../images/exportIcon.svg";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";


const ApiList = new ApiCalls();

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      value: 0,
      isopen: false,
    };
  }

  handleClick = () => {
    if (this.state.value == 0) {
      this.setState({ loading: true });
      const selectedOfferIds = this.props.exportOfferItems
        .map((OfferItem) => OfferItem.id)
        .toString();
      ApiList.getSelectedDataExport({
        offerId: selectedOfferIds,
      }).then((response) => {
        this.setState({ loading: false });
        const filename = response.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        // const newToken = response.headers.token;
        // const userData = JSON.parse(localStorage.getItem("userData"));
        // const newUserData = { ...userData, token: newToken };
        // localStorage.setItem("userData", JSON.stringify(newUserData));
      });
    } else if (this.state.value == 1) {
      this.setState({ loading: true });
      const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
      ApiList.getDataExport(userFilterData).then((response) => {
        this.setState({ loading: false });
        const filename = response.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        //  const newToken = response.headers.token;
        //  const userData = JSON.parse(localStorage.getItem("userData"));
        //  const newUserData = { ...userData, token: newToken };
        //  localStorage.setItem("userData", JSON.stringify(newUserData));
      });
    } else {
      this.setState({ isopen: true });
    }
    this.setState({ open: false });
  };
  handleChange = (ev) => {
    this.setState({ value: ev.target.value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
    this.setState({ value: 0 });
  };
  handleMessageClose = () => {
    this.setState({ isopen: false });
    this.setState({ value: 0 });
  };
  
  render() {
    const { formData, exportSavedOfferObject, exportOfferItems } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Loader open={loading} />

        <button
          style={{
            position: "relative",
            maxWidth: "92px",
            width: "100%",
            height: "30px",
            cursor: exportOfferItems.length ? "pointer" : "inherit",
            color: exportOfferItems.length ? "#208EDC" : "#C4C4C4",
            backgroundColor: "#FFF",
            textTransform: "none",
            border: exportOfferItems.length
              ? "1px solid #208EDC"
              : "1px solid #C4C4C4",
            borderRadius: "5px",
            whiteSpace: "nowrap",
            padding: "0 9px",
            display: "flex",
            flex: "1 1",
            justifyContent: "center",
          }}
          disabled={!exportOfferItems.length}
          onClick={this.handleClickOpen}
        >
          <img
            className="export-share-icon"
            src={exportOfferItems.length ? exportIcon : exportButtonIcon}
            alt=""
          />
          <Tooltip
            title={
              !exportOfferItems.length
                ? "Please select an offer"
                : "Click to Export"
            }
            TransitionProps={{ timeout: 200 }}
          >
            <span
              className="ExportShare-Text"
              style={{
                color: exportOfferItems.length ? "#208EDC" : "#C4C4C4",
              }}
            >
              Export
            </span>
          </Tooltip>
        </button>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth="lg"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "8px 8px 0px 0px",
            },
          }}
        >
          <div
            style={{
              height: "30px",
              width: "320px",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
              background:
                "linear-gradient(90.49deg, #A7E8A5 -3.83%, #73C0CE 102.84%)",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            Export Data :
            <span>
              <CloseIcon
                onClick={this.handleClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <DialogContent>
            <>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSelect-select": {
                    height: "30px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#757474",
                  },
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label={`Selected (${exportOfferItems.length})`}
                  />

                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="All (First 1000 without image)"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="All (First 1000 with image)"
                  />
                </RadioGroup>
              </FormControl>
              <div style={{ paddingLeft: "30%", marginTop: "10px" }}>
                <button
                  style={{
                    maxWidth: "92px",
                    width: "100%",
                    height: "30px",
                    cursor: "pointer",
                    color: "#208EDC",
                    backgroundColor: "#FFF",
                    textTransform: "none",
                    border: "1px solid #208EDC",
                    justifyContent: "center",
                    borderRadius: "5px",
                    whiteSpace: "nowrap",
                    padding: "0 9px",
                    display: "flex",
                    flex: "1 1",
                  }}
                  onClick={this.handleClick}
                >
                  <img className="export-share-icon" src={exportIcon} />
                  <span
                    className="ExportShare-Text"
                    style={{
                      color: "#208EDC",
                    }}
                  >
                    Export
                  </span>
                </button>
              </div>
            </>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.isopen}
          onClose={this.handleMessageClose}
          maxWidth="lg"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "8px 8px 0px 0px",
            },
          }}
        >
          <div
            style={{
              height: "30px",
              width: "100%",
              padding: "5px",
              display: "flex",
              justifyContent: "space-between",
              background:
                "linear-gradient(90.49deg, #A7E8A5 -3.83%, #73C0CE 102.84%)",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            Message :
            <span>
              <CloseIcon
                onClick={this.handleMessageClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <DialogContent>
            <>
            You can contact us directly via email at team@offerBank.com
            </>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
    exportOfferItems: state.exportSavedOffer.exportOfferList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportSavedOfferObject: (postObj) =>
      dispatch(exportSavedOfferObject(postObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Share));
