import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_TNC_REQUEST,
  GET_TNC_SUCCESS,
  GET_TNC_FAILURE,
  GET_PRODUCT_DATA,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getTnCRequest = () => {
  return {
    type: GET_TNC_REQUEST,
  };
};

export const getTnCSuccess = (tAndC) => {
  return {
    type: GET_TNC_SUCCESS,
    payload: tAndC,
  };
};

export const getTnCFailure = (error) => {
  return {
    type: GET_TNC_FAILURE,
    payload: error,
  };
};
export const getSelectedProductData = (data) => {
  return {
    type: GET_PRODUCT_DATA,
    payload: data,
  };
};
export const getTnCObject = () => {
  return (dispatch) => {
    dispatch(getTnCRequest());
    ApiList.getTermsandCondition()
      .then((response) => {
        const TnCData = response.data;
        dispatch(getTnCSuccess(TnCData));
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getTnCFailure(error.message));
      });
  };
};
