import {
  UPDATE_TO_DATE,
  UPDATE_FROM_DATE,
  UPDATE_COUNTRY,
  UPDATE_CITY,
  UPDATE_RETAILER,
  UPDATE_CATEGORY,
  UPDATE_SUB_CATEGORY,
  UPDATE_ITEM,
  UPDATE_BRAND,
  UPDATE_PRODUCT,
  UPDATE_PACKSIZE,
  UPDATE_PROMOTYPE,
  UPDATE_OFFER_PRICE,
  UPDATE_DISCOUNT_RANGE,
  UPDATE_PREFERENCE,
  UPDATE_SORTING,
  UPDATE_PAGESIZE,
  UPDATE_PAGE_NUMBER,
  UPDATE_ANLYTICS_SEARCHTEXT,
  UPDATE_SUGGESTION_TEXT,
  CLEAR_SEARCH,
  UPDATE_CALENDAR_VIEW,
  UPDATE_ATTRIBUTE,
  UPDATE_SEARCHNAME,
  UPDATE_DESCRIPTION,
  UPDATE_IS_DUPLICATE,
  UPDATE_SAVED_SEARCH_ID,
  UPDATE_NAV_VIEW,
  RESET_FROM_AND_TO_DATE,
  UPDATE_PRO_VIEW,
  UPDATE_USER_JSON,
} from "../actionTypes";

const userData = JSON.parse(localStorage.getItem("userData"));
const getPreviousDate = (date = new Date()) => {
  const previousDate = new Date(date.getTime());
  previousDate.setDate(date.getDate() - 6);
  return previousDate;
};
const initialState = {
  isCategorySelected: false,
  isCountrySelected: false,
  isSubCategorySelected: false,
  refineFiltersRenderCondition: 1,
  calendarView: false, // false means NormalView and true means WeekNumber

  userid: userData ? String(userData.userId) : "",
  saveSearchId: "",
  countryid: userData
    ? Number(userData.selectedCountryId) > 0
      ? String(userData.selectedCountryId)
      : String(userData.defaultCountryId)
    : "",
  //countryid: userData ? String(userData.defaultCountryId) : "",
  // countryid:"2",
  regionid: "",
  retailerid: "",
  categoryid: "",
  subcategoryid: "",
  sorting: "N",
  todate: localStorage.getItem("toDate"),
  fromDate: localStorage.getItem("fromDate"),
  //todate: "30/09/2022",
  //fromDate: "01/08/2022",
  cities: "",
  items: "",
  brands: "",
  promoMechs: "",
  discounts: "",
  anlyitcs_searchText: "",
  anlyitcs_suggestionType: "",
  anlyitcs_suggestionId: "",
  anlyitcs_subCategoryId: "",
  suggestion_Text: "",
  packsize: "",
  promomechanic: "",
  promopricemax: 0,
  promopricemin: 0,
  discountmax: 0,
  discountmin: 0,
  product: "",
  searchName: "string",
  description: "",
  ispackSize: false,
  SearchText: "",
  PackProdID: "1",
  callingscreen: "",
  type: "",
  LinkId: "",
  pageno: 1,
  pagesize: 20,
  language: "",
  attribute: "",
  isShowDuplicate: false,
  isPageChange: "",
  isproViewChange:0,
  offerId: "",
};

const updateFilterFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FROM_DATE:
      return {
        ...state,
        fromDate: localStorage.getItem("fromDate"),
      };

    case UPDATE_TO_DATE:
      return {
        ...state,
        todate: localStorage.getItem("toDate"),
      };

    case RESET_FROM_AND_TO_DATE:
      return {
        ...state,
        todate: localStorage.getItem("toDate"),
        fromDate: localStorage.getItem("fromDate"),
      };

    case UPDATE_COUNTRY:
      const newState = {
        ...initialState,
        todate: localStorage.getItem("toDate"),
        fromDate: localStorage.getItem("fromDate"),
        isCountrySelected: true,
        countryid: action.payload,
      };
      return newState;
    case UPDATE_CITY:
      return {
        ...state,
        cities: action.payload,
      };
    case UPDATE_RETAILER:
      return {
        ...state,
        retailerid: action.payload,
      };

    case UPDATE_CATEGORY:
      return {
        ...state,
        categoryid: action.payload,
        isCategorySelected: true,
      };

    case UPDATE_SUB_CATEGORY:
      return {
        ...state,
        subcategoryid: action.payload,
        isSubCategorySelected: true,
      };
    case UPDATE_ITEM:
      return {
        ...state,
        items: action.payload,
      };

    case UPDATE_BRAND:
      return {
        ...state,
        brands: action.payload,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case UPDATE_PACKSIZE:
      return {
        ...state,
        packsize: action.payload,
      };

    case UPDATE_PROMOTYPE:
      return {
        ...state,
        promomechanic: action.payload,
      };

    case UPDATE_OFFER_PRICE:
      return {
        ...state,
        promopricemin: action.payload[0].toString(),
        promopricemax: action.payload[1].toString(),
      };
    case UPDATE_DISCOUNT_RANGE:
      return {
        ...state,
        discountmin: action.payload[0].toString(),
        discountmax: action.payload[1].toString(),
      };
    case UPDATE_PREFERENCE:
      return {
        ...state,
        refineFiltersRenderCondition: action.payload,
      };
    case UPDATE_SORTING:
      return {
        ...state,
        sorting: action.payload,
      };

    case UPDATE_PAGESIZE:
      return {
        ...state,
        pagesize: action.payload,
      };

    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        pageno: action.payload,
      };
    case UPDATE_ANLYTICS_SEARCHTEXT:
      return {
        ...state,
        anlyitcs_searchText: action.payload,
      };

    case UPDATE_SUGGESTION_TEXT:
      return {
        ...state,
        suggestion_Text: action.payload,
      };

    case CLEAR_SEARCH:
      const userData = JSON.parse(localStorage.getItem("userData"));
      return {
        ...initialState,
        fromDate: localStorage.getItem("fromDate"),
        todate: localStorage.getItem("toDate"),
        countryid: userData
          ? Number(userData.selectedCountryId) > 0
            ? String(userData.selectedCountryId)
            : String(userData.defaultCountryId)
          : null,
        isCountrySelected: true,
      };

    case UPDATE_CALENDAR_VIEW:
      return {
        ...state,
        calendarView: action.payload,
      };
    case UPDATE_ATTRIBUTE:
      return {
        ...state,
        attribute: action.payload,
      };
    case UPDATE_SEARCHNAME:
      return {
        ...state,
        searchName: action.payload,
      };
    case UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case UPDATE_IS_DUPLICATE:
      return {
        ...state,
        isShowDuplicate: action.payload,
      };
    case UPDATE_SAVED_SEARCH_ID:
      return {
        ...state,
        saveSearchId: action.payload,
      };
    case UPDATE_NAV_VIEW:
      return {
        ...state,
        isPageChange: action.payload,
      };
    case UPDATE_PRO_VIEW:
      return {
        ...state,
        isproViewChange: action.payload,
      };
    case UPDATE_USER_JSON:
      return {
        ...state,
        //state: action.payload,
      };
    default:
      return state;
  }
};

export default updateFilterFormDataReducer;
