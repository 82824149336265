import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { OutlinedInput } from "@mui/material";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";
import Tooltip from "@mui/material/Tooltip";
import UpdateJson from "../CommonFiles/UpdateJson";

class FlyersSorting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorting: "N",
    };
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ sorting: value });
    UpdateJson([{ key: "sorting", value: value }]);
    this.props.getFlyersPageDataObject(true);
  };

  render() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const { isDisable } = this.props;
    return (
      <Box sx={{ minWidth: 148 }}>
        <Tooltip
          title="Sort Flyers"
          placement="top-end"
          TransitionProps={{ timeout: 200 }}
        >
          <FormControl
            fullWidth
            disabled={!isDisable}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-select": {
                height: "30px",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                color: "#757474",
              },
            }}
          >
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userFilterData.sorting}
              onChange={this.handleChange}
              input={<OutlinedInput label="" />}
            >
              <MenuItem value="N">Flyers New to Old</MenuItem>
              <MenuItem value="O">Flyers Old to New</MenuItem>
            </Select>
          </FormControl>
        </Tooltip>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flyersState: state.flyersPage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFlyersPageDataObject: (flag) => dispatch(getFlyersPageDataObject(flag)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FlyersSorting);
