import {
  GET_WEEK_NUMBER_REQUEST,
  GET_WEEK_NUMBER_SUCCESS,
  GET_WEEK_NUMBER_FAILURE,
  SELECTED_WEEK_NUMBERS,
  REMOVE_SELECTED_WEEK_NUMBERS,
  MIN_TO_MAX_SELECTED_WEEK_NUMBERS,
  REMOVE_ALL_SELECTED_WEEK_NUMBER,
} from "../actionTypes";

const initialState = {
  loading: false,
  weekNumbers: [],
  error: "",
  selectedWeekNumbers: [], 
};

const getWeekNumberObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEEK_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_WEEK_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        weekNumbers: action.payload.reverse(),
        error: "",
      };

    case GET_WEEK_NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        weekNumbers: [],
        error: action.payload,
      };

    case SELECTED_WEEK_NUMBERS:
      const selectedValues = new Set([
        ...state.selectedWeekNumbers,
        action.payload,
      ]);
      return {
        ...state,
        selectedWeekNumbers: [...selectedValues],
      };

    case REMOVE_SELECTED_WEEK_NUMBERS:
      const index = [...state.selectedWeekNumbers].indexOf(action.payload);
      let selectedNumbers = [...state.selectedWeekNumbers];
      selectedNumbers.splice(index, 1);
      return {
        ...state,
        selectedWeekNumbers: selectedNumbers,
      };

    case MIN_TO_MAX_SELECTED_WEEK_NUMBERS:
      return {
        ...state,
        selectedWeekNumbers: action.payload,
      };

      case REMOVE_ALL_SELECTED_WEEK_NUMBER:
        return {
          ...state,
          selectedWeekNumbers:action.payload
        };

    default:
      return state;
  }
};

export default getWeekNumberObjectReducer;
