import React, { Component } from "react";
import UpdateJson from "../CommonFiles/UpdateJson";
import { connect } from "react-redux";
import { CheckPicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ApiCalls from "../ApiList/apiCalls";
import {
  updateSavedFilterCount,
  updateSavedOfferCount,
} from "../../store/actions/userManagerAction";
import { toggleInitialize } from "../../store/actions/offerImageActions";
import { getFlyersPageDataObject } from "../../store/actions/flyersDataAction";


const ApiList = new ApiCalls();

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const footerButtonStyle = {
  float: "right",
  marginRight: 10,
  marginTop: 2,
};

class BrandFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      selectedBrandsIds: [],
    };
  }
  onStateChange(ev) {
    this.setState({ brands: ev.detail.brandList });
  }

  componentDidMount() {
    document.removeEventListener("brandsList", this.onStateChange);
    document.addEventListener("brandsList", this.onStateChange.bind(this));

    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (userFilterData) {
      if (userFilterData.flyerbrandIds) {
         const customEvent = new CustomEvent("flyerBrandOnChange", {});
         document.dispatchEvent(customEvent);
        let selectedFlyerbrandIds = [];
        let id = userFilterData.flyerbrandIds;
        var stringCityId = id.split(",");
        selectedFlyerbrandIds = stringCityId.map((item) => Number(item));
        this.setState({ selectedBrandsIds: selectedFlyerbrandIds });
           
      }
    }
  }

  onBrnadChange = (value) => {
    this.setState({ selectedBrandsIds: value });
    UpdateJson([{ key: "flyerbrandIds", value: value.toString() }]);
    this.props.getFlyersPageDataObject();
    const customEvent = new CustomEvent("flyerBrandOnChange", {
    });
    document.dispatchEvent(customEvent);
  };

  render() {
    const { isPageChange,isDisable } = this.props;
    return (
      <>
        <section
          style={{
            display: "flex",
            //flex:  this.props && this.props.flyerFlag && isDisable ?"1 1":"",
            visibility:
              this.props && this.props.flyerFlag && isDisable
                ? "visible"
                : "hidden",
               // maxWidth: this.props && this.props.flyerFlag && isDisable ? "0px":""
          }}
        >
          <CheckPicker
            style={{
              width: this.props && this.props.flyerFlag && isDisable ? 160 : 0,
              visibility:
                this.props && this.props.flyerFlag && isDisable
                  ? "visible"
                  : "hidden",
            }}
            data={this.state.brands}
            sticky
            loading={this.state.brands.length>0?false:true}
            value={this.state.selectedBrandsIds}
            onChange={(value) => {
              this.onBrnadChange(value);
            }}
            placeholder="Select Brands"
            // renderExtraFooter={() => (
            //   <div style={footerStyles}>
            //     <Checkbox
            //       indeterminate={
            //         this.state.selectedBrandsIds.length > 0 &&
            //         this.state.selectedBrandsIds.length < ""
            //       }
            //       checked={this.state.selectedBrandsIds.length === ""}
            //       // onChange={this.handleRetailerCheckAll}
            //     >
            //       Check all
            //     </Checkbox>
            //   </div>
            // )}
            labelKey="Name_en"
            valueKey="Id"
            limit={0}
            virtualized
          />
        </section>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    flyersData: state.flyersData.flyersPageData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
    updateSavedFilterCount: (newValue) =>
      dispatch(updateSavedFilterCount(newValue)),
    toggleInitialize: (value) => dispatch(toggleInitialize(value)),
    getFlyersPageDataObject: () => dispatch(getFlyersPageDataObject(true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandFilter);
