import React, { Component } from "react";
import UpdateJson from "../CommonFiles/UpdateJson";
import { connect } from "react-redux";
import { CheckPicker, Checkbox, SelectPicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ApiCalls from "../ApiList/apiCalls";
import { updateSavedFilterCount, updateSavedOfferCount } from "../../store/actions/userManagerAction";
import { toggleInitialize } from "../../store/actions/offerImageActions";



const listData = JSON.parse(localStorage.getItem("listData"));
const allCityValue = listData ? listData.cityList.map((item) =>Number(item.cityId)):[];
const allRetailerValue = listData ? listData.retailerList.map((item) => Number(item.retailerId)):[];
const ApiList = new ApiCalls();

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const footerButtonStyle = {
  float: "right",
  marginRight: 10,
  marginTop: 2,
};

class CountryCityRetailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: [],
      selectedCountryIds: 0,
      city: [],
      selectedCity: [],
      selectedCityIds: [],
      filterCity: [],
      retailer: [],
      selectedRetailerIds: [],
      filterRetailer: [],
    };
  }
  componentDidMount() {
    this.setState({ country: listData.countryList });
    this.setState({ city: listData.cityList });
    this.setState({ filterCity: listData.cityList });
    this.setState({ retailer: listData.retailerList });
    this.setState({ filterRetailer: listData.retailerList });

    const userData = JSON.parse(localStorage.getItem("userData"));
    const id =
      userData && userData.selectedCountryId > 0
        ? userData.selectedCountryId
        : userData.countryId;
    const selected = listData.countryList.find(
      (item) => item.countryId === Number(id)
    );
    this.onCountryChange(Number(id),"oneTime");

    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if(userFilterData){
       if (userFilterData.cityIds) {
        let selectedCities = [];
         let id = userFilterData.cityIds;
         var stringCityId = id.split(",");
         selectedCities = stringCityId.map((item) => Number(item));
         this.setState({ selectedCityIds: selectedCities });
       }
       if (userFilterData.retailerIds) {
         let selectedRetailers = [];
         let id = userFilterData.retailerIds;
         var stringRetailerId = id.split(",");
         selectedRetailers = stringRetailerId.map((item) => Number(item));
         this.setState({ selectedRetailerIds: selectedRetailers });
       }
    }
  }

  onCountryChange = (value,index) => {
    this.setState({ selectedCountryIds: value });
     !index &&
       UpdateJson([
         { key: "countryId", value: value.toString() },
         { key: "cityIds", value: "" },
         { key: "retailerIds", value: "" },
         {
           key: "promoPriceMin",
           value: 0.0,
         },
         {
           key: "promoPriceMax",
           value: 0.0,
         },
         {
           key: "discountMin",
           value: 0.0,
         },
         {
           key: "discountMax",
           value: 0.0,
         },
       ]);
     const newCountryId = value;
     const userData = JSON.parse(localStorage.getItem("userData"));
     const newUserData = { ...userData, selectedCountryId: newCountryId };
     localStorage.setItem("userData", JSON.stringify(newUserData));

    let arrCity = listData.cityList;
    const arrFilterCity = arrCity.filter((city) => city.countryId == value);
    this.setState({ filterCity: arrFilterCity });
    let arrRetailer = listData.retailerList;
    const arrFilterRetailer = arrRetailer.filter(
      (item) => item.countryId == value
    );
    this.setState({ filterRetailer: arrFilterRetailer });
    this.setState({ selectedCity: [], selectedRetailer: [] });
    ApiList.getSavedOfferCount()
      .then((response) => {
        const savedOfferCount = response.data.offerCount;
        this.props.updateSavedOfferCount(savedOfferCount);
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
      })
      .catch((error) => {});
    ApiList.getSavedFilterCount()
      .then((response) => {
        this.props.updateSavedFilterCount(response.data.count);
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
      })
      .catch((error) => {});
       this.props.toggleInitialize(false);
       
    // const customEvent = new CustomEvent("countryChange");
    // document.dispatchEvent(customEvent);
  };

  onCityChange = (value) => {
    this.setState({ selectedCityIds: value });
    UpdateJson([{ key: "cityIds", value: value.toString() }]);
  };

  onRetailerChange = (value) => {
    this.setState({ selectedRetailerIds: value });
    UpdateJson([{ key: "retailerIds", value: value.toString() }]);
  };

  handleCityCheckAll = (value, checked) => {
    this.setState({ selectedCityIds: checked ? allCityValue : [] });
     UpdateJson([{ key: "cityIds", value: "" }]);
  };

  handleRetailerCheckAll = (value,checked) => {
    this.setState({ selectedRetailerIds: checked ? allRetailerValue : [] });
     UpdateJson([{ key: "retailerIds", value: "" }]);
  };

  render() {
    const { isPageChange } = this.props;
    return (
      <>
        <section
          style={{
            display: "flex",
            flex: "1 1",
            // visibility: isPageChange == "compare" ? "hidden":"visible"
          }}
        >
          <SelectPicker
            data={this.state.country}
            placeholder="Select Country"
            cleanable={false}
            labelKey="name"
            valueKey="countryId"
            searchable={false}
            value={this.state.selectedCountryIds}
            style={{ width: 160 }}
            onChange={(value) => {
              this.onCountryChange(value);
            }}
          />

          <CheckPicker
            style={{ width: 160 }}
            data={this.state.filterCity}
            sticky
            onChange={(value) => {
              this.onCityChange(value);
            }}
            value={this.state.selectedCityIds}
            placeholder="Select City"
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    this.state.selectedCityIds.length > 0 &&
                    this.state.selectedCityIds.length < allCityValue.length
                  }
                  checked={
                    this.state.selectedCityIds.length === allCityValue.length
                  }
                  onChange={this.handleCityCheckAll}
                >
                  Check all
                </Checkbox>
              </div>
            )}
            labelKey="name"
            valueKey="cityId"
            limit={0}
          />

          <CheckPicker
            style={{ width: 160 }}
            data={this.state.filterRetailer}
            sticky
            value={this.state.selectedRetailerIds}
            onChange={(value) => {
              this.onRetailerChange(value);
            }}
            placeholder="Select Retailer"
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    this.state.selectedRetailerIds.length > 0 &&
                    this.state.selectedRetailerIds.length <
                      allRetailerValue.length
                  }
                  checked={
                    this.state.selectedRetailerIds.length ===
                    allRetailerValue.length
                  }
                  onChange={this.handleRetailerCheckAll}
                >
                  Check all
                </Checkbox>
              </div>
            )}
            labelKey="name"
            valueKey="retailerId"
            limit={0}
          />
        </section>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    formData: state.filterForm.isPageChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
    updateSavedFilterCount: (newValue) =>
      dispatch(updateSavedFilterCount(newValue)),
    toggleInitialize: (value) => dispatch(toggleInitialize(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryCityRetailer);
