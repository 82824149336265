import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { postSaveSearchData } from "../../store/actions/saveSearchByUserAction";
import { connect } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import crossIcon from "../../images/crossIcon.png";
import { Grid } from "@mui/material";
class SaveFilterView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { savedViewData } = this.props;
    if (JSON.stringify(savedViewData) != "{}") {
      return (
        <Dialog className="ViewDialogBox" open={this.props.open}>
          <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} align="center" style={{ marginTop: "20px" }}>
              <p className="view-saved-filter-heading-para">
                {savedViewData.saveFilterOuterModel &&
                  savedViewData.saveFilterOuterModel.searchName}
              </p>
            </Grid>
            <Grid item xs={4} align="right">
              <img
                src={crossIcon}
                style={{
                  cursor: "pointer",
                  marginTop: "18px",
                  marginRight: "12px",
                  height: "14px",
                  width: "14px",
                }}
                onClick={() => this.props.handleClose(false)}
              />
            </Grid>
          </Grid>
          <DialogContent
            sx={{
              padding: "0px 24px",
            }}
          >
            <br />
            <div style={{ border: "1px solid #757575" }}>
              <Accordion sx={{ backgroundColor: "#F4F4F4" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="saved-filter-view-subheading-para">
                    Range :
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ transform: "translate(0px, -45px)" }}
                    >
                      <p>
                        <strong className="saved-filter-accordion-details-subheading">
                          Price Range :
                        </strong>
                        &nbsp;
                        <strong className="saved-filter-accordion-details-subheading">
                          From:
                        </strong>
                        &nbsp;
                        {savedViewData.searchFilterJsonModel.priceRangeFrom
                          ? savedViewData.searchFilterJsonModel.priceRangeFrom
                          : " ----"}
                        &nbsp;&nbsp;
                        <strong className="saved-filter-accordion-details-subheading">
                          To:
                        </strong>
                        &nbsp;
                        {savedViewData.searchFilterJsonModel.priceRangeTo
                          ? savedViewData.searchFilterJsonModel.priceRangeTo
                          : " ----"}
                      </p>
                      <p>
                        <strong className="saved-filter-accordion-details-subheading">
                          Discount Range :
                        </strong>
                        &nbsp;
                        <strong className="saved-filter-accordion-details-subheading">
                          From:
                        </strong>
                        &nbsp;
                        {savedViewData.searchFilterJsonModel.discountRangeFrom
                          ? savedViewData.searchFilterJsonModel
                              .discountRangeFrom
                          : " ----"}
                        &nbsp;&nbsp;
                        <strong className="saved-filter-accordion-details-subheading">
                          To:
                        </strong>
                        &nbsp;
                        {savedViewData.searchFilterJsonModel.discountRangeTo
                          ? savedViewData.searchFilterJsonModel.discountRangeTo
                          : " ----"}
                      </p>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ backgroundColor: "#F4F4F4" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  className="ViewHeader"
                >
                  <Typography className="saved-filter-view-subheading-para">
                    Geography :
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                  <Grid container>
                    <Grid item xs={3}></Grid>

                    <Grid
                      item
                      xs={8}
                      style={{ transform: "translate(0px, -45px)" }}
                    >
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Country&nbsp;:&nbsp;
                            </strong>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {
                                savedViewData.searchFilterJsonModel
                                  .countryIdsJson
                              }
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Retailer&nbsp;:&nbsp;
                            </strong>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel
                                .retailerIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .retailerIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              City&nbsp;:&nbsp;
                            </strong>
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel.citiIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .citiIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ backgroundColor: "#F4F4F4" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className="saved-filter-view-subheading-para">
                    Filter Properties :
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid
                      item
                      xs={8}
                      style={{ transform: "translate(0px, -45px)" }}
                    >
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Category&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel
                                .categoryIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .categoryIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Sub-Category&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel
                                .subCategoryIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .subCategoryIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Product&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel
                                .productIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .productIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Brand&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel.brandIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .brandIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Pack Size&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel.packSizeJson
                                ? savedViewData.searchFilterJsonModel
                                    .packSizeJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Promo Mechanic&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel
                                .promoMachenicJson
                                ? savedViewData.searchFilterJsonModel
                                    .promoMachenicJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                      <p>
                        <Grid container>
                          <Grid item xs={3}>
                            <strong className="saved-filter-accordion-details-subheading">
                              Attribute&nbsp;:&nbsp;
                            </strong>
                          </Grid>

                          <Grid
                            item
                            xs={9}
                            className="saved-filter-accordion-details-value-grid"
                          >
                            <span className="saved-filter-accordion-details-value">
                              {savedViewData.searchFilterJsonModel
                                .attributeIdsJson
                                ? savedViewData.searchFilterJsonModel
                                    .attributeIdsJson
                                : "All Selected"}
                            </span>
                          </Grid>
                        </Grid>
                      </p>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </DialogContent>
        </Dialog>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loadingByDataPermission: state.getDataByPermission.loading,
    formData: state.filterForm,
    saveSearchData: state.saveSearchByUser.saveSearchData,
    savedViewData: state.getSearchView.savedViewData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // postSaveSearchData: () => dispatch(postSaveSearchData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SaveFilterView);
