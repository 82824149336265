import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import { Checkbox } from "@mui/material";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import { updatePageNumber } from "../../store/actions/filterFormDataAction";
import {
  exportOffersList,
  removeAllOfferFromExportList,
  removeItemFromExportOffersList,
  selectAllOffersForExport,
} from "../../store/actions/exportSavedOfferAction";
import {
  removeAllOffers,
  removeSelectedOfferItem,
  selectAllOffers,
  selectedOfferItems,
} from "../../store/actions/savedOfferAction";
import Loader from "../CommonFiles/Loader";
import ApiCalls from "../ApiList/apiCalls";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const ApiList = new ApiCalls();
 const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postPerPage: 20,
      currentPage: 1,
      test: 20,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    document.removeEventListener("onPageChange", this.onPageChange);
    document.addEventListener("onPageChange", this.onPageChange.bind(this));
    document.removeEventListener("gridView", this.onGridChange);
    document.addEventListener("gridView", this.onGridChange.bind(this));
    document.removeEventListener("pageSizeChange", this.onPageSizeChange);
    document.addEventListener(
      "pageSizeChange",
      this.onPageSizeChange.bind(this)
    );
  }
  onPageSizeChange(){
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ currentPage: userFilterData.pageNo });
        this.setState({ test: userFilterData.pageSize });
  }
  onGridChange(ev) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ currentPage: userFilterData.pageNo });
        this.setState({ test: userFilterData.pageSize });
  }
  onPageChange(ev) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ test: userFilterData.pageSize });
    this.setState({ currentPage: ev.detail.pageNo });
  }
  componentDidUpdate(prevProps) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    // if (userFilterData.pageNo !== prevProps.userFilterData.pageNo) {
    //   this.myRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // }
  }
  handleCheckBoxForOffers = async (event, item) => {
    if (event.target.checked) {
      this.props.selectedOfferItems(item.id);
      this.props.exportOffersList(item);
    } else {
      this.props.removeSelectedOfferItem(item.id);
      this.props.removeItemFromExportOffersList(item);
    }
  };
  handleSelectAllOffers = (event) => {
    if (this.props.selectedItems.length === 0) {
      this.props.selectAllOffers(this.props.offerData);
      this.props.selectAllOffersForExport(this.props.offerData);
    } else {
      this.props.removeAllOffers();
      this.props.removeAllOfferFromExportList();
    }
  };
  render() {
    const { formData, offerData, selectedItems } = this.props;
    //const { postPerPage, currentPage, data } = this.state;
    //const indexOfLastPage = currentPage * postPerPage;

    return (
      <>
        <TableContainer
          ref={this.myRef}
          component={Paper}
          style={{
            width: "98%",
            height: "591px",
            marginLeft: "8px",
            marginTop: "7px",
            overflow: "scroll",
            backgroundColor: "#ECEFF4",
          }}
        >
          <Loader open={this.props.loadingByOfferPermission} />
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    padding: "0px",
                    border: "1px solid #C4C4C4",
                    background: "#017E8F",
                    color: "white",
                    width: "42px",
                  }}
                >
                  <Checkbox
                    checked={selectedItems?.length === offerData?.length}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < offerData.length
                    }
                    sx={{
                      color: "#FFF",
                      "& .MuiSvgIcon-root": {
                        color: "#FFF",
                        height: "18px",
                        width: "18px",
                      },
                    }}
                    onChange={this.handleSelectAllOffers}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    minWidth: 48,
                  }}
                  align="center"
                  style={{
                    border: "1px solid #C4C4C4",
                    background: "#017E8F",
                    color: "#FFF",
                    padding: "0px",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="column"
                >
                  S No.
                </TableCell>
                <TableCell
                  sx={{ minWidth: 132 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Image
                </TableCell>

                <TableCell
                  sx={{ minWidth: 132 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Country
                </TableCell>

                <TableCell
                  sx={{ minWidth: 115 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Retailer
                </TableCell>
                <TableCell
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Offer Image Id
                </TableCell>
                <TableCell
                  sx={{ minWidth: 192 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Offer Name
                </TableCell>
                <TableCell
                  sx={{ minWidth: 180 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Offer Timeline
                </TableCell>
                <TableCell
                  sx={{ minWidth: 99 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Promo Price
                </TableCell>
                <TableCell
                  sx={{ minWidth: 105 }}
                  align="center"
                  className="list-View-header-table-cell"
                >
                  Regular Price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 115 }}
                  className="list-View-header-table-cell"
                >
                  Brands
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 107 }}
                  className="list-View-header-table-cell"
                >
                  Product
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 97 }}
                  className="list-View-header-table-cell"
                >
                  Packsize
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 78 }}
                  className="list-View-header-table-cell"
                >
                  Promo Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 131 }}
                  className="list-View-header-table-cell"
                >
                  Promo Mechanic
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 131 }}
                  className="list-View-header-table-cell"
                >
                  Flyer Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 82 }}
                  className="list-View-header-table-cell"
                >
                  Flyer Page
                  <br />
                  No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ minWidth: 180 }}
                  className="list-View-header-table-cell"
                >
                  Flyer Timeline
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ minWidth: 89 }}
                  className="list-View-header-table-cell"
                >
                  Total Flyer Pages
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {offerData?.map((offer, index) => (
                <TableRow key={offer.id}>
                  <TableCell
                    align="center"
                    style={{
                      border: "1px solid #C4C4C4",
                      width: "42px",
                      padding: "0px",
                    }}
                  >
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#208EDC",
                          height: "18px",
                          width: "18px",
                        },
                      }}
                      checked={selectedItems.includes(offer.id)}
                      onChange={(event) => {
                        this.handleCheckBoxForOffers(event, offer);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                    component="th"
                    scope="row"
                  >
                    {(this.state.currentPage - 1) * this.state.test + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      border: "1px solid #C4C4C4",
                      padding: "6px 16px",
                    }}
                  >
                    <img
                      src={ApiList.getImageUrl() + offer.image}
                      width="100px"
                      height="70px"
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.countryName}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.retailerName}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {/* <span
                      style={{
                        visibility:
                          prmissionData && prmissionData.isAllowOfferLogs
                            ? "visible"
                            : "hidden",
                      }}
                    > */}
                    {prmissionData && prmissionData.isAllowOfferLogs ? (
                      <>
                        <a
                          href={`http://adminpanel.clicflyer.com:92/Admin/offerv1/EditBulk/${offer.offerImageId}?index=1`}
                          target="_blank"
                        >
                          {offer.offerImageId}
                        </a>
                      </>
                    ) : (
                      <>{offer.offerImageId}</>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.nameEn}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.offerStartDate} - {offer.offerEndDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.promoPrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.regularPrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.brand}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.product}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.packSize}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.promoQuantity}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.promoMechanic}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.flyerName}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.pageNumber}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.flyerStartDate
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                    &nbsp;-&nbsp;
                    {offer.flyerEndDate
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="list-View-body-table-cell"
                  >
                    {offer.totalFlyerPages}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
    selectedItems: state.savedOfferData.selectedOfferItems,
    loadingByOfferPermission: state.userPermissionOfferData.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePageNumber: (newValue) => dispatch(updatePageNumber(newValue)),
    getOfferByUserPermission: (postObj) =>
      dispatch(getOfferByUserPermission(postObj, true)),
    selectedOfferItems: (itemId) => dispatch(selectedOfferItems(itemId)),
    exportOffersList: (offerItem) => dispatch(exportOffersList(offerItem)),
    removeSelectedOfferItem: (itemId) =>
      dispatch(removeSelectedOfferItem(itemId)),
    removeItemFromExportOffersList: (item) =>
      dispatch(removeItemFromExportOffersList(item)),
    selectAllOffers: (newValue) => dispatch(selectAllOffers(newValue)),
    removeAllOffers: () => dispatch(removeAllOffers()),
    selectAllOffersForExport: (offerData) =>
      dispatch(selectAllOffersForExport(offerData)),
    removeAllOfferFromExportList: () =>
      dispatch(removeAllOfferFromExportList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
