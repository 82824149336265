import React, { Component } from "react";
import { connect } from "react-redux";
import { getProfileData } from "../../store/actions/profileAction";
import { getAdditionalInfoData } from "../../store/actions/additionalInfoAction";
import userProfileAvatar from "../../images/userProfileAvatar.svg";

const userData = JSON.parse(localStorage.getItem("userData"));
class UserProfileData extends Component {
  handleSign() {
    window.location.href = "/logout";
  }
  render() {
    const { profileData } = this.props;
    return (
      <>
        <div className="user-profile-page-container">
          <img className="account-info-avatar" src={userProfileAvatar} />
          <span className="account-info-heading">Account Information</span>
        </div>

        <br />
        <div className="user-info-main-div">
          <div className="username-div">
            <span className="username-sub-heading">Username:</span>

            <div className="username-value">
              <p>{profileData.email}</p>
            </div>
          </div>
          <div className="name-div">
            <p className="name-sub-heading">Full Name:</p>
            <div className="name-value">{profileData.name}</div>
          </div>
          <div className="company-div">
            <p className="company-sub-heading">Company Name:</p>
            <div className="company-value">{profileData.companyName}</div>
          </div>
          <div className="email-div">
            <p className="email-sub-heading">Email:</p>
            <div className="email-value">{profileData.email}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.getProfile.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: (loginId) => dispatch(getProfileData(loginId)),
    getAdditionalInfoData: (loginId) =>
      dispatch(getAdditionalInfoData(loginId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileData);
