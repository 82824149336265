import { Grid } from "@mui/material";
import React, { Component } from "react";
import Footer from "./CommonFiles/Footer";
import Navbar from "./Navbar/Navbar";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    const flagValue = JSON.parse(localStorage.getItem("Flag"));
    return (
      <section
        style={{
          position: "relative",
          height: "100vh",
          maxWidth: "1920px",
          width: "100%",
          margin: "auto",
        }}
      >
        <Navbar />
        {/* {flagValue ? <Navbar /> : null} */}
        <div
          style={
            window.location.pathname == "/" ||
            window.location.pathname == "/logout" ||
            window.location.pathname == "/whats-new" ||
            window.location.pathname == "/offer-bank-user-guide"
              ? null
              : {
                  maxWidth: "100%",
                  width: "100%",
                  margin: "12px auto 0px auto",
                }
          }
          // style={
          //   flagValue
          //     ? {
          //         maxWidth: "100%",
          //         width: "100%",
          //         margin: "12px auto 0px auto",
          //       }
          //     : null
          // }
        >
          <Grid container>
            {/* //sx={{ marginBottom: flagValue ? "36px" : "" }} */}
            <Grid
              item
              xs={12}
              sx={{
                marginBottom:
                  window.location.pathname == "/" ||
                  window.location.pathname == "/whats-new" ||
                  window.location.pathname == "/offer-bank-user-guide" ||
                  window.location.pathname == "/logout"
                    ? ""
                    : "36px",
              }}
            >
              {this.props.children}
            </Grid>
          </Grid>
        </div>
        {window.location.pathname == "/" ||
        window.location.pathname == "/logout" ? null : (
          <Footer />
        )}
      </section>
    );
  }
}
