import React, { Component } from "react";
import { AppBar, CssBaseline, Drawer, Typography } from "@mui/material";
import Subscription from "./Subscription";
import ForgetPassword from "./ForgetPassword";
import { connect } from "react-redux";
import {
  getProfileData,
  updateProfileView,
} from "../../store/actions/profileAction";
import { getAdditionalInfoData } from "../../store/actions/additionalInfoAction";
import { updateNavView } from "../../store/actions/filterFormDataAction";
import "./UserProfile.css";
import UserProfileData from "./UserProfileData";

const userData = JSON.parse(localStorage.getItem("userData"));

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      f: 0,
      profileView:0,
    };
  }
  componentDidMount() {
    this.props.getProfileData(userData.userId);
    this.props.getAdditionalInfoData(userData.userId);
    this.props.updateNavView(3);
  }
  render() {
    const { profileView } = this.props;
    return (
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <section className="profile-page-left-section">
          <CssBaseline />
          <AppBar position="fixed" sx={{ pl: "500px" }}></AppBar>
          <Drawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: 305,
                boxSizing: "border-box",
                mt: "63px",
                mb: "100px",
                height: "86.5vh",
                background:
                  "linear-gradient(174.01deg, #A6E7A6 -5.32%, #75C2CC 104.23%)",
                position:"absolute",
              },

            }}
            variant="permanent"
            anchor="left"
          >
            <div>
              <div
                style={{
                  borderLeft: profileView == 0 ? "8px solid #004E58" : "",
                  borderBottom: "1px solid white",
                  height: "57px",
                  width: "297px",
                  textAlign: "center",
                  paddingTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => this.props.updateProfileView(0)}
              >
                <Typography
                  style={{
                    fontWeight: profileView == 0 ? 700 : 400,
                  }}
                >
                  View Profile
                </Typography>
              </div>
              <div
                style={{
                  borderLeft: profileView == 1 ? "8px solid #004E58" : "",
                  borderBottom: "1px solid white",
                  height: "57px",
                  width: "297px",
                  textAlign: "center",
                  paddingTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => this.props.updateProfileView(1)}
              >
                <Typography
                  style={{
                    fontWeight: profileView == 1 ? 700 : 400,
                  }}
                >
                  My Subscription Plan
                </Typography>
              </div>
              {/* <div
                style={{
                  borderLeft: profileView == 2 ? "8px solid #004E58" : "",
                  borderBottom: "1px solid white",
                  height: "57px",
                  width: "297px",
                  textAlign: "center",
                  paddingTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => this.props.updateProfileView(2)}
              >
                <Typography
                  style={{
                    fontWeight: profileView == 2 ? 700 : 400,
                  }}
                >
                  Change Password
                </Typography>
              </div> */}
            </div>
          </Drawer>
        </section>
        <section className="profile-page-right-section">
          {profileView == 0 && <UserProfileData />}
          {profileView == 1 && <Subscription />}
          {/* {profileView == 2 && <ForgetPassword />} */}
        </section>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profileData: state.getProfile.profileData,
    profileView: state.getProfile.profileView,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfileView: (newValue) => dispatch(updateProfileView(newValue)),
    getProfileData: (loginId) => dispatch(getProfileData(loginId)),
    getAdditionalInfoData: (loginId) =>
      dispatch(getAdditionalInfoData(loginId)),
    updateNavView: (newValue) => dispatch(updateNavView(newValue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
