import {
  REMOVE_SAVED_OFFER_REQUEST,
  REMOVE_SAVED_OFFER_SUCCESS,
  REMOVE_SAVED_OFFER_FAILURE,
  CART_SELECTED_OFFER_ITEMS,
  CART_CLEAR_SELECTED_OFFER_ITEMS,
  CART_REMOVE_SELECTED_OFFER_ITEMS,
  CART_SELECT_ALL_OFFERS,
  CART_REMOVE_ALL_OFFERS,
} from "../actionTypes";

const initialState = {
  removeOfferObject: {},
  loading: false,
  selectedCartOfferItems: [],
  error: "",
};

const removeSavedOfferObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_SAVED_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case REMOVE_SAVED_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        removeOfferObject: action.payload,
        error: "",
      };

    case REMOVE_SAVED_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        removeOfferObject: {},
        error: action.payload,
      };

    case CART_SELECTED_OFFER_ITEMS:
      const selectedCartItems = new Set([
        ...state.selectedCartOfferItems,
        action.payload,
      ]);
      return {
        ...state,
        loading: false,
        selectedCartOfferItems: [...selectedCartItems],
        error: "",
      };

    case CART_REMOVE_SELECTED_OFFER_ITEMS:
      const index = [...state.selectedCartOfferItems].indexOf(action.payload);
      let selectedOffersList = [...state.selectedCartOfferItems];
      selectedOffersList.splice(index, 1);
      return {
        ...state,
        loading: false,
        selectedCartOfferItems: selectedOffersList,
        error: "",
      };

    case CART_CLEAR_SELECTED_OFFER_ITEMS:
      return { ...initialState };

    case CART_SELECT_ALL_OFFERS:
      return {
        ...state,
        selectedCartOfferItems: action.payload,
      };
    case CART_REMOVE_ALL_OFFERS:
      return {
        ...state,
        selectedCartOfferItems: [],
      };

    default:
      return state;
  }
};

export default removeSavedOfferObjectReducer;
