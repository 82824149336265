import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import saveCurrentFilterStyles from "../../styling/saveCurrentFilterStyles";
import { postSaveSearchData } from "../../store/actions/saveSearchByUserAction";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  updateAnlyticsSearchText,
  updateDescription,
  updateSavedSearchId,
  updateSearchName,
} from "../../store/actions/filterFormDataAction";
import saveCurrentFilter from "../../images/saveCurrentFilter.svg";
import confirmIcon from "../../images/confirmIcon.svg";
import UpdateJson from "../CommonFiles/UpdateJson";
import { getSaveSearchData } from "../../store/actions/getSaveSearchAction";
import { updateSavedFilterCount } from "../../store/actions/userManagerAction";
import ApiCalls from "../ApiList/apiCalls";
import UnauthorisedUser from "../CommonFiles/Unauthorised";
const savedflag = JSON.parse(localStorage.getItem("savedflag"));
const ApiList = new ApiCalls();
class SaveCurrentFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchName: "",
      description: "",
      savedFilterId: "0",
    };
  }
  componentDidMount() {
    // document.addEventListener("savedfilter", this.onChange.bind(this));
    //document.addEventListener("srchBarChange", this.onCall.bind(this));
    //document.addEventListener("newsearch", this.onSearchChange.bind(this));
    //const savedflag = JSON.parse(localStorage.getItem("savedflag"));
    // if (savedflag != null && JSON.stringify(savedflag) != "{}") {
    //   this.setState({ f: savedflag.flag });
    //   this.setState({ updateId: savedflag.id });
    // }
  }
  // onCall(ev){
  //   localStorage.setItem("name_en", ev.detail.value);
  // }
  // onSearchChange() {
  //   this.setState({ f: 0 });
  // }
  // onChange(ev) {
  //   this.setState({ flag: ev.detail.flag });
  //   const savedflag = JSON.parse(localStorage.getItem("savedflag"));
  //   localStorage.setItem("name_en", savedflag.name);
  //   this.setState({ f: savedflag.flag });
  //   this.setState({ description: savedflag.desc });
  //   this.setState({ searchName: savedflag.name });
  //   this.setstate({ updateId: savedflag.id });
  // }

  handleClickOpen = () => {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const { changeInPriceRange, changeInDiscountRange } = this.props;
    if (
      !(
        userFilterData.cityIds ||
        userFilterData.categoryIds ||
        userFilterData.retailerIds ||
        userFilterData.subCategoryIds ||
        userFilterData.itemIds ||
        userFilterData.brandIds ||
        userFilterData.product ||
        userFilterData.packSize ||
        userFilterData.promoMechIds ||
        userFilterData.attribute ||
        changeInPriceRange ||
        changeInDiscountRange
      )
    ) {
      this.props.enqueueSnackbar("Please select one or more Filters", {
        variant: "error",
      });
    } else {
      this.setState({ description: userFilterData.description });
      this.setState({ searchName: userFilterData.searchName });
      this.setState({ savedFilterId: userFilterData.saveSearchId });
      this.setState({ open: true });
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // if (savedflag != null && JSON.stringify(savedflag) != "{}") {
    //   this.setState({ updateId: savedflag.id });
    // }
    UpdateJson([
      { key: "saveSearchId", value: this.state.savedFilterId },
      { key: "description", value: this.state.description },
      { key: "searchName", value: this.state.searchName },
    ]);
    await ApiList.saveSearchByUser()
       .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
         localStorage.setItem("savedflag", "{}");
         localStorage.setItem("name_en", "");
         const saveSearchData = response.data;
         if (response.data.status == 2 && this.state.savedFilterId == 0) {
           this.props.enqueueSnackbar("The given name is already exits please give another name", {
             variant: "error",
           });
         } else {
           this.props.getSaveSearchData();
           this.props.enqueueSnackbar("Saved filter Successfully", {
             variant: "success",
           });
           this.setState({ open: false });
         }
       })
       .catch((error) => {
         UnauthorisedUser(error.response.status);
       });
    //await this.props.postSaveSearchData();
    UpdateJson([
      { key: "saveSearchId", value: "" },
      { key: "description", value: "" },
      { key: "searchName", value: "String" },
    ]);
    //debugger;
  //   if (this.props.saveSearchData.saveSearchData.status==2){
  //     this.props.enqueueSnackbar("The given name is already exits", {
  //       variant: "error",
  //     });
  //   }else{
  //   await this.props.getSaveSearchData();
  //   this.props.enqueueSnackbar("Saved filter Successfully", {
  //     variant: "success",
  //   });
  // }
    // ApiList.getSavedFilterCount()
    //   .then((response) => {
    //     this.props.updateSavedFilterCount(response.data.count);
    //     const newToken = response.headers.token;
    //     const userData = JSON.parse(localStorage.getItem("userData"));
    //     const newUserData = { ...userData, token: newToken };
    //     localStorage.setItem("userData", JSON.stringify(newUserData));
    //   })
    //   .catch((error) => {});
    
    // this.setState({ f: 0 });
  };
  render() {
    // const savedflag = JSON.parse(localStorage.getItem("savedflag"));
    // const {
    //   priceFormMinValue,
    //   priceFormMaxValue,
    //   discountFormMinValue,
    //   discountFormMaxValue,
    //   suggestionFlag,
    // } = this.props;
    return (
      <>
        <button
          style={saveCurrentFilterStyles.buttonSizeStyling}
          className="refine-filter-save-current-filter-button"
          variant="contained"
          onClick={this.handleClickOpen}
        >
          <img
            className="refine-filter-save-current-filter-button-icon"
            src={saveCurrentFilter}
          />
          Save Current Filter
        </button>
        <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="lg">
          <div className="saved-filter-dialogue-title">
            <span className="save-filter-as-para">Save Filter as:</span>
            <span>
              <CloseIcon
                onClick={this.handleClose}
                className="saved-filter-title-close-button"
              />
            </span>
          </div>
          <DialogContent>
            <div>
              <form required onSubmit={this.handleSubmit}>
                <div className="save-filter-as-form-container">
                  <TextField
                    sx={{
                      maxHeight: "30px",
                      "& .MuiInputBase-input": {
                        padding: "5px 14px",
                      },
                    }}
                    size="small"
                    required={true}
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    value={
                      this.state.searchName == "String"
                        ? ""
                        : this.state.searchName
                    }
                    fullWidth
                    onChange={(event) => {
                      this.setState({ searchName: event.target.value });
                    }}
                  />
                  <br />
                  <br />
                  <TextField
                    id="outlined-basic-description"
                    label="Description"
                    variant="outlined"
                    value={this.state.description}
                    multiline={true}
                    rows={3}
                    fullWidth
                    onChange={(event) => {
                      this.setState({
                        description: event.target.value,
                      });
                    }}
                  />
                </div>
                <br />
                <button className="save-filter-save-button" type="submit">
                  <img src={confirmIcon} className="saved-filter-save-icon" />
                  Save
                </button>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loadingByDataPermission: state.getDataByPermission.loading,
    formData: state.filterForm,
    analyticsText: state.filterForm.anlyitcs_searchText,
    saveSearchData: state.saveSearchByUser,
    suggestionFlag: state.getSearchSuggestion.suggestionFlag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSaveSearchData: () => dispatch(getSaveSearchData()),
    updateSavedFilterCount: (newValue) =>
      dispatch(updateSavedFilterCount(newValue)),
    postSaveSearchData: () => dispatch(postSaveSearchData()),
    updateSearchName: (value) => dispatch(updateSearchName(value)),
    updateDescription: (value) => dispatch(updateDescription(value)),
    updateSavedSearchId: (value) => dispatch(updateSavedSearchId(value)),
    updateAnlyticsSearchText: (newValue) => {
      dispatch(updateAnlyticsSearchText(newValue));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SaveCurrentFilter));
