import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { updateSorting } from "../../store/actions/filterFormDataAction";
import { connect } from "react-redux";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import MenuItem from "@mui/material/MenuItem";
import sortSizeStyles from "../../styling/sortSizeStyles";
import Tooltip from "@mui/material/Tooltip";
import "./AdditionalFilter.css";
import UpdateJson from "../CommonFiles/UpdateJson";
import { InputLabel } from "@mui/material";
import { CheckPicker, CheckTreePicker, Stack } from "rsuite";

class OfferValidity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OfferValidity: [],
    };
  }
  componentDidMount(){
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if(userFilterData.OfferValidity){
      const id = userFilterData.OfferValidity;
       const offerValidityIds = id.split(",").map((id) => id.trim());
       this.setState({ OfferValidity: offerValidityIds });
    }
  }

    handleChange = (value) => {
      UpdateJson([
        {
          key: "offerValidity",
          value: value.toString(),
        },
      ]);
      console.log("value", value.toString());
      this.setState({ OfferValidity: value });
      this.props.getOfferByUserPermission();
    };

  render() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    return (
      <>
        <CheckTreePicker
          data={treeData}
          searchable={false}
          value={this.state.OfferValidity}
          onChange={(value) => {
            this.handleChange(value);
          }}
          //defaultOpen={true}
          // defaultExpand={false}
          // expandedGroups={false}
          //defaultExpandAllGroup
          className="selectValidity"
          style={{ maxWidth: "140px" }}
          placeholder="Offer Validity"
          labelKey="name"
          valueKey="id"
        />
      </>
    );
  }
}


const treeData = [
  {
    id: "1,2,3,4",
    name: "Short (Validity 1 to 4 Days)",
    children: Array.from({ length: 4 }, (_, index) => ({
      id: (index + 1).toString(),
      name: `${index + 1} ${index + 1==1?"Day":"Days"}`,
    })),
  },
  {
    id: "5,6,7,8,9,10",
    name: "Mid (Validity 5 to 10 Days)",
    children: Array.from({ length: 6 }, (_, index) => ({
      id: (index + 5).toString(),
      name: `${index + 5} Days`,
    })),
  },
  {
    id: "11,12,13,14",
    name: "Long (Validity > 10 Days)",
    children: [
      ...Array.from({ length: 4 }, (_, index) => ({
        id: (index + 11).toString(),
        name: `${index + 11} Days`,
      })),
      {
        id: "15",
        name: "> 14 Days",
      },
    ],
  },
];

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission("sort", true)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OfferValidity);
