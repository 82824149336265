import authReducer from "./authReducer";
import getDataByPermissionReducer from "./getDataByPermissionReducer";
import { combineReducers } from "redux";
import updateFilterFormDataReducer from "./filterFormReducer";
import getOfferByUserPermissionReducer from "./offerImageReducers";
import getOfferByUserPermissionReducerCopy from "./dataCopyReducer";
import getSavedOfferObjectReducer from "./savedOfferReducer";
import exportSavedOfferReducer from "./exportSavedOfferReducer";
import postSavedOfferObjectReducer from "./postSavedOfferReducer";
import getSearchSuggestionDataReducer from "./searchSuggestionReducer";
import getOfferDetailsObjectReducer from "./offerDetailsReducer";
import removeSavedOfferObjectReducer from "./removeSavedOfferReducer";
import getFlyerPagesReducer from "./getFlyerPagesReducer";
import compareOfferReducer from "./compareReducer";
import flyersPageRenderReducer from "./flyersReducer";
import getWeekYearObjectReducer from "./getWeekYearReducer";
import getWeekNumberObjectReducer from "./getWeekNumberReducer";
import flyersPageDataReducer from "./flyersDataReducer";
import getFlyerDetailsObjectReducer from "./flyerDetailReducer";
import saveSearchReducer from "./saveSearchReducer";
import getNotificationReducer from "./notificationReducer";
import getNotificationHomeReducer from "./notificationHomeReducer";
import getProfileReducer from "./profileReducer";
import userFilterPermissionPageDataReducer from "./userFilterPermissionReducer";
import getSaveSearchReducer from "./getSaveSearchReducer";
import getAdditionalInfoReducer from "./additionalInfoReducer";
import getSearchViewReducer from "./savedSearchViewReducer";
import getSaveSearchByIdReducer from "./getSaveSearchByIdReducer";
import termsAndConditionReducer from "./termsAndConditionReducer";
import getApplySavedFilterReducer from "./applySavedFilterReducer";
import getSaveSearchShareReducer from "./getSaveSearchShareReducer";
import updateUserManagerReducer from "./userManagerReducer";



const rootReducer = combineReducers({
  auth: authReducer,
  getDataByPermission: getDataByPermissionReducer,
  filterForm: updateFilterFormDataReducer,
  userPermissionOfferData: getOfferByUserPermissionReducer,
  offerDataCopy: getOfferByUserPermissionReducerCopy,
  savedOfferData: getSavedOfferObjectReducer,
  exportSavedOffer: exportSavedOfferReducer,
  postSavedOfferData: postSavedOfferObjectReducer,
  getSearchSuggestion: getSearchSuggestionDataReducer,
  getOfferDetails: getOfferDetailsObjectReducer,
  removeSavedOffer: removeSavedOfferObjectReducer,
  getFlyerPages: getFlyerPagesReducer,
  compareOffer: compareOfferReducer,
  getWeekYear: getWeekYearObjectReducer,
  getWeekNumber: getWeekNumberObjectReducer,
  flyersPage: flyersPageRenderReducer,
  flyersData: flyersPageDataReducer,
  flyerDetail: getFlyerDetailsObjectReducer,
  getNotification: getNotificationReducer,
  getNotificationHome: getNotificationHomeReducer,
  saveSearchByUser: saveSearchReducer,
  getProfile: getProfileReducer,
  userFiltersPermission: userFilterPermissionPageDataReducer,

  getSaveSearchData: getSaveSearchReducer,
  getSearchView: getSearchViewReducer,
  getAdditionalInfo: getAdditionalInfoReducer,
  getSaveSearchByIdData: getSaveSearchByIdReducer,
  getTnCObject: termsAndConditionReducer,
  getApplySavedFilter: getApplySavedFilterReducer,
  getSaveSearchShareData: getSaveSearchShareReducer,
  userManager:updateUserManagerReducer,
});

export default rootReducer;
