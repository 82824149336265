import React, { Component } from "react";
import { connect } from "react-redux";
import { updateNavView } from "../store/actions/filterFormDataAction";
import ApiCalls from "./ApiList/apiCalls";

const userData = JSON.parse(localStorage.getItem("userData"));
const ApiList = new ApiCalls();
class UserAgreement extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.updateNavView(4);
  }
  state = {};
  render() {
    return (
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ textDecoration: "underline" }}>Subscription Agreement</h2>
        {userData.tnC.indexOf("</") !== -1 ? (
          <div
            style={{
              padding: "22px 29px",
              height: "80vh",
              overflowY: "auto",
              backgroundColor: "#ECEFF4",
            }}
            dangerouslySetInnerHTML={{
              __html: userData.tnC.replace(/(<? *script)/gi, "illegalscript"),
            }}
          ></div>
        ) : (
          userData.tnC
        )}
        <button
          onClick={() => {
            ApiList.getDataByPermission(userData.id, true).then((response) => {
              const newToken = response.headers.token;
              const userData = JSON.parse(localStorage.getItem("userData"));
              const newUserData = { ...userData, token: newToken };
              localStorage.setItem("userData", JSON.stringify(newUserData));
            });
            window.location.href = "/offers";
          }}
          style={{ marginTop: "5px" }}
        >
          Accept Terms and Condition
        </button>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNavView: (count) => dispatch(updateNavView(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAgreement);
