import React, { Component } from "react";
import GetImage from "./Image";

class OfferDataGridView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { offerData, savedOffersList, paginationhit } = this.props;

    return (
      <GetImage
        offerData={offerData}
        savedOffersList={savedOffersList}
        paginationhit={paginationhit}
      />
    );
  }
}

export default OfferDataGridView;
