import {
  GET_TNC_REQUEST,
  GET_TNC_SUCCESS,
  GET_TNC_FAILURE,
  GET_PRODUCT_DATA,
} from "../actionTypes";

const initialState = {
    loading: true,
    termsAndCondition: "",
    productData:"",
};

const termsAndConditionReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TNC_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };

      case GET_TNC_SUCCESS:
        return {
          ...state,
          loading: false,
          termsAndCondition: action.payload,
          error: "",
        };

      case GET_TNC_FAILURE:
        return {
          ...state,
          loading: false,
          termsAndCondition: "",
          error: action.payload,
        };
      case GET_PRODUCT_DATA:
        return {
          ...state,
          loading: false,
          productData: action.payload,
          error: "",
        };
        
      default:
        return state;
    }
};

export default termsAndConditionReducer;