const UnauthorisedUser = (statusCode) => {
  if (statusCode === 401) {
    window.location.href = "/logout";
    // localStorage.setItem("userData", "{}");
    // localStorage.removeItem("savedflag");
    // localStorage.removeItem("userPermissionData");
    // localStorage.removeItem("userFilterData");
    // localStorage.removeItem("listData");
  }
};

export default UnauthorisedUser;
