import React, { Component } from "react";
import { connect } from "react-redux";
import {
  removeAllOffers,
  selectAllOffers,
} from "../../store/actions/savedOfferAction";
import {
  removeAllOfferFromExportList,
  selectAllOffersForExport,
} from "../../store/actions/exportSavedOfferAction";
import { Checkbox } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

class SelectAllOffers extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    document.addEventListener("clear", this.onClear.bind(this));
  }

  onClear(){
       this.props.removeAllOffers();
       this.props.removeAllOfferFromExportList();
  }
  
  handleSelectAllOffers = (event) => {
    if (this.props.selectedItems.length===0) {
      this.props.selectAllOffers(this.props.offerData);
      this.props.selectAllOffersForExport(this.props.offerData);
    } else {
      this.props.removeAllOffers();
      this.props.removeAllOfferFromExportList();
    }
  };

  render() {
    const { offerData, selectedItems } = this.props;

    return (
      <Tooltip
        title={
          (selectedItems?.length > 0 &&
            selectedItems?.length < offerData?.length) ||
          selectedItems?.length === offerData?.length
            ? "Uncheck All"
            : "Select All"
        }
      >
        <Checkbox
          checked={
            selectedItems.length>0 && selectedItems.length === offerData.length
          }
          indeterminate={
            selectedItems?.length > 0 && selectedItems.length < offerData.length
          }
          sx={{
            marginRight: ".5rem",
            "& .MuiSvgIcon-root": {
              backgroundColor: "85CEC0",
              color: "#3E8D98",
              height: "22px",
              width: "22px",
            },
            "&.Mui-checked": {
              color: "#3E8D98",
            },
            padding: "0px 9px",
          }}
          onChange={this.handleSelectAllOffers}
        />
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
    selectedItems: state.savedOfferData.selectedOfferItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectAllOffers: (newValue) => dispatch(selectAllOffers(newValue)),
    removeAllOffers: () => dispatch(removeAllOffers()),
    selectAllOffersForExport: (offerData) =>
      dispatch(selectAllOffersForExport(offerData)),
    removeAllOfferFromExportList: () =>
      dispatch(removeAllOfferFromExportList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAllOffers);
