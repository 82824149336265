import { SavedSearch } from "@mui/icons-material";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_SAVE_SEARCH_REQUEST,
  GET_SAVE_SEARCH_SUCCESS,
  GET_SAVE_SEARCH_FAILURE,
  SAVED_SEARCH_COUNT,
} from "../actionTypes";

const ApiList = new ApiCalls();
export const getSaveSearchRequest = () => {
  return {
    type: GET_SAVE_SEARCH_REQUEST,
  };
};

export const getSaveSearchSuccess = (SaveSearch) => {
  return {
    type: GET_SAVE_SEARCH_SUCCESS,
    payload: SaveSearch,
  };
};

export const getSaveSearchFailure = (error) => {
  return {
    type: GET_SAVE_SEARCH_FAILURE,
    payload: error,
  };
};

export const updateSaveSearchCount = (count) => {
  return {
    type: SAVED_SEARCH_COUNT,
    payload: count,
  };
};

export const getSaveSearchData = () => {
  return (dispatch) => {
    dispatch(getSaveSearchRequest());
    ApiList.getSaveSearch()
      .then((response) => {
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
        const SaveSearch = response.data;
        const customEvent = new CustomEvent("savedFilterCount", {
          detail: {
            savedOfferCount: response.data.length,
          },
        });
        document.dispatchEvent(customEvent);
        dispatch(getSaveSearchSuccess(SaveSearch));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getSaveSearchFailure(error.message));
      });
  };
};
