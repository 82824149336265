import React, { Component } from "react";
import { connect } from "react-redux";
import planInfoAvatar from "../../images/planInfoAvatar.svg";
import additonalInfoAvatar from "../../images/additonalInfoAvatar.svg";



const userData = JSON.parse(localStorage.getItem("userData"));
class Subscription extends Component {
  render() {
    const { profileData, additionalData } = this.props;
    return (
      <div className="profile-subscription-section-container">
        <div>
          <div className="plan-info-heading">
            <img className="plan-info-img" src={planInfoAvatar} />
            <span className="plan-info-span">Plan Information</span>
          </div>

          <br />

          <div className="plan-info-main-section">
            <div className="date-of-act-div">
              <p className="date-of-act-sub-heading">Date Of Activation: </p>
              <p className="date-of-act-value">
                {profileData.dateOfActivation}
              </p>
            </div>
            <div className="exp-date-div">
              <p className="exp-date-sub-heading">Date Of Expiry: </p>
              <p className="exp-date-value">{profileData.dateOfExpiry}</p>
            </div>
            <div className="status-div">
              <p className="status-sub-heading">Status: </p>
              <p className="status-value">
                {profileData.isActive ? "Activated" : "Deactivated"}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="additional-info-heading">
            <img
              className="additional-info-heading-avatar"
              src={additonalInfoAvatar}
            />
            <span className="additional-info-heading-span">
              Additional Information
            </span>
          </div>
          <br />
          <div className="add-info-main-section">
            <div className="country-div">
              <p className="country-sub-heading">Country(s):</p>
              <p className="country-value">{additionalData.countryName}</p>
            </div>
            <div className="retailer-div">
              <p className="retailer-sub-heading">Retailers:</p>
              <div
                style={{ height: "150px" ,overflowY:"scroll" }}
              >
                <p className="retailer-value">{additionalData.retailerName}</p>
              </div>
            </div>
            {/* <div className="add-ons-div">
              <p className="add-ons-sub-heading">Add-on's:</p>
              <p className="add-ons-value">Data Export,Tags,Attributes</p>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.getProfile.profileData,
    additionalData: state.getAdditionalInfo.additionalData,
  };
};

export default connect(mapStateToProps)(Subscription);