import {
  GET_WEEK_YEAR_REQUEST,
  GET_WEEK_YEAR_SUCCESS,
  GET_WEEK_YEAR_FAILURE,
  SELECTED_YEAR,
} from "../actionTypes";

const initialState = {
  loading: false,
  weekYears: [],
  selectedYear:'',
  error: "",
};

const getWeekYearObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEEK_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_WEEK_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        weekYears: action.payload,
        error: "",
      };

    case GET_WEEK_YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        weekYears: [],
        error: action.payload,
      };

      case SELECTED_YEAR:
        return {
          ...state,
          selectedYear:action.payload
        };

    default:
      return state;
  }
};

export default getWeekYearObjectReducer;
