import {
 GET_SAVE_SEARCH_VIEW_REQUEST,
  GET_SAVE_SEARCH_VIEW_SUCCESS,
  GET_SAVE_SEARCH_VIEW_FAILURE,
} from "../actionTypes";

const initialState = {
  loading: true,
  savedViewData: {},
  error: "",
};

const getSearchViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAVE_SEARCH_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SAVE_SEARCH_VIEW_SUCCESS:
      return {
        loading: false,
        savedViewData: action.payload,
        error: "",
      };
    case GET_SAVE_SEARCH_VIEW_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getSearchViewReducer;
