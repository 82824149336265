import { Grid } from "@mui/material";
import React, { Component } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import "./ContactUs.css";
import { updateNavView } from "../../store/actions/filterFormDataAction";
import { connect } from "react-redux";

class ContactUs extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.updateNavView(4);
  }
  state = {};
  render() {
    return (
      <section className="contact-main-section">
        <Grid container spacing={10}>
          <Grid item xs={6} className="left-col">
            <h2 className="">BODMAS TECHNOLOGIES PVT. LTD.</h2>
            <p>
              <strong>Head Office: Gurgaon</strong>
              <br />
              SF 601, 6th Floor, JMD Megapolis, Sohna Road
              <br />
              Gurgaon, Haryana 122018, India
            </p>
            <p>
              <strong>Mumbai</strong>
              <br />
              53, Bajaj Bhavan, 226, Nariman Point
              <br />
              Mumbai 400021, India
            </p>
          </Grid>

          <Grid item xs={6} className="right-col">
            <h2>LET'S TALK</h2>
            <strong>
              <a href="tel:+911244219594" class="link1">
                <PhoneIcon style={{ marginRight: "10px" }} />
                +91-124-4219594
              </a>
            </strong>

            <div class="contact-form">
              <h2>WRITE TO US AT</h2>
              <p>
                <a href="mailto:operations@clicflyer.com">
                  <EmailIcon style={{ marginRight: "10px" }} />
                  operations@clicflyer.com
                </a>
              </p>
            </div>
          </Grid>
        </Grid>
        <p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d521.6137050349985!2d77.03773332269972!3d28.419716782061382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d229e8903adbb%3A0xce5171e7f7efb3f5!2sJMD+MEGAPOLIS%2C+Sector+48%2C+Gurgaon%2C+Haryana+122018!5e0!3m2!1sen!2sin!4v1449224447081"
            frameborder="0"
            allowfullscreen=""
            className="iframe-map"
          ></iframe>
        </p>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateNavView: (count) => dispatch(updateNavView(count)),
  };
};

export default connect(null, mapDispatchToProps)(ContactUs);
