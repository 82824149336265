import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_OFFER_DETAILS_REQUEST,
  GET_OFFER_DETAILS_SUCCESS,
  GET_OFFER_DETAILS_FAILURE,
  IMAGE_POP_UP_OPEN,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getOfferDetailsRequest = () => {
  return {
    type: GET_OFFER_DETAILS_REQUEST,
  };
};

export const getOfferDetailsSuccess = (offerDetails) => {
  return {
    type: GET_OFFER_DETAILS_SUCCESS,
    payload: offerDetails,
  };
};

export const getOfferDetailsFailure = (error) => {
  return {
    type: GET_OFFER_DETAILS_FAILURE,
    payload: error,
  };
};

export const imagePopUpOpen=(boolValue)=>{
  return{
    type:IMAGE_POP_UP_OPEN,
    payload:boolValue
  }
}

export const getOfferDetailsObject = (offerId, loading = false) => {
  return (dispatch) => {
    if(loading) dispatch(getOfferDetailsRequest());
    ApiList.getOfferDetails(offerId)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const OfferDetailsData = response.data;
        dispatch(getOfferDetailsSuccess(OfferDetailsData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getOfferDetailsFailure(error.message));
      });
  };
};
