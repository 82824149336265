import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  EXPORT_SAVED_OFFER_REQUEST,
  EXPORT_SAVED_OFFER_SUCCESS,
  EXPORT_SAVED_OFFER_FAILURE,
  EXPORT_OFFERS,
  EXPORT_SAVED_OFFERS,
  REMOVE_ITEM_FROM_EXPORT_OFFERS_LIST,
  Remove_ITEM_FROM_EXPORT_SAVED_OFFERS_LIST,
  SELECT_ALL_OFFERS_FOR_EXPORT,
  SELECT_ALL_SAVED_OFFERS_FOR_EXPORT,
  REMOVE_ALL_OFFERS_FROM_EXPORT_LIST,
  REMOVE_ALL_SAVED_OFFERS_FROM_EXPORT_LIST,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const exportSavedOfferRequest = (exportData) => {
  return {
    type: EXPORT_SAVED_OFFER_REQUEST,
    payload: exportData,
  };
};

export const exportSavedOfferSuccess = (exportData) => {
  return {
    type: EXPORT_SAVED_OFFER_SUCCESS,
    payload: exportData,
  };
};

export const exportSavedOfferFailure = (error) => {
  return {
    type: EXPORT_SAVED_OFFER_FAILURE,
    payload: error,
  };
};

export const exportOffersList = (offersItemForExport) => {
  return {
    type: EXPORT_OFFERS,
    payload: offersItemForExport,
  };
};

export const removeItemFromExportOffersList = (offerItem) => {
  return {
    type: REMOVE_ITEM_FROM_EXPORT_OFFERS_LIST,
    payload: offerItem,
  };
};

export const exportSavedOffersList = (savedOfferItemForExport) => {
  return {
    type: EXPORT_SAVED_OFFERS,
    payload: savedOfferItemForExport,
  };
};

export const removeItemFromExportSavedOffersList = (offerItem) => {
  return {
    type: Remove_ITEM_FROM_EXPORT_SAVED_OFFERS_LIST,
    payload: offerItem,
  };
};

export const selectAllSavedOffersForExport = (savedOfferList) => {
  return {
    type: SELECT_ALL_SAVED_OFFERS_FOR_EXPORT,
    payload: savedOfferList,
  };
};

export const selectAllOffersForExport = (offerData) => {
  return {
    type: SELECT_ALL_OFFERS_FOR_EXPORT,
    payload: offerData,
  };
};

export const removeAllOfferFromExportList = () => {
  return {
    type: REMOVE_ALL_OFFERS_FROM_EXPORT_LIST,
  };
};

export const removeAllSavedOfferFromExportList=()=>{
  return{
    type:REMOVE_ALL_SAVED_OFFERS_FROM_EXPORT_LIST,
  }
}



export const exportSavedOfferObject = (postObj) => {
  return (dispatch) => {
    dispatch(exportSavedOfferRequest(postObj));
    ApiList.exportSavedOfferObjectData(postObj)
      .then((response) => {
        const exportOfferObjectData = response.data;
        dispatch(exportSavedOfferSuccess(exportOfferObjectData));
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(exportSavedOfferFailure(error.message));
      });
  };
};
