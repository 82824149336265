import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { updateViewBy } from "../../store/actions/filterFormDataAction";
import viewByStyles from "../../styling/viewByStyles";
import Tooltip from "@mui/material/Tooltip";

class ViewBy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "v1",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  render() {
    return (
      <Tooltip title="Detail View / Image View" placement="top">
        <FormControl
          fullWidth
          size="small"
          style={viewByStyles.viewByStyling}
          sx={{
            "& .MuiFormControl-root": {
              width: "auto",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-root": {
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#757474",
            },
            "& .MuiSvgIcon-root": {
              transform: "translate(0px, -2px)",
              marginBottom: "15px",
            },
          }}
        >
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="detailsbutton"
            onChange={async (event) => {
              this.props.updateViewBy(Number(event.target.value));
            }}
            defaultValue={this.props.viewBy}
          >
            <MenuItem
              className="ViewBy-text"
              value="0"
              style={{
                fontSize: "14px",
              }}
            >
              Detail
            </MenuItem>
            <MenuItem
              value="1"
              style={{
                fontSize: "14px",
              }}
            >
              Image
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
    viewBy: state.userPermissionOfferData.viewBy,
    formData: state.filterForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateViewBy: (newValue) => {
      dispatch(updateViewBy(newValue));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewBy);
