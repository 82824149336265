import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { updateSorting } from "../../store/actions/filterFormDataAction";
import { connect } from "react-redux";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import MenuItem from "@mui/material/MenuItem";
import sortSizeStyles from "../../styling/sortSizeStyles";
import Tooltip from "@mui/material/Tooltip";
import "./AdditionalFilter.css";
import UpdateJson from "../CommonFiles/UpdateJson";

class Sort extends Component {
  constructor(props) {
    super(props);
    this.state={
      sorting:"N"
    };
  }

  handleChange = (event) => {
    const { value } = event.target;
     UpdateJson([
       {
         key: "sorting",
         value: value,
       },
     ]);
    this.props.getOfferByUserPermission();
  };

  render() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    return (
      <Tooltip
        title="Sort Orders"
        placement="top-end"
        TransitionProps={{ timeout: 200 }}
      >
        <FormControl
          fullWidth
          style={sortSizeStyles.sortSizeStyling}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSelect-select": {
              height: "30px",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#757474",
            },
          }}
        >
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userFilterData.sorting}
            onChange={this.handleChange}
          >
            <MenuItem
              className="Sort-fontText"
              value="N"
              style={{
                fontSize: "14px",
              }}
            >
              Offer New to Old
            </MenuItem>
            <MenuItem
              value="O"
              style={{
                fontSize: "14px",
              }}
            >
              Offer Old to New
            </MenuItem>
            <MenuItem
              value="HP"
              style={{
                fontSize: "14px",
              }}
            >
              Price High to Low
            </MenuItem>
            <MenuItem
              value="LP"
              style={{
                fontSize: "14px",
              }}
            >
              Price Low to High
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission( "sort", true)),
    updateSorting: (newValue) => {
      dispatch(updateSorting(newValue));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sort);
