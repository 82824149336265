import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_WEEK_NUMBER_REQUEST,
  GET_WEEK_NUMBER_SUCCESS,
  GET_WEEK_NUMBER_FAILURE,
  SELECTED_WEEK_NUMBERS,
  REMOVE_SELECTED_WEEK_NUMBERS,
  MIN_TO_MAX_SELECTED_WEEK_NUMBERS,
  REMOVE_ALL_SELECTED_WEEK_NUMBER,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getWeekNumberRequest = () => {
  return {
    type: GET_WEEK_NUMBER_REQUEST,
  };
};

export const getWeekNumberSuccess = (newValue) => {
  return {
    type: GET_WEEK_NUMBER_SUCCESS, 
    payload: newValue,
  };
};

export const getWeekNumberFailure = (error) => {
  return {
    type: GET_WEEK_NUMBER_FAILURE,
    payload: error,
  };
};

export const getSelectedWeekNumbers=(weekNumber)=>{
  return {
    type: SELECTED_WEEK_NUMBERS,
    payload: weekNumber,
  };
}

export const removeSelectedWeekNumbers=(weekNumber)=>{
  return {
    type: REMOVE_SELECTED_WEEK_NUMBERS,
    payload:weekNumber
  };
}

export const getMinToMaxSelectedWeekNumber=(selectedValues)=>{
  return{
    type:MIN_TO_MAX_SELECTED_WEEK_NUMBERS,
    payload:selectedValues
  }
}

export const removeAllSelectedWeekNumbers=()=>{
  return{
    type:REMOVE_ALL_SELECTED_WEEK_NUMBER,
    payload:[]
  }
}

export const getWeekNumberObject = (weekYear, loading = false) => {
  return (dispatch) => {
    dispatch(getWeekNumberRequest());
    ApiList.getWeekNumber(weekYear)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const weekNumberData = response.data;
        dispatch(getWeekNumberSuccess(weekNumberData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getWeekNumberFailure(error.message));
      });
  };
};
