import React, { Component } from "react";
import { Navbar, NavbarBrand, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import mainScreenLogo from "../../images/mainScreenLogo.svg";
import { flyersPageRender, updateFlyerDetail } from "../../store/actions/flyersAction";
import savedOfferButtonIcon from "../../images/vector.svg";
import navVector from "../../images/navVector.svg";
import navsaved from "../../images/navsaved.svg";
import navSavedSel from "../../images/navSavedSel.svg";
import CountryCityRetailer from "./CountryCityRetailer";
import Tooltip from "@mui/material/Tooltip";
import { savedOfferCount } from "../../store/actions/savedOfferAction";
import { Select } from "@mui/material";
import ApiCalls from "../ApiList/apiCalls";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import profilePopupStyles from "../../styling/profilePopupStyles";
import { updateProfileView } from "../../store/actions/profileAction";
import profileIcon from "../../images/profileIcon.svg";
import profileSelIcon from "../../images/profileSelIcon.svg";
import UpdateJson from "../CommonFiles/UpdateJson";
import whatsNew from "../../images/whats-new.svg";
import Dropdwon from "../../images/Dropdwon.svg";
import {
  updatePage,
  updateSavedFilterCount,
  updateSavedOfferCount,
} from "../../store/actions/userManagerAction";
import HelpIcon from "@mui/icons-material/Help";

const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const userData = JSON.parse(localStorage.getItem("userData"));
const ApiList = new ApiCalls();

class NavBar extends Component {
  static displayName = NavBar.name;

  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      savedFilterCount: 0,
      isSavedFilter: false,
      isSavedOffers: false,
      isProfile: false,
      isOffers: true,
      anchorEl: null,
      anchorHelpEl: null,
      selValue: "Offers",
    };
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleHelpClick = (event) => {
    this.setState({ anchorHelpEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleHelpClose = () => {
    this.setState({ anchorHelpEl: null });
  };
  handleLogout = () => {
    this.setState({
      isSavedFilter: false,
      isSavedOffers: false,
      isOffers: false,
      isProfile: true,
    });
    // localStorage.setItem("Flag", JSON.stringify(false));
    localStorage.setItem("userData", "{}");
    localStorage.removeItem("savedflag");
    localStorage.removeItem("listData");
    localStorage.removeItem("userFilterData");
    localStorage.removeItem("userPermissionData");
    window.location.href = "/logout";
  };

  componentDidMount() {
    if (window.location.pathname == "/savedfilter")
      this.setState({
        isSavedFilter: true,
        isSavedOffers: false,
        isOffers: false,
        isProfile: false,
      });
    else if (window.location.pathname == "/savedoffers")
      this.setState({
        isSavedFilter: false,
        isSavedOffers: true,
        isOffers: false,
        isProfile: false,
      });
    else if (window.location.pathname == "/profile")
      this.setState({
        isSavedFilter: false,
        isSavedOffers: false,
        isOffers: false,
        isProfile: true,
      });
    else
      this.setState({
        isSavedFilter: false,
        isSavedOffers: false,
        isOffers: true,
        isProfile: false,
      });
    if (window.location.pathname != "/") {
      ApiList.getSavedOfferCount()
        .then((response) => {
          const savedOfferCount = response.data.offerCount;
          this.props.updateSavedOfferCount(savedOfferCount);
          const newToken = response.headers.token;
          const userData = JSON.parse(localStorage.getItem("userData"));
          const newUserData = { ...userData, token: newToken };
          localStorage.setItem("userData", JSON.stringify(newUserData));
        })
        .catch((error) => {});
      ApiList.getSavedFilterCount()
        .then((response) => {
          this.props.updateSavedFilterCount(response.data.count);
          const newToken = response.headers.token;
          const userData = JSON.parse(localStorage.getItem("userData"));
          const newUserData = { ...userData, token: newToken };
          localStorage.setItem("userData", JSON.stringify(newUserData));
        })
        .catch((error) => {});
    }
    this.setState({
      selValue: window.location.pathname == "/flyers" ? "flyers" : "Offers",
    });
  }
  handleSwitchClick = () => {
    ApiList.toggleUserPermission().then((response) => {
      window.location.href =
        //"http://localhost:53133/Retailer/Retailer/OfferBank?ids=1";
        // https://analytics.clicflyer.com/
        "https://analytics.clicflyer.com//Retailer/Retailer/OfferBank?ids=1";
    });
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  handleChnage = ()=>{
    window.location.pathname === "/flyers"
      ? this.props.flyersPageRender(false)
      : this.props.flyersPageRender(true);
  }
  render() {
    const open = Boolean(this.state.anchorEl);
    const openHelp = Boolean(this.state.anchorHelpEl);
    const { offerCount, savedCount, page } = this.props;
    if (page == "offer") {
      this.setState({
        isSavedFilter: false,
        isSavedOffers: false,
        isOffers: true,
        isProfile: false,
        //selValue: "Offers",
      });
      this.props.updatePage("");
    }
    return (
      <>
        {window.location.pathname == "/" ||
        window.location.pathname == "/logout" ? null : (
          <header className="header-main">
            <Navbar
              className="navbar-expand-sm navbar-toggleable-sm ng-white "
              light
            >
              <div className="headerInner">
                <div className="hdrLeft d-flex align-items-center">
                  {window.location.href.toLowerCase().indexOf("whats-new") >
                    0 ||
                  window.location.href
                    .toLowerCase()
                    .indexOf("offer-bank-user-guide") > 0 ? (
                    <div className="main-screen-logo">
                      <img src={mainScreenLogo} />
                    </div>
                  ) : (
                    <NavbarBrand
                      className="text-light"
                      tag={Link}
                      to={"/offers"}
                      onClick={() => {
                        this.setState({
                          isSavedFilter: false,
                          isSavedOffers: false,
                          isOffers: true,
                          isProfile: false,
                          selValue: "Offers",
                        });
                        const customEvent = new CustomEvent("offerBankClick");
                        document.dispatchEvent(customEvent);
                      }}
                    >
                      <div className="main-screen-logo">
                        <img src={mainScreenLogo} />
                      </div>
                    </NavbarBrand>
                  )}

                  {window.location.href
                    .toLowerCase()
                    .indexOf("termsandconditions") < 0 &&
                    window.location.href.toLowerCase().indexOf("contact") < 0 &&
                    window.location.href
                      .toLowerCase()
                      .indexOf("useragreement") < 0 &&
                    window.location.href.toLowerCase().indexOf("whats-new") <
                      0 &&
                    window.location.href
                      .toLowerCase()
                      .indexOf("offer-bank-user-guide") < 0 && (
                      <div
                        className="offer-select-div"
                        style={{
                          visibility: this.state.isOffers
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        <Select
                          className="offer-select-button"
                          sx={{
                            "& .MuiSelect-select": {
                              border: "none",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#FFF",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={this.state.selValue}
                          //defaultValue={"Offers"}
                          style={{
                            backgroundColor: "#3E8D98",
                            borderRadius: "5px",
                            padding: "2px 0px 2px 6px",
                            color: "#FFF",
                            height: "30px",
                            border: "none",
                          }}
                          onChange={(ev) => {
                            ev.preventDefault();
                            UpdateJson([
                              {
                                key: "pageNo",
                                value: 1,
                              },
                              {
                                key: "sorting",
                                value: "N",
                              },
                              {
                                key: "pageSize",
                                value: 20,
                              },
                              {
                                key: "flyerbrandIds",
                                value: "",
                              },
                              { key: "isAttributeExclude", value: false },
                              {
                                key: "offerValidity",
                                value: "",
                              },
                            ]);
                            this.setState({ selValue: ev.target.value });
                            this.handleChnage();
                          }}
                        >
                          <MenuItem
                            style={{ textDecoration: "none" }}
                            to="/offers"
                            component={Link}
                            onClick={() => {
                              this.setState({
                                isSavedFilter: false,
                                isSavedOffers: false,
                                isOffers: true,
                                isProfile: false,
                              });
                            }}
                            value="Offers"
                          >
                            Offers
                          </MenuItem>

                          <MenuItem
                            style={{ textDecoration: "none" }}
                            component={Link}
                            to="/flyers"
                            value="flyers"
                            onClick={() => {
                              this.setState({
                                isSavedFilter: false,
                                isSavedOffers: false,
                                isOffers: true,
                                isProfile: false,
                              });
                            }}
                          >
                            Flyers
                          </MenuItem>
                        </Select>
                      </div>
                    )}
                </div>
                {window.location.href
                  .toLowerCase()
                  .indexOf("termsandconditions") < 0 &&
                  window.location.href.toLowerCase().indexOf("contact") < 0 &&
                  window.location.href.toLowerCase().indexOf("useragreement") <
                    0 &&
                  window.location.href.toLowerCase().indexOf("whats-new") < 0 &&
                  window.location.href
                    .toLowerCase()
                    .indexOf("offer-bank-user-guide") < 0 && (
                    <div className="hdrRight d-flex align-items-center">
                      <div
                        className="country-button-div"
                        style={{
                          visibility: this.state.isOffers
                            ? page != "compare"
                              ? "visible"
                              : "hidden"
                            : "hidden",
                        }}
                      >
                        <CountryCityRetailer />
                      </div>
                      <div className="saved-offer-div">
                        <NavLink
                          tag={Link}
                          className="text-light"
                          to="/savedoffers"
                        >
                          <Tooltip title="Saved Offers">
                            <Button
                              className="saved-offer-button"
                              variant="contained"
                              onClick={(ev) => {
                                if (this.state.isSavedOffers) {
                                  ev.preventDefault();
                                  return;
                                } else
                                  this.setState({
                                    isSavedFilter: false,
                                    isSavedOffers: true,
                                    isOffers: false,
                                    isProfile: false,
                                  });
                              }}
                              style={{
                                boxShadow: this.state.isSavedOffers
                                  ? "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
                                  : "none",
                                color: this.state.isSavedOffers
                                  ? "#FFFFFF"
                                  : "#3E8D98",
                                background: this.state.isSavedOffers
                                  ? "#3E8D98"
                                  : "#FFFFFF",
                              }}
                            >
                              <span style={{ fontSize: "12px" }}>
                                {`(${offerCount})`}&nbsp;
                                <img
                                  src={
                                    this.state.isSavedOffers
                                      ? navVector
                                      : savedOfferButtonIcon
                                  }
                                />
                              </span>
                            </Button>
                          </Tooltip>
                        </NavLink>
                      </div>
                      <div className="saved-filter-div">
                        <NavLink
                          tag={Link}
                          className="text-light"
                          to="/savedfilter"
                        >
                          <Tooltip title="Saved Filters">
                            <Button
                              className="saved-filter-button"
                              variant="contained"
                              onClick={(ev) => {
                                if (this.state.isSavedFilter) {
                                  ev.preventDefault();
                                  return;
                                } else
                                  this.setState({
                                    isSavedFilter: true,
                                    isSavedOffers: false,
                                    isOffers: false,
                                    isProfile: false,
                                  });
                              }}
                              style={{
                                boxShadow: this.state.isSavedFilter
                                  ? "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
                                  : "none",
                                background: this.state.isSavedFilter
                                  ? "#3E8D98"
                                  : "#FFFFFF",
                                color: this.state.isSavedFilter
                                  ? "#FFFFFF"
                                  : "#3E8D98",
                              }}
                            >
                              <span style={{ fontSize: "12px" }}>
                                {`(${savedCount})`}
                                &nbsp;
                                <img
                                  src={
                                    this.state.isSavedFilter
                                      ? navsaved
                                      : navSavedSel
                                  }
                                />
                                {/* <FilterAltIcon /> */}
                              </span>
                            </Button>
                          </Tooltip>
                        </NavLink>
                      </div>
                      <div>
                        <NavLink
                          tag={Link}
                          className="text-light"
                          style={{ textDecoration: "none" }}
                          to="/whats-new"
                          target="_blank"
                          // onClick={() => {
                          //   this.setState({
                          //     isSavedFilter: false,
                          //     isSavedOffers: false,
                          //     isOffers: false,
                          //     isProfile: false,
                          //   });
                          // }}
                        >
                          {/* <img
                            style={{
                              width: "18px",
                              marginRight: "10px",
                              marginBottom: "5px",
                            }}
                            src={whatsNew}
                          /> */}
                          <span style={{ color: "rgb(62, 141, 152)" }}>
                            What's New
                          </span>
                        </NavLink>
                      </div>

                      <div className="profile-icon-div">
                        <NavLink>
                          <Box sx={profilePopupStyles.profilePopupBoxStyling}>
                            {/* <Tooltip title="Account settings"> */}
                            <IconButton
                              onClick={this.handleHelpClick}
                              size="small"
                              aria-controls={
                                openHelp ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openHelp ? "true" : undefined}
                            >
                              <HelpIcon
                                style={{ color: "rgb(62, 141, 152)" }}
                              />
                              <img src={Dropdwon} style={{ width: "13px" }} />
                            </IconButton>
                            {/* </Tooltip> */}
                          </Box>
                          <Menu
                            sx={{
                              "& .MuiPaper-root": {
                                minWidth: "341px",
                                // minHeight: "100%",
                              },
                              "& .MuiList-root": {
                                background:
                                  " linear-gradient(112.31deg, #c5e5ca -1.22%, #bddde7 99.85%)",
                                minWidth: "341px",
                                minHeight: "100%",
                              },
                            }}
                            anchorEl={this.state.anchorHelpEl}
                            id="account-menu"
                            open={openHelp}
                            onClose={this.handleHelpClose}
                            onClick={this.handleHelpClose}
                            PaperProps={
                              profilePopupStyles.paperPropsSizeStyling
                            }
                            transformOrigin={
                              profilePopupStyles.transformOriginStyling
                            }
                            anchorOrigin={
                              profilePopupStyles.anchorOriginStyling
                            }
                            className="account-setting-pop-up"
                          >
                            <MenuItem
                              className="ProfilePopup"
                              style={{ cursor: "inherit", height: "40px" }}
                            >
                              <div>
                                <b>Resources</b>
                              </div>
                            </MenuItem>
                            <Link
                              to="/offer-bank-user-guide"
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                margin: "auto 0",
                              }}
                            >
                              <MenuItem
                                style={{
                                  height: "40px",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "#2B2B2B",
                                }}
                                // onClick={() => {
                                //   this.setState({
                                //     isSavedFilter: false,
                                //     isSavedOffers: false,
                                //     isOffers: false,
                                //     isProfile: false,
                                //   });
                                // }}
                              >
                                Offer Bank Documentation
                              </MenuItem>
                            </Link>
                            <Divider
                              sx={profilePopupStyles.divider}
                              className="ProfileDivider"
                            />
                            {/* <MenuItem
                              className="ProfilePopup"
                              style={{ cursor: "inherit", height: "40px" }}
                            >
                              <div>
                                <b>Support</b>
                                <br />
                              </div>
                            </MenuItem>
                            <MenuItem
                              style={{
                                height: "40px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#2B2B2B",
                              }}
                            >
                              Give Feedback
                            </MenuItem>
                            <MenuItem
                              style={{
                                height: "40px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#2B2B2B",
                              }}
                            >
                              Create a support ticket
                            </MenuItem> */}
                          </Menu>
                        </NavLink>
                      </div>

                      <div className="profile-icon-div">
                        <NavLink>
                          <Box sx={profilePopupStyles.profilePopupBoxStyling}>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={this.handleClick}
                                size="small"
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <img
                                  src={
                                    this.state.isProfile
                                      ? profileSelIcon
                                      : profileIcon
                                  }
                                />
                                <img src={Dropdwon} style={{ width: "13px" }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Menu
                            sx={{
                              "& .MuiPaper-root": {
                                minWidth: "341px",
                                // minHeight: "100%",
                              },
                              "& .MuiList-root": {
                                background:
                                  " linear-gradient(112.31deg, #c5e5ca -1.22%, #bddde7 99.85%)",
                                minWidth: "341px",
                                minHeight: "100%",
                              },
                            }}
                            anchorEl={this.state.anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={this.handleClose}
                            onClick={this.handleClose}
                            PaperProps={
                              profilePopupStyles.paperPropsSizeStyling
                            }
                            transformOrigin={
                              profilePopupStyles.transformOriginStyling
                            }
                            anchorOrigin={
                              profilePopupStyles.anchorOriginStyling
                            }
                            className="account-setting-pop-up"
                          >
                            <MenuItem
                              className="ProfilePopup"
                              style={{ cursor: "inherit" }}
                            >
                              <div>
                                <b>Bodmas</b>
                                <br />
                                <span
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "16px",
                                    color: "#757575",
                                  }}
                                >
                                  {userData && userData.userName}
                                </span>
                              </div>
                            </MenuItem>

                            <Divider
                              className="ProfileDivider"
                              sx={profilePopupStyles.divider}
                            />
                            <Link
                              to="/profile"
                              style={{
                                textDecoration: "none",
                                margin: "auto 0",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  this.setState({
                                    isSavedFilter: false,
                                    isSavedOffers: false,
                                    isOffers: false,
                                    isProfile: true,
                                  });
                                  this.props.updateProfileView(0);
                                }}
                                style={{
                                  height: "40px",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "#2B2B2B",
                                }}
                              >
                                Account Overview
                              </MenuItem>
                            </Link>
                            <Divider sx={profilePopupStyles.divider} />
                            <Link
                              to="/profile"
                              style={{
                                textDecoration: "none",
                                margin: "auto 0",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  this.setState({
                                    isSavedFilter: false,
                                    isSavedOffers: false,
                                    isOffers: false,
                                    isProfile: true,
                                  });
                                  this.props.updateProfileView(1);
                                }}
                                style={{
                                  height: "40px",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "#2B2B2B",
                                }}
                              >
                                My Subscription
                              </MenuItem>
                            </Link>
                            <Divider
                              sx={profilePopupStyles.divider}
                              className="ProfileDivider"
                              style={{
                                display:
                                  prmissionData &&
                                  prmissionData.isSwitchDisplayBtn
                                    ? ""
                                    : "none",
                              }}
                            />
                            <MenuItem
                              onClick={this.handleSwitchClick}
                              style={{
                                height: "40px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#2B2B2B",
                                display:
                                  prmissionData &&
                                  prmissionData.isSwitchDisplayBtn
                                    ? ""
                                    : "none",
                              }}
                            >
                              Switch to Old Offer Bank View
                            </MenuItem>

                            {/* <Divider sx={profilePopupStyles.divider} />
                            <Link
                              to="/profile"
                              style={{
                                textDecoration: "none",
                                margin: "auto 0",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  this.setState({
                                    isSavedFilter: false,
                                    isSavedOffers: false,
                                    isOffers: false,
                                    isProfile: true,
                                  });
                                  this.props.updateProfileView(2);
                                }}
                                style={{
                                  height: "40px",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "#2B2B2B",
                                }}
                              >
                                Change Password
                              </MenuItem>
                            </Link> */}
                            <Divider
                              sx={profilePopupStyles.divider}
                              className="ProfileDivider"
                            />
                            <MenuItem
                              onClick={this.handleLogout}
                              style={{
                                height: "40px",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#2B2B2B",
                              }}
                            >
                              Sign Out
                            </MenuItem>
                          </Menu>
                        </NavLink>
                      </div>
                    </div>
                  )}
              </div>
            </Navbar>
          </header>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    saveSearchDataCount: state.getSaveSearchData.savedSearchCount,
    flyerDetail: state.flyersPage.flyerDetailPage,
    profileView: state.getProfile.profileView,
    formData: state.filterForm,
    page: state.userManager.isPage,
    offerCount: state.userManager.savedOfferCount,
    savedCount: state.userManager.savedFilterCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFlyerDetail: (newValue) => dispatch(updateFlyerDetail(newValue)),
    savedOfferCount: (count) => dispatch(savedOfferCount(count)),
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
    updateSavedFilterCount: (newValue) =>
      dispatch(updateSavedFilterCount(newValue)),
    updatePage: (page) => dispatch(updatePage(page)),
    updateProfileView: (page) => dispatch(updateProfileView(page)),
    flyersPageRender: (value) => dispatch(flyersPageRender(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
