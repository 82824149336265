import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import {
  updateDescription,
  updateSearchName,
} from "../../store/actions/filterFormDataAction";
import { getSaveSearchByIdData } from "../../store/actions/getSaveSearchByIdAction";
import { postSaveSearchData } from "../../store/actions/saveSearchByUserAction";
import ApiCalls from "../ApiList/apiCalls";
import Autocomplete from "@mui/material/Autocomplete";
import { Paper } from "@mui/material";
import { withSnackbar } from "notistack";
import confirmIcon from "../../images/confirmIcon.svg";

const ApiList = new ApiCalls();

class SaveFilterShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      selectedUserList: [],
      id: "",
      message: "",
      hdnUserIdsData: "",
    };
  }

  handleShareSubmit = async (event) => {
    event.preventDefault();
    this.props.enqueueSnackbar("Filter shared Successfully", {
      variant: "success",
    });
    const postObj = {
      id: this.props.shareItemId,
      message: this.state.message,
      hdnUserIdsData: this.state.hdnUserIdsData,
    };
    ApiList.postSaveSearchShareById(postObj).then((response) => {
      const newToken = response.headers.token;
      const userData = JSON.parse(localStorage.getItem("userData"));
      const newUserData = { ...userData, token: newToken };
      localStorage.setItem("userData", JSON.stringify(newUserData));
    });
    this.props.handleEditClose();
    this.setState({ hdnUserIdsData: "" });
  };

  componentDidMount() {
    document.addEventListener("SahreData", this.onChange.bind(this));
  }
  onChange(ev) {
    this.setState({
      userList: ev.detail.data.shareUserModelList
        ? ev.detail.data.shareUserModelList
        : [],
    });
    let selected = ev.detail.data.selectedUserModelList;
    this.setState({ selectedUserList: selected ? selected : [] });
    this.setState({
      message: ev.detail.data.SaveSearchUserModel
        ? ev.detail.data.SaveSearchUserModel.SharedMessage
        : "",
    });
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.openEdit}>
          <div className="share-via-dialog-title">
            <span className="share-via-para">Share Via:</span>
            <span>
              <CloseIcon
                onClick={() => this.props.handleEditClose()}
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
              />
            </span>
          </div>
          <DialogContent>
            <div>
              <form required onSubmit={this.handleShareSubmit}>
                <div className="share-via-pop-up-container">
                  <Autocomplete
                    multiple
                    autoComplete={true}
                    size="small"
                    limitTags={1}
                    id="multiple-limit-tags"
                    options={this.state.userList}
                    value={this.state.selectedUserList}
                    renderOption={(props, option) => (
                      <li key={option.userLoginId} {...props}>
                        {option.name}
                      </li>
                    )}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(event, value) => {
                      const selectedValues = value
                        .map((selected) => selected.userLoginId)
                        .toString();
                      this.setState({
                        selectedUserList: value,
                        hdnUserIdsData: selectedValues,
                      });
                    }}
                    PaperComponent={(props) => (
                      <Paper
                        elevation={8}
                        {...props}
                        style={{
                          fontSize: "12px",
                          padding: "0px",
                          textTransform: "none",
                        }}
                      />
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: "16px", textTransform: "none" }}
                        {...params}
                        label="Select User"
                        required={!Boolean(this.state.hdnUserIdsData)}
                      />
                    )}
                  />
                  <br />
                  <div>
                    <span className="share-via-add-message">Add Message:</span>
                    <TextField
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      id="outlined-basic-description"
                      label=""
                      variant="outlined"
                      fullWidth
                      multiline={true}
                      rows={2}
                      value={this.state.message ? this.state.message : ""}
                      onChange={(event) => {
                        this.setState({ message: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <br />
                <button className="confirm-button" type="submit">
                  <img src={confirmIcon} className="confirm-button-icon" />
                  Confirm
                </button>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loadingByDataPermission: state.getDataByPermission.loading,
    formData: state.filterForm,
    SaveSearchIdData: state.getSaveSearchByIdData.SaveSearchIdData,
    SaveSearchShareData: state.getSaveSearchShareData.SaveSearchShareData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSaveSearchByIdData: (saveSearchId) =>
      dispatch(getSaveSearchByIdData(saveSearchId)),
    updateSearchName: (value) => dispatch(updateSearchName(value)),
    updateDescription: (value) => dispatch(updateDescription(value)),
    postSaveSearchData: (postObj) => dispatch(postSaveSearchData(postObj)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SaveFilterShare));
