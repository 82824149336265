import React, { Component } from "react";
import clicflyerLogo from "../../images/clicflyerLogo.svg";
import "./LoginPage.css";
import ApiCalls from "../ApiList/apiCalls";
import { LinearProgress } from "@mui/material";

const API_URL = process.env.REACT_APP_API_URL;
const ApiList = new ApiCalls();

class RedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      userinfo: [],
      isChecked: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const params = new URLSearchParams(window.location.search);
    const token = params.get("auth_token");
    if (token) {
      localStorage.clear();
      this.setState({ username: "", password: "" });
      const userData = {
        username: "test",
        password: "test",
        token: token,
      };
      ApiList.accountLogin(userData).then(
        (response) => {
          if (
            typeof response.data.token.status != "undefined" &&
            !response.data.token.status
          ) {
            
            // this.props.enqueueSnackbar(response.data.token.message, {
            //   variant: "error",
            // });
          } else {
            const userPermissionData = response.data.prmssn;
            localStorage.setItem(
              "userPermissionData",
              JSON.stringify(userPermissionData)
            );
            localStorage.setItem(
              "listData",
              JSON.stringify(response.data.ddnData)
            );
            if (this.state.isChecked === true) {
              localStorage.username = this.state.username;
              localStorage.password = this.state.password;
              localStorage.checkbox = this.state.isChecked;
            } else {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
              localStorage.removeItem("checkbox");
            }
            this.setState({ userinfo: response.data.token });
            const newUserData = {
              ...response.data.token,
              selectedCountryId: 0,
            };
            localStorage.setItem("userData", JSON.stringify(newUserData));
            localStorage.setItem("Flag", JSON.stringify(true));
            if (!response.data.token.isTnCStatus) {
              this.setState({ loading: false });
              window.location.href = "/useragreement";
            } else {
              this.setState({ loading: false });
              window.location.href = "/offers";
            }
          }
        },
        (error) => {
          // const message = "Incorrect Username or Password";
          // this.props.enqueueSnackbar(message, {
          //   variant: "error",
          // });
          this.setState({ loading: false });
        }
      );
    }
  
  }
  componentWillUnmount(){

  }
  render() {
    return (
      <>
        {/* <Loader open={this.state.loading} /> */}
        <section className="login-main-section">
          <div className="login-page-logo">
            <img src={clicflyerLogo}></img>
          </div>
          <div className="login-page-form">
            <p className="username-label">
              Starting ...... OfferBank Version 2
            </p>
            <LinearProgress style={{ marginTop: "70px" }} />
          </div>
        </section>
      </>
    );
  }
}
export default RedirectPage;
