import React, { Component } from "react";
import { Route, Router, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { SnackbarProvider } from "notistack";
import PrivateRoutes from "./utils/ProtectedRoutes";
import "./custom.css";

import Offers from "./components/Offers";
import AddtoCart from "./components/SavedOffers/AddtoCart";
import Login from "./components/Login/Login";
import SavedFilter from "./components/SavedFilter/SaveFilterTable";
import Subscription from "./components/UserProfile/Subscription";
import ComparePage from "./components/Compare/ComparePage";
import ForgetPassword from "./components/UserProfile/ForgetPassword";
import UserAgreement from "./components/UserAgreement";
import TnC from "./components/TnC";
import ContactUs from "./components/ContactUs/ContactUs";
import ProfilePage from "./components/UserProfile/ProfilePage";
import FlyersPage from "./components/Flyers/FlyersPage";
import RedirectPage from "./components/Login/RedirectPage";
import LogoutPage from "./components/Login/LogoutPage";
import WhatsNew from "./components/WhatsNew";
import UserGuide from "./components/UserGuide";
export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={1500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
          zIndex: "9999",
        }}
      >
        <Layout>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/offers" exact element={<Offers />} />
              <Route path="/flyers" exact element={<FlyersPage />} />
              <Route path="/savedfilter" exact element={<SavedFilter />} />
              <Route path="/subscription" exact element={<Subscription />} />
              <Route path="/savedoffers" exact element={<AddtoCart />} />
              <Route path="/compare" exact element={<ComparePage />} />
              <Route path="/useragreement" exact element={<UserAgreement />} />
              <Route path="/notification" exact element={<Login />} />
              <Route
                path="/changepassword"
                exact
                element={<ForgetPassword />}
              />
              <Route path="/profile" exact element={<ProfilePage />} />
              <Route path="/termsandconditions" exact element={<TnC />} />
              <Route path="/contact" exact element={<ContactUs />} />
              <Route path="/whats-new" exact element={<WhatsNew />} />
              <Route
                path="/offer-bank-user-guide"
                exact
                element={<UserGuide />}
              />
            </Route>
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/" element={<RedirectPage />} />
          </Routes>
        </Layout>
      </SnackbarProvider>
    );
  }
}
