import React, { Component } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { connect } from "react-redux";
import { getFlyerPages } from "../../store/actions/getFlyerPagesAction";
import DialogTitle from "@mui/material/DialogTitle";
import ImageListItem from "@mui/material/ImageListItem";
import flyerPageIcon from "../../images/flyerPageIcon.png";
import crossIcon from "../../images/crossIcon.png";
import { IconButton } from "rsuite";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import image from "../../images/cf.png";
import ApiCalls from "../ApiList/apiCalls";
const ApiList = new ApiCalls();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingTop: "0px",
  paddingButtom: "0px",
  borderRadius: "5px 5px 5px 5px",
};
// const Mapper = (imgUrl, coordinates) => {
//   const URL = imgUrl;
//   const MAPP = {
//     name: "my-map",
//     // GET JSON FROM BELOW URL AS AN EXAMPLE
//     areas: [
//       {
//         id: "469f9800-c45a-483f-b13e-bd24f3fb79f4",
//         //  "title": "Hardwood",
//         shape: "rect",
//         //"name": "1",
//         preFillColor: "#00ff194c",
//         strokeColor: "black",
//         // coords: [109, 42, 248, 137],
//         "coords":coordinates
//       },
//     ],
//   };

// return (
//   <ImageMapper
//     src={URL}
//     map={MAPP}
//     height={500}
//     width={360}
//     disabled={true}
//   />
// );
// };
class ImageSilderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      imageUrl: image,
      imgSrc:image,
      flyerName: "",
      flyersday: "",
      flyersmonth: "",
      flyereday: "",
      flyeremonth: "",
      flyerEndDate: "",
      flyerCurrectPage: "",
      totalFlyerPages: "",
      imagesArray: [],
    };
  }

  handleOpen = async () => {
    this.props.getFlyerPages(this.props.flyerId);
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    document.removeEventListener("flyerPageData", this.onFlyerPageData);
    document.addEventListener("flyerPageData", this.onFlyerPageData.bind(this));
  }
  onFlyerPageData(ev) {
    this.setState({ imagesArray: ev.detail.pageList });
    ev.detail.pageList.map((item) => {
      if (item.Id == this.props.flyerPageId) {
        this.setState({
          imageUrl: ApiList.getFlyerImageUrl() + item.FlyerImage,
          flyerCurrectPage: item.PageNo,
        });
      }
    });
    this.setState({
      flyerName: ev.detail.flyerData.Name_en,
      totalFlyerPages: ev.detail.pageList.length,
    });
    let sstr = ev.detail.flyerData.FlyerStartDate;
    let sdate = new Date(sstr);
    this.setState({
      flyersday: sdate.getDate(),
      flyersmonth: sdate.getMonth() + 1,
    });
    let estr = ev.detail.flyerData.FlyerEndDate;
    let edate = new Date(estr);
    this.setState({
      flyereday: edate.getDate(),
      flyeremonth: edate.getMonth() + 1,
    });
  }
  handlePrev = () => {
    let currentPage = this.state.flyerCurrectPage;
    let totalPage = this.state.totalFlyerPages;
    if (currentPage > 1) {
      currentPage = currentPage - 1;
    }
    this.setState({
      flyerCurrectPage: currentPage,
      imageUrl:
        ApiList.getFlyerImageUrl() +
        this.state.imagesArray[currentPage - 1].FlyerImage,
    });
  };
  handleNext = () => {
    let currentPage = this.state.flyerCurrectPage;
    let totalPage = this.state.totalFlyerPages;
    if (currentPage < totalPage) {
      currentPage = currentPage + 1;
    }
    this.setState({
      flyerCurrectPage: currentPage,
      imageUrl:
        ApiList.getFlyerImageUrl() +
        this.state.imagesArray[currentPage - 1].FlyerImage,
    });
  };
  render() {
    const isNextButtonDisabled =
      this.state.flyerCurrectPage === this.state.totalFlyerPages;
    const isPrevButtonDisabled = this.state.flyerCurrectPage === 1;
    if (JSON.stringify(this.props.flyerPageData) != "{}") {
      return (
        <div>
          <button
            className="offer-detail-flyers-page-combo-button"
            variant="outlined"
            color="primary"
            size="small"
            onClick={this.handleOpen}
          >
            <img
              src={flyerPageIcon}
              className="offer-detail-flyers-page-combo-button-icon"
              alt=""
            />
            &nbsp;
            <span className="offer-detail-flyers-page-combo-span">
              Flyer Page
            </span>
          </button>

          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="flyerpopup-box">
              <div
                style={{
                  height: "30px",
                  background:
                    "linear-gradient(90.49deg, #A7E8A5 -3.83%, #73C0CE 102.84%)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <DialogTitle className="flyerpopup-title">
                  <div
                    style={{
                      paddingTop: "0px",
                      display: "flex",
                      marginTop: "-12px",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <p>
                      {this.state.flyerName}&nbsp;&nbsp;({this.state.flyersday}/
                      {this.state.flyersmonth} - {this.state.flyereday}/
                      {this.state.flyeremonth})
                    </p>
                    <span className="flyerpopupclose-icon">
                      <span>
                        <img
                          style={{ cursor: "pointer" }}
                          src={crossIcon}
                          alt=""
                          onClick={() => {
                            this.handleClose();
                          }}
                        />
                      </span>
                    </span>
                  </div>
                </DialogTitle>
              </div>
              <ImageListItem
                style={{
                  hight: "100px",
                  width: "400px",
                  padding: "20px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                {/* {Mapper(
                  `${imagesList}?w=164&h=164&fit=crop&auto=format`,
                  flyersMapCoordinates
                )} */}
                <img
                  src={`${this.state.imageUrl}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${this.state.imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  //alt={item.title}
                  //loading="lazy"
                  alt=""
                />
              </ImageListItem>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "100px",
                    height: "26px",
                    fontFamily: "Roboto",
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "#004E58",
                    marginLeft: "20px",
                    marginTop: "6px",
                  }}
                >
                  Page {this.state.flyerCurrectPage}/
                  {this.state.totalFlyerPages}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    style={{
                      height: "26px",
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "14px",
                      marginLeft: "20px",
                      background: "none",
                      cursor: isPrevButtonDisabled ? "default" : "",
                      color: isPrevButtonDisabled ? "" : "#004E58",
                    }}
                    onClick={() => {
                      if (!isPrevButtonDisabled) this.handlePrev();
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                    <span
                      style={{
                        fontFamily: "Roboto",
                        height: "26px",
                        fontSize: "14px",
                        color: isPrevButtonDisabled ? "" : "#004E58",
                        marginTop: "6px",
                        cursor: isPrevButtonDisabled ? "default" : "",
                      }}
                    >
                      Prev
                    </span>
                  </IconButton>

                  <IconButton
                    style={{
                      height: "26px",
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      fontSize: "14px",
                      color: isNextButtonDisabled ? "" : "#004E58",
                      cursor: isNextButtonDisabled ? "default" : "",
                      background: "none",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      if (!isNextButtonDisabled) this.handleNext();
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Roboto",
                        height: "26px",
                        fontSize: "14px",
                        cursor: isNextButtonDisabled ? "default" : "",
                        marginTop: "6px",
                      }}
                    >
                      Next
                    </span>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      );
    }
    return (
      <button
        className="offer-detail-flyers-page-combo-button"
        variant="outlined"
        color="primary"
        size="small"
        onClick={this.handleOpen}
      >
        <img
          src={flyerPageIcon}
          className="offer-detail-flyers-page-combo-button-icon"
          alt=""
        />
        &nbsp;
        <span className="offer-detail-flyers-page-combo-span">Flyer Page</span>
      </button>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    flyerPageData: state.getFlyerPages.flyerData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFlyerPages: (flyerId) => dispatch(getFlyerPages(flyerId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageSilderPopup);
