import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  SEARCH_SUGGESTION_REQUEST,
  SEARCH_SUGGESTION_SUCCESS,
  SEARCH_SUGGESTION_FAILURE,
  SEARCH_SUGGESTION_FLAG,
} from "../actionTypes";

const ApiList = new ApiCalls();
export const searchSuggestionRequest = (searchString) => {
  return {
    type: SEARCH_SUGGESTION_REQUEST,
    payload: searchString,
  };
};

export const searchSuggestionSuccess = (searchString) => {
  return {
    type: SEARCH_SUGGESTION_SUCCESS,
    payload: searchString,
  };
};

export const searchSuggestionFailure = (error) => {
  return {
    type: SEARCH_SUGGESTION_FAILURE,
    payload: error,
  };
};
export const searchSuggestionFlagUpdate = (value) => {
  return {
    type: SEARCH_SUGGESTION_FLAG,
    payload: value,
  };
};

export const getSearchSuggestionData = (queryString) => {
  return (dispatch) => {
    dispatch(searchSuggestionRequest(queryString));
    ApiList.getSearchSuggestion(queryString)
      .then((response) => {
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
        const searchSuggestionData = response.data;
        const customEvent = new CustomEvent("searchSuggestionData", {
          detail: {
            searchSuggestionData: searchSuggestionData,
          },
        });
        document.dispatchEvent(customEvent);

        dispatch(searchSuggestionSuccess(searchSuggestionData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(searchSuggestionFailure(error.message));
      });
  };
};
