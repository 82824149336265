import axios from "axios";
import moment from "moment";
import { browserName, browserVersion } from "react-device-detect";

const API_URL = process.env.REACT_APP_API_URL;
const userData = JSON.parse(localStorage.getItem("userData"));

export const apiHeaders = () => {
  var userData = JSON.parse(localStorage.getItem("userData"));
  if (userData) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.token}`,
      userId: userData.userId,
      email: userData.emailId,
      companyId: userData.companyId,
      countryId: userData.countryId,
      selectedCountryId: userData.selectedCountryId,
      browser: browserName,
      version: browserVersion,
      device: `${navigator?.userAgentData?.platform} ${navigator?.platform}`,
    };
    return headers;
  }
  return null;
};

const headers = { headers: apiHeaders(userData) };
export default class ApiCalls {
  accountLogin(userData) {
    const url = `${API_URL}/api/Account/Login`;
    //const url = `https://localhost:7059/api/Account/Login`;
    return axios.post(url, userData);
  }
  getDataByPermission(userId, isTnC) {
    const url = `${API_URL}/api/GetAllDataByPermission/GetAllData?userId=${userId}&isTnC=${isTnC}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getOfferByUserPermission() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const url = `${API_URL}/api/GetOfferByUserPermission`;
    return axios.post(url, userFilterData, {
      headers: apiHeaders(),
    });
  }
  getSavedOfferObjectData(offerId) {
    const url = `${API_URL}/api/SaveOffer?OfferId=${offerId}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  exportSavedOfferObjectData(postObj) {
    const url = `${API_URL}/api/GetFlyerOffersExcel`;
    return axios.post(url, postObj, {
      headers: apiHeaders(),
    });
  }
  getSavedOfferCount() {
    const url = `${API_URL}/api/SaveOfferCount`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  //https://localhost:7059/api/GetSaveOffers/GetSavedOffers?sortType=5&sortOrder=N
  postSavedOfferObjectData(sortType, sortOrder) {
    const url = `${API_URL}/api/GetSaveOffers/GetSavedOffers?sortType=${sortType}&sortOrder=${sortOrder}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getSearchSuggestion(queryString) {
    const url = `${API_URL}/api/GetSearchSuggestion?searchText=${queryString}`;
    return axios.post(url, "", {
      headers: apiHeaders(),
    });
  }
  getOfferDetails(offerId) {
    const url = `${API_URL}/api/GetOfferDetail?offerid=${offerId}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  // removeSavedOfferObjectData(offerId) {
  //   const url = `${API_URL}/api/RemoveOffer?OfferId=${offerId}`;
  //   return axios.get(url, {
  //     headers: apiHeaders(),
  //   });
  // }
  removeSavedOfferObjectData(offerId) {
    const url = `${API_URL}/api/RemoveOffer`;
    return axios.post(
      url,
      { offerId: offerId.toString() },
      {
        headers: apiHeaders(),
      }
    );
  }
  getImageUrl() {
    const imgUrl =
      "https://clicflyercdnlive-vrz.azureedge.net/appimages/offers/";
    // "https://imagebloblive.blob.core.windows.net/appimages/offers/";
    return imgUrl;
  }
  getFlyerImageUrl() {
    const imgUrl =
      "https://clicflyercdnlive-vrz.azureedge.net/appimages/flyerpages/";
    // "https://imagebloblive.blob.core.windows.net/appimages/offers/";
    return imgUrl;
  }
  getRetailerImageUrl() {
    const logoUrl =
      "https://clicflyercdnlive-vrz.azureedge.net/appimages/retailers/";
    // " https://imagebloblive.blob.core.windows.net/appimages/retailers/";

    return logoUrl;
  }
  getFlyerPages(flyerId) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const requesModel = {
      FlyerId: flyerId,
      BrandIds: userFilterData?.flyerbrandIds,
    };
    const url = `${API_URL}/api/GetFlyerPages`;
    //?flyerid=${flyerId}&flyerpagno=0&flyerpageid=0&brandIds=${userFilterData?.flyerbrandIds}`;
    return axios.post(url, requesModel, {
      headers: apiHeaders(),
    });
  }
  getWeekYear() {
    const url = `${API_URL}/api/Calendar/GetDistinctYear`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getWeekNumber(weekYear) {
    const url = `${API_URL}/api/Calendar/GetWeekNumber?year=${weekYear}`;
    return axios.post(url, "", {
      headers: apiHeaders(),
    });
  }
  getFlyersPageData() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const url = `${API_URL}/api/Flyers/Flyers`;
    return axios.post(url, userFilterData, {
      headers: apiHeaders(),
    });
  }
  getFlyerDetails(flyerId) {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const requesModel = {
      FlyerId: flyerId,
      BrandIds: userFilterData?.flyerbrandIds,
    };
    const url = `${API_URL}/api/Flyers/GetFlyerPages`;
    return axios.post(url, requesModel, {
      headers: apiHeaders(),
    });
  }
  getNotification() {
    const url = `${API_URL}/api/Account/CheckProductNotification`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getNotificationHome() {
    const url = `${API_URL}/api/GetAllDataByPermission/CheckProductNotification`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  saveSearchByUser() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const url = `${API_URL}/api/SaveSearch/SaveSerachByUser`;
    return axios.post(url, userFilterData, {
      headers: apiHeaders(),
    });
  }
  getProfile(loginId) {
    const url = `${API_URL}/api/Account/MyProfile?userLoginId=${loginId}`;
    return axios.post(url, "", {
      headers: apiHeaders(),
    });
  }
  getSavedUserComment(postObj) {
    const url = `${API_URL}/api/Comment/UserComment`;
    return axios.post(url, postObj, {
      headers: apiHeaders(),
    });
  }
  userFilterPermission(postObj) {
    const url = `${API_URL}/api/FilterPermission/FilterPermission`;
    return axios.post(url, postObj, {
      headers: apiHeaders(),
    });
  }
  getAdditionalInfo(loginId) {
    const url = `${API_URL}/api/Account/GetUserAddtionalInfo?userLoginId=${loginId}`;
    return axios.post(url, "", {
      headers: apiHeaders(),
    });
  }
  getSaveSearch() {
    const url = `${API_URL}/api/SaveSearch/GetSaveSearch`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  deleteSaveSearch(saveSearchId, userId) {
    const url = `${API_URL}/api/SaveSearch/DeleteSavedSearchById?saveSearchId=${saveSearchId}&userId=${userId}`;
    return axios.post(url, "", {
      headers: apiHeaders(),
    });
  }
  getSaveSearchById(saveSearchId) {
    const url = `${API_URL}/api/SaveSearch/GetSavedSearchById?saveSearchId=${saveSearchId}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  updateSaveSearchById(postObj) {
    const url = `${API_URL}/api/SaveSearch/UpdateSaveSearchById`;
    return axios.post(url, postObj, {
      headers: apiHeaders(),
    });
  }
  getSavedSearchView(id) {
    const url = `${API_URL}/api/SaveSearch/GetSaveSearchView?id=${id}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getTermsandCondition() {
    const url = `${API_URL}/api/Comment/GetTermsAndConditions`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getDataExport(postObj) {
    const url = `${API_URL}/api/ExportToExcel/ExportToExcel`;
    return axios.post(
      url,
      postObj,
      { responseType: "blob" },
      {
        headers: apiHeaders(),
      }
    );
  }
  getSelectedDataExport(postObj) {
    const url = `${API_URL}/api/ExportToExcel/ExportSelectedOfferToExcel`;
    return axios.post(
      url,
      postObj,
      { responseType: "blob" },
      {
        headers: apiHeaders(),
      }
    );
  }
  getSavedSearchApply(id) {
    const url = `${API_URL}/api/SaveSearch/GetApplyFilter?id=${id}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getSavedSearchShareById(saveSearchId, companyId) {
    const url = `${API_URL}/api/SaveSearch/GetSavedSearchById?saveSearchId=${saveSearchId}&companyId=${companyId}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  postSaveSearchShareById(postObj) {
    const url = `${API_URL}/api/SaveSearch/ShareSaveSearchById`;
    return axios.post(url, postObj, {
      headers: apiHeaders(),
    });
  }
  resetPassword(postObj) {
    const url = `${API_URL}/api/Account/ResetPassword`;
    return axios.post(url, postObj, {
      headers: apiHeaders(),
    });
  }
  getProductData() {
    const url = `${API_URL}/api/Retailer/Welcome`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  getToggleOBFlag() {
    const url = `${API_URL}/api/Retailer/ToggleOBFlag`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }

  getPasswordCheck(password) {
    const url = `${API_URL}/api/Account/CheckUserPassword?password=${password}`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }

  getSavedFilterCount() {
    const url = `${API_URL}/api/SaveSearch/GetSaveSearchCount`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }
  toggleUserPermission() {
    const url = `${API_URL}/api/Account/toggleUserPermission`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }

  getBrands() {
    const url = `${API_URL}/api/Flyers/GetBrands`;
    return axios.get(url, {
      headers: apiHeaders(),
    });
  }

  getFlyerDataExport() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const url = `${API_URL}/api/Flyers/ExportFlyerData`;
    return axios.post(
      url,
      userFilterData,
      { responseType: "blob" },
      {
        headers: apiHeaders(),
      }
    );
  }
  getInitialState() {
    const permissionData = JSON.parse(
      localStorage.getItem("userPermissionData")
    );
    let validTo = new Date(permissionData.validTo);
    // Get today's date
    let today = new Date();
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    const initialState = {
      isCategorySelected: false,
      isCountrySelected: false,
      isSubCategorySelected: false,
      refineFiltersRenderCondition: 1,
      calendarView: true,
      userId: userData ? String(userData.userId) : "",
      saveSearchId: "0",
      countryId: userData
        ? Number(userData.selectedCountryId) > 0
          ? String(userData.selectedCountryId)
          : String(userData.defaultCountryId)
        : "",
      regionId: "",
      retailerIds: "",
      categoryIds: "",
      subCategoryIds: "",
      sorting: "N",
      toDate:
        validTo < today
          ? moment(validTo).format("DD/MM/YYYY")
          : moment(today).format("DD/MM/YYYY"),
      fromDate: moment(new Date(this.getPreviousDate())).format("DD/MM/YYYY"),
      cityIds: "",
      itemIds: "",
      brandIds: "",
      flyerbrandIds: "",
      promoMechIds: "",
      discounts: "",
      analyticsSearchText: "",
      analyticsSuggestionType: "",
      analyticsSuggestionId: "",
      analyticsSubCategoryId: "",
      suggestionText: "",
      packSize: "",
      promoMechanic: "",
      promoPriceMax: 0,
      promoPriceMin: 0,
      discountMax: 0,
      discountMin: 0,
      product: "",
      searchName: "String",
      description: "",
      ispackSize: false,
      SearchText: "",
      PackProdID: "1",
      callingscreen: "",
      type: "",
      LinkId: "",
      pageNo: 1,
      pageSize: 20,
      language: "",
      attribute: "",
      isShowDuplicate: false,
      isNavViewChange: 0,
      isproViewChange: 0,
      offerId: "",
      flyerSeg: "",
      isComapre: false,
      offerValidity: "",
      isAttributeExclude: false,
    };
    if (userFilterData) {
      return userFilterData;
    } else {
      return initialState;
    }
  }
  getPreviousDate = () => {
    const permissionData = JSON.parse(
      localStorage.getItem("userPermissionData")
    );
    let date = new Date(permissionData.validTo);
    const previousDate = new Date(date.getTime());
    previousDate.setDate(date.getDate() - 6);
    return previousDate;
  };

  getUserState() {
    const permissionData = JSON.parse(
      localStorage.getItem("userPermissionData")
    );
    let validTo = new Date(permissionData.validTo);
    // Get today's date
    let today = new Date();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const initialState = {
      isCategorySelected: false,
      isCountrySelected: false,
      isSubCategorySelected: false,
      refineFiltersRenderCondition: 1,
      calendarView: true,
      userId: userData ? String(userData.userId) : "",
      saveSearchId: "0",
      countryId: userData
        ? Number(userData.selectedCountryId) > 0
          ? String(userData.selectedCountryId)
          : String(userData.defaultCountryId)
        : "",
      regionId: "",
      retailerIds: "",
      categoryIds: "",
      subCategoryIds: "",
      sorting: "N",
      toDate:
        validTo < today
          ? moment(validTo).format("DD/MM/YYYY")
          : moment(today).format("DD/MM/YYYY"),
      fromDate: moment(new Date(this.getPreviousDate())).format("DD/MM/YYYY"),
      cityIds: "",
      itemIds: "",
      brandIds: "",
      flyerbrandIds: "",
      promoMechIds: "",
      discounts: "",
      analyticsSearchText: "",
      analyticsSuggestionType: "",
      analyticsSuggestionId: "",
      analyticsSubCategoryId: "",
      suggestionText: "",
      packSize: "",
      promoMechanic: "",
      promoPriceMax: 0,
      promoPriceMin: 0,
      discountMax: 0,
      discountMin: 0,
      product: "",
      searchName: "String",
      description: "",
      ispackSize: false,
      SearchText: "",
      PackProdID: "1",
      callingscreen: "",
      type: "",
      LinkId: "",
      pageNo: 1,
      pageSize: 20,
      language: "",
      attribute: "",
      isShowDuplicate: false,
      isNavViewChange: 0,
      isproViewChange: 0,
      offerId: "",
      flyerSeg: "",
      isComapre: false,
      offerValidity: "",
      isAttributeExclude: false,
    };
    return initialState;
  }
}
