import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_FLYER_PAGES_REQUEST,
  GET_FLYER_PAGES_SUCCESS,
  GET_FLYER_PAGES_FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const fetchFlyerPageDataRequest = () => {
  return {
    type: GET_FLYER_PAGES_REQUEST,
  };
};

export const fetchFlyerPageDataSuccess = (flyerData) => {
  return {
    type: GET_FLYER_PAGES_SUCCESS,
    payload: flyerData,
  };
};

export const fetchFlyerPageDataFailure = (error) => {
  return {
    type: GET_FLYER_PAGES_FAILURE,
    payload: error,
  };
};

export const getFlyerPages = (flyerId) => {
  return (dispatch) => {
    dispatch(fetchFlyerPageDataRequest);
    ApiList.getFlyerPages(flyerId)
      .then((response) => {
        const FlyerData = response.data;
        dispatch(fetchFlyerPageDataSuccess(FlyerData));
        const customEvent = new CustomEvent("flyerPageData", {
          detail: {
            pageList: FlyerData.flyerDisplayObj.flyerPagesList,
            flyerData: FlyerData.flyerDisplayObj.flyerDetailsbyId,
          },
        });
        document.dispatchEvent(customEvent);


        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(fetchFlyerPageDataFailure(error.message));
      });
  };
};
