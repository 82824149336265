import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { connect } from "react-redux";
import {
  getWeekNumberObject,
  removeAllSelectedWeekNumbers,
} from "../../store/actions/getWeekNumberAction";
import { updateSelectedYear } from "../../store/actions/getWeekYearAction";

class WeekYear extends Component {
  constructor(props) {
    super(props);
  }

  weekYear = () => {
    const elements = [];
    for (let index = 0; index < this.props.weekYearsList.length; index++) {
      const element = this.props.weekYearsList[index];
      elements.push(
        <FormControlLabel
          key={element}
          className="year-radio-button"
          value={element}
          control={<Radio />}
          label={element}
          checked={element == Number(this.props.selectedYear)}
        />
      );
    }
    return elements;
  };

  handleYearChange = (event) => {
    const { value } = event.target;
    this.props.updateSelectedYear(value);
    this.setState({ selectedYear: value });
    this.props.getWeekNumberObject(value);
    this.props.handleSelectedWeekNumer("");
    this.props.removeAllSelectedWeekNumbers();
  };

  render() {
    const { weekNumbers, selectedYear } = this.props;
    return (
      <>
        <section style={{ position: "relative", height: "222px" }}>
          <FormControl
            sx={{
              ml: 2,
            }}
          >
            <RadioGroup
              onChange={(event) => this.handleYearChange(event)}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              {this.weekYear()}
            </RadioGroup>
          </FormControl>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    weekNumbers: state.getWeekNumber.weekNumbers,
    selectedYear: state.getWeekYear.selectedYear,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWeekNumberObject: (newValue) => dispatch(getWeekNumberObject(newValue)),
    removeAllSelectedWeekNumbers: () =>
      dispatch(removeAllSelectedWeekNumbers()),
    updateSelectedYear: (newValue) => dispatch(updateSelectedYear(newValue)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WeekYear);
