import {
  OFFER_DATA_COPY,
  BRAND_DATA_COPY,
  PRODUCT_DATA_COPY,
} from "../actionTypes";

const initialState = {
  offerDataCopy: [],
  brandDataCopy: [],
  productDataCopy: [],
};

const getOfferByUserPermissionReducerCopy = (state = initialState, action) => {
  switch (action.type) {
    case OFFER_DATA_COPY:
      return {
        offerDataCopy: action.payload,
      };

    case BRAND_DATA_COPY:
      return {
        brandDataCopy: action.payload,
      };

    case PRODUCT_DATA_COPY:
      return {
        productDataCopy: action.payload,
      };

    default:
      return state;
  }
};

export default getOfferByUserPermissionReducerCopy;
