import React, { Component } from "react";
import clicflyerLogo from "../../images/clicflyerLogo.svg";
import "./LoginPage.css";
import ApiCalls from "../ApiList/apiCalls";
import { withSnackbar } from "notistack";
import Footer from "../CommonFiles/Footer";
import Loader from "../CommonFiles/Loader";

// const API_URL = process.env.REACT_APP_API_URL;
const ApiList = new ApiCalls();

class Login extends Component {
  static displayName = Login.name;
  constructor(props) {
    super(props);
    this.state = { username: "", password: "", userinfo: [], isChecked: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { notification: "", log: 0, flag: 0, loading: false };
  }

  onChangeCheckbox = (event) => {
    this.setState({
      isChecked: event.target.checked,
    });
  };
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("auth_token");
    if (token) {
      this.setState({ username: "", password: "" });
      const userData = {
        username: "test",
        password: "test",
        token: token,
      };
      ApiList.accountLogin(userData).then(
        (response) => {
          if (
            typeof response.data.token.status != "undefined" &&
            !response.data.token.status
          ) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(response.data.token.message, {
              variant: "error",
            });
          } else {
            const userPermissionData = response.data.prmssn;
            localStorage.setItem(
              "userPermissionData",
              JSON.stringify(userPermissionData)
            );
            localStorage.setItem(
              "listData",
              JSON.stringify(response.data.ddnData)
            );
            if (this.state.isChecked === true) {
              localStorage.username = this.state.username;
              localStorage.password = this.state.password;
              localStorage.checkbox = this.state.isChecked;
            } else {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
              localStorage.removeItem("checkbox");
            }
            this.setState({ userinfo: response.data.token });
            const newUserData = {
              ...response.data.token,
              selectedCountryId: 0,
            };
            localStorage.setItem("userData", JSON.stringify(newUserData));
            localStorage.setItem("Flag", JSON.stringify(true));
            if (!response.data.token.isTnCStatus) {
              this.setState({ loading: false });
              window.location.href = "/useragreement";
            } else {
              this.setState({ loading: false });
              window.location.href = "/offers";
            }
          }
        },
        (error) => {
          const message = "Incorrect Username or Password";
          this.props.enqueueSnackbar(message, {
            variant: "error",
          });
          this.setState({ loading: false });
        }
      );
    }
    if (localStorage.checkbox && localStorage.username !== "") {
      this.setState({
        isChecked: true,
        username: localStorage.username,
        password: localStorage.password,
      });
    } else if (localStorage.checkbox === "false") {
      localStorage.removeItem("isChecked");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
    }
    ApiList.getNotification().then((response) => {
      if (response.data.notification) {
        this.setState({
          notification: JSON.parse(response.data.notification),
        });
      } else {
        this.setState({ notification: response.data.notification });
      }
      const newToken = response.headers.token;
      const userData = JSON.parse(localStorage.getItem("userData"));
      const newUserData = { ...userData, token: newToken };
      localStorage.setItem("userData", JSON.stringify(newUserData));
    });
  }
  render() {
    const { notification } = this.state;
    return (
      <>
        <Loader open={this.state.loading} />
        {notification ? (
          <section className="notification-section">
            {notification.Message_En.indexOf("</") !== -1 ? (
              <div
                style={{
                  padding: "22px 29px",
                  height: "80vh",
                  overflowY: "auto",
                  backgroundColor: "#ECEFF4",
                }}
                dangerouslySetInnerHTML={{
                  __html: notification.Message_En.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            ) : (
              notification.Message_En
            )}
          </section>
        ) : (
          <section className="login-main-section">
            <div className="login-page-logo">
              <img src={clicflyerLogo} alt=""></img>
            </div>
            <div className="login-page-form">
              <form onSubmit={this.handleSubmit}>
                <p className="username-label">Username</p>
                <input
                  className="email-input-field"
                  name="username"
                  type="text"
                  placeholder="Enter Email ID"
                  required={true}
                  value={this.state.username ? this.state.username : ""}
                  onChange={(e) => {
                    this.setState({ username: e.target.value });
                  }}
                />

                <p className="password-label">Password</p>
                <input
                  className="password-input-field"
                  type="password"
                  name="password"
                  required={true}
                  value={this.state.password ? this.state.password : ""}
                  placeholder="Enter your password"
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                />
                <div style={{ marginTop: "19px" }}>
                  <input
                    type="checkbox"
                    checked={this.state.isChecked}
                    name="lsRememberMe"
                    onChange={this.onChangeCheckbox}
                  />
                  <label style={{ marginLeft: "5px" }}>Remember me</label>
                </div>
                <div className="login-button-container">
                  <button className="login-button" type="submit">
                    Log in
                  </button>
                </div>
              </form>
            </div>
            <Footer />
          </section>
        )}
      </>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const userData = {
      username: this.state.username,
      password: this.state.password,
      token: null,
    };
    this.setState({ flag: 1 });

    this.setState({ log: 0 });
    ApiList.accountLogin(userData).then(
      (response) => {
        if (
          typeof response.data.token.status != "undefined" &&
          !response.data.token.status
        ) {
          this.setState({ loading: false });
          this.props.enqueueSnackbar(response.data.token.message, {
            variant: "error",
          });
        } else {
          const userPermissionData = response.data.prmssn;
          localStorage.setItem(
            "userPermissionData",
            JSON.stringify(userPermissionData)
          );
          localStorage.setItem(
            "listData",
            JSON.stringify(response.data.ddnData)
          );
          if (this.state.isChecked === true) {
            localStorage.username = this.state.username;
            localStorage.password = this.state.password;
            localStorage.checkbox = this.state.isChecked;
          } else {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("checkbox");
          }
          this.setState({ userinfo: response.data.token });
          const newUserData = { ...response.data.token, selectedCountryId: 0 };
          localStorage.setItem("userData", JSON.stringify(newUserData));
          localStorage.setItem("Flag", JSON.stringify(true));
          if (!response.data.token.isTnCStatus) {
            this.setState({ loading: false });
            window.location.href = "/useragreement";
          } else {
            this.setState({ loading: false });
            window.location.href = "/offers";
          }
        }
      },
      (error) => {
        const message = "Incorrect Username or Password";
        this.props.enqueueSnackbar(message, {
          variant: "error",
        });
        this.setState({ loading: false });
      }
    );
  }
}
export default withSnackbar(Login);
