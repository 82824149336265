import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_WEEK_YEAR_REQUEST,
  GET_WEEK_YEAR_SUCCESS,
  GET_WEEK_YEAR_FAILURE,
  SELECTED_YEAR,
  
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getWeekYearRequest = () => {
  return {
    type: GET_WEEK_YEAR_REQUEST,
  };
};

export const getWeekYearSuccess = (offerDetails) => {
  return {
    type: GET_WEEK_YEAR_SUCCESS,
    payload: offerDetails,
  };
};

export const getWeekYearFailure = (error) => {
  return {
    type: GET_WEEK_YEAR_FAILURE,
    payload: error,
  };
};

export const updateSelectedYear = (year) => {
  return {
    type: SELECTED_YEAR,
    payload: year,
  };
};

export const getWeekYearObject = (offerId, loading = false) => {
  return (dispatch) => {
    dispatch(getWeekYearRequest());
    ApiList.getWeekYear(offerId)
      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const weekYearData = response.data;
        dispatch(getWeekYearSuccess(weekYearData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getWeekYearFailure(error.message));
      });
  };
};
