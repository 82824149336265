import {
  SAVED_OFFERS_COUNT,
  UPDATE_PAGE,
  SAVED_FILTER_COUNT,
} from "../actionTypes";

export const updateSavedOfferCount = (newValue) => {
  return {
    type: SAVED_OFFERS_COUNT,
    payload: newValue,
  };
};

export const updatePage = (newValue) => {
  return {
    type: UPDATE_PAGE,
    payload: newValue,
  };
};

export const updateSavedFilterCount = (newValue) => {
  return {
    type: SAVED_FILTER_COUNT,
    payload: newValue,
  };
};

