import React, { Component } from "react";
import clicflyerLogo from "../../images/clicflyerLogo.svg";
import "./LoginPage.css";


class LogoutPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    localStorage.clear();
    //window.close();
  }
  render() {
    return (
      <>
        <section className="login-main-section">
          <div className="login-page-logo">
            <img src={clicflyerLogo}></img>
          </div>
          <div className="login-page-form">
            <p className="username-label">
              You have been successfully logged out. Thank you for using our
              services.
            </p>
            <div style={{ marginTop: "20%", textAlign: "end" }}>
              <a
                style={{
                  color: "#208EDC",
                  cursor: "pointer",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                href={
                  "https://analytics.clicflyer.com/Retailer/Retailer/Welcome"
                }
              >
                Return to Home Page
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default LogoutPage;
