import React, { Component } from "react";
import { Paper } from "@mui/material";
import ApiCalls from "../ApiList/apiCalls";
const ApiList = new ApiCalls();

class ImageItem extends Component {
  render() {
    const { flyerPage } = this.props;
    return (
      <Paper>
        <img
          style={{ objectFit: "contain" }}
          alt=""
          src={`${ApiList.getFlyerImageUrl()}${flyerPage.FlyerImage}`}
        />
      </Paper>
    );
  }
}

export default ImageItem;
