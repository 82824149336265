import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import ImageDetailTable from "./ImageDetailTable";
import imageStyles from "../../styling/imageStyles";
import crossIcon from "../../images/crossIcon.png";
import addToCartIcon from "../../images/addToCart.svg";
import removeFromCart from "../../images/removeFromCart.svg";
import { connect } from "react-redux";
import { getFlyerPages } from "../../store/actions/getFlyerPagesAction";
import ImageSilderPopup from "./ImageSliderPopup";
import { getSavedOfferObject } from "../../store/actions/savedOfferAction";
import { removeSavedOfferObject } from "../../store/actions/removeSavedOfferAction";
import { getOfferDetailsObject } from "../../store/actions/offerDetailsAction";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import exportIcon from "../../images/exportIcon.svg";
import {
  compareOfferList,
  removeItemFromCompareOffersList,
} from "../../store/actions/compareOfferAction";
import { savedOfferCount } from "../../store/actions/savedOfferAction";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApiCalls from "../ApiList/apiCalls";
import LoadingButton from "@mui/lab/LoadingButton";
import newCompareIcon from "../../images/newCompareIcon.svg";
import newCompareIconBlank from "../../images/newCompareIconBlank.svg";
import { updateSavedOfferCount } from "../../store/actions/userManagerAction";
import Loader from "../CommonFiles/Loader";

const ApiList = new ApiCalls();
const userData = JSON.parse(localStorage.getItem("userData"));
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
class ImagePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      expanded: false,
      status: null,
      message: "",
      userId: userData.userId,
    };
  }
  componentDidMount() {
    this.setState({
      message: this.props.offerDetails.objOffferDetail.comments,
    });
    document.addEventListener("removecount", this.onChange.bind(this));
  }
  onChange(ev) {
    this.props.updateSavedOfferCount(ev.detail.removeCount);
  }
  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleExport = (itemId) => {
    ApiList.getSelectedDataExport({
      offerId: itemId.toString(),
    }).then((response) => {
      this.setState({ loading: false });
      const filename = response.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      // const newToken = response.headers.token;
      // const userData = JSON.parse(localStorage.getItem("userData"));
      // const newUserData = { ...userData, token: newToken };
      // localStorage.setItem("userData", JSON.stringify(newUserData));
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    ApiList.getSavedUserComment({
      offerId: this.props.offerDetails.objOffferDetail.id,
      comment: this.state.message,
    }).then((response) => {
      const message = ` Comment Added Successfully`;
      this.props.enqueueSnackbar(message, {
        variant: "success",
      });
      const newToken = response.headers.token;
      const userData = JSON.parse(localStorage.getItem("userData"));
      const newUserData = { ...userData, token: newToken };
      localStorage.setItem("userData", JSON.stringify(newUserData));
    });
  };

  render() {
    const {
      open,
      savedOffer,
      offerData,
      offerDetails,
      getSavedOfferObject,
      enqueueSnackbar,
      handleClose,
      formData,
      savedOfferDataSorting,
      cartOfferData,
      compareOfferData,
      savedOfferGrid,
      attribute,
    } = this.props;
    if (offerDetails) {
      const CompareDataIds = compareOfferData.map(
        (offer) => offer.id || offer.id
      );
      return (
        <>
          <Loader open={true} />
          <Dialog
            open={open}
            maxWidth="xl"
            sx={{
              "& .MuiDialogContent-root": {
                width: "920px",
                height: "auto",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                margin: "0 auto",
                padding: "0px 0px 8px 0px",
              },
            }}
          >
            <DialogTitle style={imageStyles.dialogTitleStyling}>
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#004E58",
                }}
              >
                {offerDetails.objOffferDetail.flyerName}
              </span>

              <span
                style={{
                  fontStyle: "normal",
                  marginLeft: "50%",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "16px",
                  visibility:
                    prmissionData && prmissionData.isAllowOfferLogs
                      ? "visible"
                      : "hidden",
                }}
              >
                <a
                  href={`http://adminpanel.clicflyer.com:92/Admin/offerv1/EditBulk/${offerDetails.objOffferDetail.offerImageId}?index=1`}
                  target="_blank"
                >
                  {`(${offerDetails.objOffferDetail.offerImageId})`}
                </a>
              </span>

              <span>
                <img
                  style={{ cursor: "pointer" }}
                  src={crossIcon}
                  onClick={() => {
                    handleClose(false);
                  }}
                />
              </span>
            </DialogTitle>
            <DialogContent>
              <Box sx={imageStyles.dialogContentBoxSize}>
                <section className="offer-detail-top-section">
                  <div
                    style={{
                      width: "362px",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        marginBottom: "10px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "#2B2B2B",
                      }}
                    >
                      {`Valid From ${offerDetails.objOffferDetail.flyerStartDate} To ${offerDetails.objOffferDetail.flyerEndDate}`}
                    </p>
                  </div>
                  {offerDetails.objRefineOffferList.length > 1 ? (
                    <div
                      style={{
                        width: "493px",
                        marginLeft: "20px",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "5px",
                          marginBottom: "10px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#2B2B2B",
                        }}
                      >
                        {`Total Offers: ${offerDetails.objRefineOffferList.length}`}
                      </p>
                    </div>
                  ) : null}
                </section>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <section
                    style={{
                      width: "338px",
                      height: "362px",
                      background: "#FFFFFF",
                      border: "1.26132px solid #E8E7E7",
                      margin: "0px 0px 14px 20px",
                    }}
                  >
                    <img
                      alt=""
                      style={imageStyles.gridImageStyling}
                      src={`${offerDetails.objOffferDetail.image}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${offerDetails.objOffferDetail.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    />
                  </section>

                  {offerDetails.objRefineOffferList.length > 1 ? (
                    <>
                      <section
                        style={{
                          width: "493px",
                          zIndex: "9999",
                          marginLeft: "20px",
                          position: "relative",
                          height: "280px",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {offerDetails.objRefineOffferList.map(
                          (offer, index) => (
                            <Accordion
                              sx={{ mb: 1 }}
                              expanded={
                                this.state.expanded === `panel${index + 1}`
                              }
                              key={index}
                              onChange={this.handleChange(`panel${index + 1}`)}
                              style={{
                                width: "493px",
                                left: "0px",
                                top: "0px",

                                background: "#FFFFFF",
                                border: "1px solid #e4e4e4",
                                borderRadius: "5px",
                                boxShadow:
                                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                              }}
                            >
                              <AccordionSummary
                                style={{
                                  //display: "flow",
                                  height: "64px",
                                  position: "relative",
                                }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <span
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    marginBottom: "0px",
                                    fontSize: "13px",
                                    lineHeight: "16px",
                                    width: "440px",
                                    color: "#2B2B2B",
                                    marginTop: "8px",
                                  }}
                                >
                                  {offer.brand +
                                    " " +
                                    offer.product +
                                    " " +
                                    offer.promoZones}
                                  <br />
                                  <p>
                                    <span
                                      style={{
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        color: "#373737",
                                      }}
                                    ></span>
                                    <span
                                      style={{
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        textDecorationLine: "line-through",
                                        color: "#A1A1A1",
                                        marginLeft: "20px",
                                      }}
                                    ></span>
                                  </p>
                                </span>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "42px",
                                    left: "15px",
                                  }}
                                >
                                  <p>
                                    {offerDetails.objOffferDetail.promoPrice ? (
                                      <>
                                        <span
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            color: "#373737",
                                          }}
                                        >
                                          {
                                            offerDetails.objOffferDetail
                                              .currency
                                          }
                                          &nbsp;
                                          {
                                            offerDetails.objOffferDetail
                                              .promoPrice
                                          }
                                        </span>
                                        <span
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            textDecorationLine: "line-through",
                                            color: "#A1A1A1",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          {
                                            offerDetails.objOffferDetail
                                              .currency
                                          }
                                          &nbsp;
                                          {
                                            offerDetails.objOffferDetail
                                              .regularPrice
                                          }
                                        </span>
                                      </>
                                    ) : null}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    position: "absolute",
                                    top: "36px",
                                    right: "70px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "13px",
                                      height: "24px",
                                      borderLeft: "solid 9px #FFF",
                                      borderBottom: "solid 12px transparent",
                                      borderTop: "solid 12px transparent",
                                      transform: "translate(12px, 0px)",
                                    }}
                                  ></div>
                                  {offerDetails.objOffferDetail.discount ? (
                                    <p
                                      style={{
                                        background: "#CC1E0D",
                                        width: "97px",
                                        height: "24px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          lineHeight: "16px",
                                          textAlign: "center",
                                          letterSpacing: "0.5px",
                                          color: "#FBF300",
                                          paddingLeft: "12px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {Math.ceil(
                                          offerDetails.objOffferDetail.discount
                                        )}
                                        % Off
                                      </span>
                                    </p>
                                  ) : null}
                                </div>
                              </AccordionSummary>

                              <AccordionDetails>
                                <ImageDetailTable
                                  offerRefineList={offer}
                                  imageStyles={imageStyles}
                                  offerDetails={offerDetails}
                                  dispAttribute={attribute}
                                />
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </section>
                      <section
                        style={{
                          position: "absolute",
                          bottom:
                            prmissionData && prmissionData.isAllowOfferLogs
                              ? "72px"
                              : "10px",
                          right:
                            prmissionData && prmissionData.isAllowOfferLogs
                              ? "47px"
                              : "36px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <LoadingButton
                            className="offer-detail-add-to-save-offer-combo-button"
                            sx={{
                              textTransform: "none",
                              "& .MuiButtonBase-root": {
                                width: "149px",
                              },
                            }}
                            loading={this.props.loadingByOfferDetail}
                            //loadingPosition="start"
                            variant="outlined"
                            style={{
                              border:
                                Number(
                                  offerDetails.objOffferDetail.shoppingCartId
                                ) === 0
                                  ? "1px solid #208EDC"
                                  : "1px solid #BB4F37",
                              color:
                                Number(
                                  offerDetails.objOffferDetail.shoppingCartId
                                ) === 0
                                  ? "#208EDC"
                                  : "#BB4F37",
                            }}
                            size="small"
                            onClick={async () => {
                              const condition =
                                Number(
                                  offerDetails.objOffferDetail.shoppingCartId
                                ) === 0;
                              if (condition) {
                                await getSavedOfferObject(
                                  offerDetails.objOffferDetail.id
                                );
                                this.props.getOfferByUserPermission(formData);
                                this.props.getOfferDetailsObject(
                                  offerDetails.objOffferDetail.id
                                );
                                await this.props.handleClickOpen(
                                  offerDetails.objOffferDetail.id
                                );
                              } else {
                                 await this.props.removeSavedOfferObject(
                                  offerDetails.objOffferDetail.id
                                );
                                this.props.getOfferByUserPermission(formData);
                                this.props.getOfferDetailsObject(
                                  offerDetails.objOffferDetail.id
                                );
                                await this.props.handleClickOpen(
                                  offerDetails.objOffferDetail.id
                                );
                                savedOfferGrid && handleClose(false);
                              }
                              const message = condition
                                ? `${offerDetails.objOffferDetail.nameEn} Added to Saved Offer Successfully`
                                : `${offerDetails.objOffferDetail.nameEn} Removed from Saved Offers.`;
                              enqueueSnackbar(message, {
                                variant: condition ? "success" : "error",
                              });
                            }}
                          >
                            {!this.props.loadingByOfferDetail ? (
                              <>
                                <img
                                  src={
                                    Number(
                                      offerDetails.objOffferDetail
                                        .shoppingCartId
                                    ) === 0
                                      ? addToCartIcon
                                      : removeFromCart
                                  }
                                  className="offer-detail-add-to-save-offer-combo-button-icon"
                                />
                                &nbsp;
                                <span
                                  className="offer-detail-add-to-save-offer-combo-span"
                                  style={{
                                    color:
                                      Number(
                                        offerDetails.objOffferDetail
                                          .shoppingCartId
                                      ) === 0
                                        ? "#208EDC"
                                        : "#BB4F37",
                                  }}
                                >
                                  {Number(
                                    offerDetails.objOffferDetail.shoppingCartId
                                  ) === 0
                                    ? "Add To Saved Offers"
                                    : "Remove Saved Offer"}
                                </span>
                              </>
                            ) : null}
                          </LoadingButton>

                          <ImageSilderPopup
                            flyerCoordinates={
                              offerDetails.objOffferDetail.offerImageCoord
                            }
                            flyerId={offerDetails.objOffferDetail.flyerId}
                            flyerPageId={
                              offerDetails.objOffferDetail.flyerPageId
                            }
                          />
                          {!savedOfferGrid ? (
                            <>
                              {prmissionData &&
                              prmissionData.isAllowOfferCompare ? (
                                <button
                                  className="offer-detail-compare-combo-button"
                                  style={{
                                    background: CompareDataIds.includes(
                                      offerDetails.objOffferDetail.id
                                    )
                                      ? "#208EDC"
                                      : "#FFF",
                                  }}
                                  onClick={async () => {
                                    if (
                                      !CompareDataIds.includes(
                                        offerDetails.objOffferDetail.id
                                      )
                                    ) {
                                      if (compareOfferData.length <= 3) {
                                        const currentItem =
                                          offerData.offerLibraryDisplays.find(
                                            (offer) =>
                                              offer.id ===
                                              offerDetails.objOffferDetail.id
                                          );

                                        this.props.compareOfferList(
                                          currentItem
                                        );
                                        const message = `${offerDetails.objOffferDetail.nameEn} Added to Compare Successfully`;

                                        enqueueSnackbar(message, {
                                          variant: "success",
                                        });
                                      } else {
                                        const message = `Can not select more than 4 items for compare`;
                                        enqueueSnackbar(message);
                                      }
                                    } else {
                                      this.props.removeItemFromCompareOffersList(
                                        offerDetails.objOffferDetail.id
                                      );
                                      const message = `${offerDetails.objOffferDetail.nameEn}Removed from Compare Offers`;

                                      enqueueSnackbar(message, {
                                        variant: "error",
                                      });
                                    }

                                    this.setState({
                                      selected: !this.state.selected,
                                    });
                                  }}
                                >
                                  <img
                                    className="offer-detail-compare-combo-button-icon"
                                    src={
                                      CompareDataIds.includes(
                                        offerDetails.objOffferDetail.id
                                      )
                                        ? newCompareIconBlank
                                        : newCompareIcon
                                    }
                                  />
                                  &nbsp;
                                  <span
                                    className="offer-detail-compare-combo-button-span"
                                    style={{
                                      color: CompareDataIds.includes(
                                        offerDetails.objOffferDetail.id
                                      )
                                        ? "#FFF"
                                        : "#208EDC",
                                    }}
                                  >
                                    Compare
                                  </span>
                                </button>
                              ) : null}
                            </>
                          ) : null}
                          {prmissionData && prmissionData.isExport ? (
                            <div
                              onClick={() => {
                                this.handleExport(
                                  offerDetails.objOffferDetail.id
                                );
                              }}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#0070C0",
                                backgroundColor: "white",
                                textTransform: "none",
                                border: "1px solid #208EDC",
                                borderRadius: "5px",
                                width: "84px",
                                height: "32px",
                                marginLeft: "18px",
                              }}
                              variant="contained"
                            >
                              <img
                                style={{ marginLeft: "12px" }}
                                src={exportIcon}
                              />
                              <span
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  color: "#208EDC",
                                  marginLeft: "6px",
                                }}
                              >
                                Export
                              </span>
                            </div>
                          ) : null}
                        </div>
                        {prmissionData && prmissionData.isAllowAddComment ? (
                          <form onSubmit={this.handleSubmit}>
                            <div
                              style={{
                                width: "493px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <input
                                type="text"
                                name="message"
                                placeholder="Enter your Description"
                                //required={true}
                                maxLength="40"
                                onChange={(e) => {
                                  this.setState({ message: e.target.value });
                                }}
                                value={this.state.message}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  padding: "6px 12px",
                                  width: "383px",
                                  height: "32px",
                                  left: "400px",
                                  top: "395px",
                                  border: "1px solid #757575",
                                  borderRadius: "5px",
                                  marginRight: "15px",
                                  fontSize: "12px",
                                }}
                              />
                              <button
                                type="submit"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "6px 12px",
                                  width: "95px",
                                  height: "32px",
                                  left: "798px",
                                  top: "397px",
                                  background: "#208EDC",
                                  border: "1px solid #208EDC",
                                  borderRadius: "5px",
                                }}
                              >
                                <span
                                  style={{
                                    width: "71px",
                                    height: "14px",
                                    fontFamily: "'Roboto'",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                    color: "#FFFFFF",
                                    flex: "none",
                                    order: 0,
                                    flexGrow: 0,
                                  }}
                                >
                                  Add/Update
                                </span>
                              </button>
                            </div>
                          </form>
                        ) : null}
                      </section>
                    </>
                  ) : (
                    <section style={{ width: "493px", margin: "0px 20px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          position: "relative",
                          marginBottom: "24px",
                          border: " 1px solid #AAAAAA",
                          borderRadius: "5px",
                          paddingTop: "11px",
                          paddingLeft: "20px",
                          minHeight: "60px",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "16px",
                              color: "#2B2B2B",
                              marginBottom: "0px",
                            }}
                          >
                            {offerDetails.objOffferDetail.nameEn}
                          </p>
                          <p>
                            <span
                              style={{
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#373737",
                              }}
                            >
                              {offerDetails.objOffferDetail.promoPrice
                                ? `${offerDetails.objOffferDetail.currency} ${offerDetails.objOffferDetail.promoPrice}`
                                : null}
                            </span>
                            <span
                              style={{
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "20px",
                                textDecorationLine: "line-through",
                                color: "#A1A1A1",
                                marginLeft: "20px",
                              }}
                            >
                              {offerDetails.objOffferDetail.promoPrice
                                ? `${offerDetails.objOffferDetail.currency} ${offerDetails.objOffferDetail.regularPrice}`
                                : null}
                            </span>
                          </p>
                        </div>
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              display: "flex",
                              position: "absolute",
                              top: "15px",
                              right: "70px",
                            }}
                          >
                            <div
                              style={{
                                width: "13px",
                                height: "24px",
                                borderLeft: "solid 9px #FFF",
                                borderBottom: "solid 12px transparent",
                                borderTop: "solid 12px transparent",
                                transform: "translate(12px, 0px)",
                              }}
                            ></div>
                            {offerDetails.objOffferDetail.discount ? (
                              <p
                                style={{
                                  background: "#CC1E0D",
                                  width: "97px",
                                  height: "24px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    lineHeight: "16px",
                                    textAlign: "center",
                                    letterSpacing: "0.5px",
                                    color: "#FBF300",
                                    paddingLeft: "12px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {Math.ceil(
                                    offerDetails.objOffferDetail.discount
                                  )}
                                  % Off
                                </span>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <ImageDetailTable
                        offerRefineList={offerDetails.objRefineOffferList[0]}
                        imageStyles={imageStyles}
                        offerDetails={offerDetails}
                        dispAttribute={attribute}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          width: "493px",
                        }}
                      >
                        <LoadingButton
                          className="offer-detail-add-to-save-offer-combo-button"
                          sx={{
                            textTransform: "none",
                            "& .MuiButtonBase-root": {
                              width: "149px",
                            },
                          }}
                          loading={this.props.loadingByOfferDetail}
                          //loadingPosition="start"
                          variant="outlined"
                          style={{
                            border:
                              Number(
                                offerDetails.objOffferDetail.shoppingCartId
                              ) === 0
                                ? "1px solid #208EDC"
                                : "1px solid #BB4F37",
                          }}
                          onClick={async () => {
                            const condition =
                              Number(
                                offerDetails.objOffferDetail.shoppingCartId
                              ) === 0;
                            if (condition) {
                              await getSavedOfferObject(
                                offerDetails.objOffferDetail.id
                              );
                              this.props.getOfferByUserPermission(formData);

                              this.props.getOfferDetailsObject(
                                offerDetails.objOffferDetail.id
                              );
                              await this.props.handleClickOpen(
                                offerDetails.objOffferDetail.id
                              );
                            } else {
                              await this.props.removeSavedOfferObject(
                                offerDetails.objOffferDetail.id
                              );
                              !savedOfferGrid && this.props.getOfferByUserPermission(
                                formData
                              );
                              !savedOfferGrid && this.props.getOfferDetailsObject(
                                offerDetails.objOffferDetail.id
                              );
                              !savedOfferGrid && await this.props.handleClickOpen(
                                offerDetails.objOffferDetail.id
                              );

                              savedOfferGrid && handleClose(false);
                            }
                            const message = condition
                              ? `${offerDetails.objOffferDetail.nameEn} Added to Saved Offer Successfully`
                              : `${offerDetails.objOffferDetail.nameEn} Removed from Saved Offers.`;
                            enqueueSnackbar(message, {
                              variant: condition ? "success" : "error",
                            });
                          }}
                        >
                          {!this.props.loadingByOfferDetail ? (
                            <>
                              <img
                                className="offer-detail-add-to-save-offer-combo-button-icon"
                                src={
                                  Number(
                                    offerDetails.objOffferDetail.shoppingCartId
                                  ) === 0
                                    ? addToCartIcon
                                    : removeFromCart
                                }
                              />
                              &nbsp;
                              <span
                                className="offer-detail-add-to-save-offer-combo-span"
                                style={{
                                  color:
                                    Number(
                                      offerDetails.objOffferDetail
                                        .shoppingCartId
                                    ) === 0
                                      ? "#208EDC"
                                      : "#BB4F37",
                                }}
                              >
                                {Number(
                                  offerDetails.objOffferDetail.shoppingCartId
                                ) === 0
                                  ? "Add To Saved Offers"
                                  : "Remove Saved Offer"}
                              </span>
                            </>
                          ) : null}
                        </LoadingButton>
                        <ImageSilderPopup
                          flyerCoordinates={
                            offerDetails.objOffferDetail.offerImageCoord
                          }
                          flyerId={offerDetails.objOffferDetail.flyerId}
                          flyerPageId={offerDetails.objOffferDetail.flyerPageId}
                        />

                        {!savedOfferGrid ? (
                          <>
                            {prmissionData &&
                            prmissionData.isAllowOfferCompare ? (
                              <button
                                className="offer-detail-compare-combo-button"
                                style={{
                                  background: CompareDataIds.includes(
                                    offerDetails.objOffferDetail.id
                                  )
                                    ? "#208EDC"
                                    : "#FFF",
                                }}
                                onClick={async () => {
                                  if (
                                    !CompareDataIds.includes(
                                      offerDetails.objOffferDetail.id
                                    )
                                  ) {
                                    if (compareOfferData.length <= 3) {
                                      const currentItem =
                                        offerData.offerLibraryDisplays.find(
                                          (offer) =>
                                            offer.id ===
                                            offerDetails.objOffferDetail.id
                                        );
                                      this.props.compareOfferList(currentItem);
                                      const message = `${offerDetails.objOffferDetail.nameEn} Added to Compare Successfully`;

                                      enqueueSnackbar(message, {
                                        variant: "success",
                                      });
                                    } else {
                                      const message = `Can not select more than 4 items for compare`;
                                      enqueueSnackbar(message);
                                    }
                                  } else {
                                    this.props.removeItemFromCompareOffersList(
                                      offerDetails.objOffferDetail.id
                                    );
                                    const message = `${offerDetails.objOffferDetail.nameEn}Removed from Compare Offers`;

                                    enqueueSnackbar(message, {
                                      variant: "error",
                                    });
                                  }

                                  this.setState({
                                    selected: !this.state.selected,
                                  });
                                }}
                              >
                                <img
                                  className="offer-detail-compare-combo-button-icon"
                                  src={
                                    CompareDataIds.includes(
                                      offerDetails.objOffferDetail.id
                                    )
                                      ? newCompareIconBlank
                                      : newCompareIcon
                                  }
                                />
                                &nbsp;
                                <span
                                  className="offer-detail-compare-combo-button-span"
                                  style={{
                                    color: CompareDataIds.includes(
                                      offerDetails.objOffferDetail.id
                                    )
                                      ? "#FFFFFF"
                                      : "#208EDC",
                                  }}
                                >
                                  Compare
                                </span>
                              </button>
                            ) : null}
                          </>
                        ) : null}

                        {offerDetails ? (
                          <>
                            {prmissionData && prmissionData.isExport ? (
                              <div
                                onClick={() => {
                                  this.handleExport(
                                    offerDetails.objOffferDetail.id
                                  );
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  color: "#0070C0",
                                  backgroundColor: "white",
                                  textTransform: "none",
                                  border: "1px solid #0070C0",
                                  borderRadius: "5px",
                                  width: "84px",
                                  height: "32px",
                                  marginLeft: "18px",
                                }}
                                variant="contained"
                              >
                                <img
                                  style={{ marginLeft: "12px" }}
                                  src={exportIcon}
                                />
                                <span
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#208EDC",
                                    marginLeft: "6px",
                                  }}
                                >
                                  Export
                                </span>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                      {prmissionData && prmissionData.isAllowAddComment ? (
                        <form onSubmit={this.handleSubmit}>
                          <div
                            style={{
                              width: "493px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <input
                              type="text"
                              name="message"
                              placeholder="Enter your Description"
                              maxLength="40"
                              onChange={(e) => {
                                this.setState({ message: e.target.value });
                              }}
                              value={this.state.message}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "6px 12px",
                                width: "383px",
                                height: "32px",
                                border: "1px solid #757575",
                                borderRadius: "5px",
                                marginRight: "15px",
                                fontSize: "12px",
                              }}
                            />
                            <button
                              type="submit"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "6px 12px",
                                width: "95px",
                                height: "32px",
                                left: "798px",
                                top: "397px",
                                background: "#208EDC",
                                border: "1px solid #208EDC",
                                borderRadius: "5px",
                              }}
                            >
                              <span
                                style={{
                                  width: "71px",
                                  height: "14px",
                                  fontFamily: "'Roboto'",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  lineHeight: "14px",
                                  color: "#FFFFFF",
                                  flex: "none",
                                  order: 0,
                                  flexGrow: 0,
                                }}
                              >
                                Add/Update
                              </span>
                            </button>
                          </div>
                        </form>
                      ) : null}
                    </section>
                  )}
                </section>
                {prmissionData && prmissionData.isAllowOfferLogs ? (
                  <section>
                    <Box style={imageStyles.boxStyling}>
                      <div style={{ display: "flex" }}>
                        <p style={imageStyles.offerInfoPara1}>
                          <strong>Offer Created</strong>
                          <br />
                          on: {offerDetails.objOffferDetail.createdOn}
                          <br />
                          By: {offerDetails.objOffferDetail.createdBy}
                        </p>

                        <p style={imageStyles.offerInfoPara2}>
                          <strong>Offer Updated</strong>
                          <br />
                          on: {offerDetails.objOffferDetail.updatedOn}
                          <br />
                          By: {offerDetails.objOffferDetail.updatedBy}
                        </p>
                      </div>
                    </Box>
                  </section>
                ) : null}
              </Box>
            </DialogContent>
          </Dialog>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    offerData: state.userPermissionOfferData.imageData,
    flyerPageData: state.getFlyerPages.flyerData,
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
    compareOfferData: state.compareOffer.compareOfferList,
    loadingByOfferDetail: state.getOfferDetails.loading,
    //offerLibraryDisplays
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFlyerPages: (flyerId) => dispatch(getFlyerPages(flyerId)),
    getSavedOfferObject: (itemId) => dispatch(getSavedOfferObject(itemId)),
    removeSavedOfferObject: (itemId) =>
      dispatch(removeSavedOfferObject(itemId)),
    getOfferDetailsObject: (offerId) =>
      dispatch(getOfferDetailsObject(offerId, true)),
    getOfferByUserPermission: (postObj) =>
      dispatch(getOfferByUserPermission(postObj)),
    postSavedOfferObject: (sortType, sortOrder) =>
      dispatch(postSavedOfferObject(sortType, sortOrder)),
    savedOfferCount: (count) => dispatch(savedOfferCount(count)),
    compareOfferList: (offerItem) => dispatch(compareOfferList(offerItem)),
    removeItemFromCompareOffersList: (offer) =>
      dispatch(removeItemFromCompareOffersList(offer)),
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImagePopUp);
