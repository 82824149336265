import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import {
  GET_APPLY_SAVED_FILTER_REQUEST,
  GET_APPLY_SAVED_FILTER_SUCCESS,
  GET_APPLY_SAVED_FILTER_FAILURE,
  GET_APPLY_SAVED_FLAG,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const fetchApplyFilterDataRequest = () => {
  return {
    type: GET_APPLY_SAVED_FILTER_REQUEST,
  };
};

export const fetchApplyFilterDataSuccess = (applyFilterData) => {
  return {
    type: GET_APPLY_SAVED_FILTER_SUCCESS,
    payload: applyFilterData,
  };
};

export const fetchApplyFilterDataFailure = (error) => {
  return {
    type: GET_APPLY_SAVED_FILTER_FAILURE,
    payload: error,
  };
};
export const applyFilterDataFlag = (value) => {
  return {
    type: GET_APPLY_SAVED_FLAG,
    payload: value,
  };
};

export const getApplyFilterData = (id) => {
  return (dispatch) => {
    dispatch(fetchApplyFilterDataRequest);

    ApiList.getSavedSearchApply(id)

      .then((response) => {
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const ApplySavedFilterData = response.data;

        dispatch(fetchApplyFilterDataSuccess(ApplySavedFilterData));
         const customEvent = new CustomEvent("apply", {
           detail: { ApplySavedFilterData },
         });
         document.dispatchEvent(customEvent);
      })
      .catch((error) => {
        dispatch(fetchApplyFilterDataFailure(error.message));
      });
  };
};
