import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_FLYER_DETAILS_REQUEST,
  GET_FLYER_DETAILS_SUCCESS,
  GET_FLYER_DETAILS_FAILURE,
  UPDATE_FLYER_DETAILS_CURRENT_PAGE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getFlyerDetailsRequest = () => {
  return {
    type: GET_FLYER_DETAILS_REQUEST,
  };
};

export const getFlyerDetailsSuccess = (offerDetails) => {
  return {
    type: GET_FLYER_DETAILS_SUCCESS,
    payload: offerDetails,
  };
};

export const getFlyerDetailsFailure = (error) => {
  return {
    type: GET_FLYER_DETAILS_FAILURE,
    payload: error,
  };
};




export const updateFlyerDetailsCurrentPage = (currentPageNumber) => {
  return {
    type: UPDATE_FLYER_DETAILS_CURRENT_PAGE,
    payload: currentPageNumber,
  };
};


export const getFlyerDetailsObject = (flyerId, loading = false) => {
  return (dispatch) => {
    dispatch(getFlyerDetailsRequest());
    ApiList.getFlyerDetails(flyerId)
      .then((response) => {
        const flyerDetailsData = response.data;
        dispatch(getFlyerDetailsSuccess(flyerDetailsData));
      var ar = [];
      ar.push(
        ...(flyerDetailsData?.flyerDisplayObj?.flyerPagesList || []).map(
          (item) => {
            return item.PageNo;
          }
        )
      );
        const customEvent = new CustomEvent("pageOnFLyer", {
          detail: {
            pageList: ar,
          },
        });
        document.dispatchEvent(customEvent);
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getFlyerDetailsFailure(error.message));
      });
  };
};
