import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_SAVE_SEARCH_VIEW_REQUEST,
  GET_SAVE_SEARCH_VIEW_SUCCESS,
  GET_SAVE_SEARCH_VIEW_FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const fetchSavedSearchViewDataRequest = () => {
  return {
    type: GET_SAVE_SEARCH_VIEW_REQUEST,
  };
};

export const fetchSavedSearchViewDataSuccess = (savedViewData) => {
  return {
    type: GET_SAVE_SEARCH_VIEW_SUCCESS,
    payload: savedViewData,
  };
};

export const fetchSavedSearchViewDataFailure = (error) => {
  return {
    type: GET_SAVE_SEARCH_VIEW_FAILURE,
    payload: error,
  };
};

export const getSavedSearchViewData = (id) => {
  return (dispatch) => {
    dispatch(fetchSavedSearchViewDataRequest);
    ApiList.getSavedSearchView(id)
      .then((response) => {
        debugger;
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const SavedSearchViewData = response.data;
        dispatch(fetchSavedSearchViewDataSuccess(SavedSearchViewData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(fetchSavedSearchViewDataFailure(error.message));
      });
  };
};
