import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_SAVED_OFFER_OBJECT_REQUEST,
  GET_SAVED_OFFER_OBJECT_SUCCESS,
  GET_SAVED_OFFER_OBJECT_FAILURE,
  SELECTED_OFFER_ITEMS,
  CLEAR_SELECTED_OFFER_ITEMS,
  REMOVE_SELECTED_OFFER_ITEMS,
  SELECT_ALL_OFFERS,
  REMOVE_ALL_OFFERS,
  SAVED_OFFERS_COUNT,
  UPDATE_SAVED_OFFER_SORTING,
} from "../actionTypes";

const ApiList = new ApiCalls();

export const getSavedOfferRequest = () => {
  return {
    type: GET_SAVED_OFFER_OBJECT_REQUEST,
  };
};

export const getSavedOfferSuccess = (savedOfferObject) => {
  return {
    type: GET_SAVED_OFFER_OBJECT_SUCCESS,
    payload: savedOfferObject,
  };
};

export const getSavedOfferFailure = (error) => {
  return {
    type: GET_SAVED_OFFER_OBJECT_FAILURE,
    payload: error,
  };
};

export const selectedOfferItems = (offerItem) => {
  return {
    type: SELECTED_OFFER_ITEMS,
    payload: offerItem,
  };
};

export const removeSelectedOfferItem = (offerItem) => {
  return {
    type: REMOVE_SELECTED_OFFER_ITEMS,
    payload: offerItem,
  };
};

export const clearSelectedOfferItems = () => {
  return {
    type: CLEAR_SELECTED_OFFER_ITEMS,
  };
};

export const selectAllOffers = (offerData) => {
  return {
    type: SELECT_ALL_OFFERS,
    payload: offerData,
  };
};

export const removeAllOffers = () => {
  return {
    type: REMOVE_ALL_OFFERS,
  };
};

export const savedOfferCount = (saveOfferCount) => {
  return {
    type: SAVED_OFFERS_COUNT,
    payload: saveOfferCount,
  };
};

export const updateSavedOfferSorting = (sortingValue) => {
  return {
    type: UPDATE_SAVED_OFFER_SORTING,
    payload: sortingValue,
  };
};

export const getSavedOfferObject = (itemId, loading = false) => {
  return (dispatch) => {
    if (loading) {
      dispatch(getSavedOfferRequest());
    }
    ApiList.getSavedOfferObjectData(itemId)
      .then((response) => {
        debugger;
         const newToken = response.headers.token;
         const userData = JSON.parse(localStorage.getItem("userData"));
         const newUserData = { ...userData, token: newToken };
         localStorage.setItem("userData", JSON.stringify(newUserData));
        const savedOfferObjectData = response.data;
         const customEvent = new CustomEvent("removecount", {
           detail: {
             removeCount: savedOfferObjectData.saveOfferObj.saveOfferCount,
           },
         });
         document.dispatchEvent(customEvent);
        dispatch(getSavedOfferSuccess(savedOfferObjectData));
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getSavedOfferFailure(error.message));
      });
  };
};
