import React, { Component } from "react";
import AddToSaveOffer from "./AddToSaveOffer";
import Duplicate from "./Duplicate";
import Share from "./ExportShare";
import ViewBy from "./ViewBy";
import Sort from "./Sort";
import Show from "./Show";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import additionalFilterStyles from "../../styling/additionalFilterStyles";
import { updateView } from "../../store/actions/offerImageActions";
import SelectAllOffers from "./SelectAllOffers";
import gridViewButtonIconBlack from "../../images/gridViewButtonIconBlack.svg";
import gridViewButtonIconGrey from "../../images/gridViewButtonIconGrey.svg";
import listViewButtonIconGrey from "../../images/listViewButtonIconGrey.svg";
import listViewButtonIconBlack from "../../images/listViewButtonIconBlack.svg";
import Tooltip from "@mui/material/Tooltip";
import "./AdditionalFilter.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import OfferValidity from "./OfferValidity";

const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

class AdditionalFilters extends Component {
  render() {
    const { offerData, view, handlePageNumber } = this.props;
      return (
        <section className="additional-filter-container">
          <Grid container>
            <Grid item xs={5} className="additional-filters-first-grid">
              <ToggleButtonGroup
                sx={{
                  height: "30px",
                }}
                size="small"
                color="primary"
                exclusive
                aria-label="Platform"
              >
                <Tooltip title="Grid View">
                  <ToggleButton
                    value=""
                    sx={{
                      backgroundColor: !view ? "rgba(0, 0, 0, 0.08);" : "#FFF",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.props.updateView(0);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        !view ? gridViewButtonIconBlack : gridViewButtonIconGrey
                      }
                    />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="List View">
                  <ToggleButton
                    value=""
                    sx={{
                      backgroundColor: view ? "rgba(0, 0, 0, 0.08);" : "#FFF",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const customEvent = new CustomEvent("gridView", {});
                      document.dispatchEvent(customEvent);
                      this.props.updateView(1);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        view ? listViewButtonIconBlack : listViewButtonIconGrey
                      }
                    />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
              {!this.props.view ? (
                <SelectAllOffers />
              ) : (
                <div
                  style={{
                    width: this.props.view ? "" : "22px",
                    marginLeft: "1rem",
                  }}
                ></div>
              )}
              {!this.props.view ? (
                <div
                  style={{
                    width: "75px",
                  }}
                >
                  <ViewBy />
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: ".25%",
                  }}
                ></div>
              )}
              {!this.props.view ? (
                <div
                  style={{
                    marginLeft: ".25%",
                    width: "104px",
                  }}
                >
                  <Duplicate />
                </div>
              ) : (
                <div
                  style={{
                    maxWidth: "104px",
                    width: this.props.view ? "" : "100%",
                    marginLeft: ".25%",
                  }}
                ></div>
              )}
              <div
                style={{
                  minWidth: "142px",
                  marginBottom: "4px",
                  marginLeft: "8px",
                  visibility: prmissionData?.isValidity ? "visible" : "hidden",
                }}
              >
                <OfferValidity view={this.props.view} />
              </div>
            </Grid>
            <Grid item xs={2} className="additional-filters-second-grid">
              <sub
              className="total-records-para"
              >
                Total Records:&nbsp;
                {offerData && offerData[0]?.totalRecords}
              </sub>
            </Grid>
            <Grid item xs={5} className="additional-filters-third-grid">
              {prmissionData && prmissionData.isExport ? (
                ""
              ) : (
                <div
                  style={{
                    maxWidth: "92px",
                    width: "100%",
                  }}
                ></div>
              )}
              <div style={{ minWidth: "142px", marginLeft: "8.5%" }}>
                <Sort view={this.props.view} />
              </div>
              <div style={{ marginLeft: "2%", marginRight: "1%" }}>
                <Show view={this.props.view} />
              </div>
              {prmissionData && prmissionData.isExport ? <Share /> : null}
              <AddToSaveOffer />
            </Grid>
          </Grid>
        </section>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.userPermissionOfferData.loading,
    offerData: state.userPermissionOfferData?.imageData?.offerLibraryDisplays,
    view: state.userPermissionOfferData.view,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateView: (newValue) => {
      dispatch(updateView(newValue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFilters);
