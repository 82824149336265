import React, { Component } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import weekNumberIconSvg from "../../images/weekNumberIconSvg.svg";
import calendarIconSvg from "../../images/calendarIconSvg.svg";
import { updateCalendarView } from "../../store/actions/filterFormDataAction";
import Tooltip from "@mui/material/Tooltip";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import UpdateJson from "../CommonFiles/UpdateJson";
import CalendarView from "./CalendarView";
import WeekNumberView from "./WeekNumberView";

const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

const { allowedRange } = DateRangePicker;

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = { calendarView: true };
  }
  componentDidMount() {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (userFilterData) {
      this.setState({ calendarView: userFilterData.calendarView });
    }
  }

  handleChange = (e) => {
    this.setState({ calendarView: e.target.value });
    UpdateJson([{ key: "calendarView", value: e.target.value }]);
  };

  render() {
    return (
      <>
        <div className="calendar-main-section">
          <>
            {prmissionData && prmissionData.isAllowPromoWeek ? (
              <FormControl
                sx={{
                  height: "30px",
                  transform: "none",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                size="small"
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.calendarView}
                  onChange={this.handleChange}
                >
                  <MenuItem sx={{ fontSize: "12px" }} value={true}>
                    <Tooltip title="Calendar" placement="right">
                      <img src={calendarIconSvg} />
                    </Tooltip>
                  </MenuItem>

                  <MenuItem sx={{ fontSize: "12px" }} value={false}>
                    <Tooltip title="Weeknumber" placement="right">
                      <img src={weekNumberIconSvg} />
                    </Tooltip>
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <img src={calendarIconSvg} />
            )}
          </>
        </div>
        <CalendarView flag={this.state.calendarView} />
        <WeekNumberView flag={this.state.calendarView} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    calendarView: state.filterForm.calendarView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCalendarView: (newValue) => dispatch(updateCalendarView(newValue)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
