import {
  EXPORT_SAVED_OFFER_REQUEST,
  EXPORT_SAVED_OFFER_SUCCESS,
  EXPORT_SAVED_OFFER_FAILURE,
  EXPORT_OFFERS,
  EXPORT_SAVED_OFFERS,
  REMOVE_ITEM_FROM_EXPORT_OFFERS_LIST,
  Remove_ITEM_FROM_EXPORT_SAVED_OFFERS_LIST,
  SELECT_ALL_OFFERS_FOR_EXPORT,
  SELECT_ALL_SAVED_OFFERS_FOR_EXPORT,
  REMOVE_ALL_OFFERS_FROM_EXPORT_LIST,
  REMOVE_ALL_SAVED_OFFERS_FROM_EXPORT_LIST,
} from "../actionTypes";

const initialState = {
  loading: false,
  exportOfferList: [],
  exportSavedOffersList: [],
  error: "",
};

const exportSavedOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_SAVED_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case EXPORT_SAVED_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case EXPORT_SAVED_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EXPORT_OFFERS:
      const itemsForExportOfferList = new Set([
        ...state.exportOfferList,
        action.payload,
      ]);
      return {
        ...state,
        exportOfferList: [...itemsForExportOfferList],
      };

    case REMOVE_ITEM_FROM_EXPORT_OFFERS_LIST:
      const index = [...state.exportOfferList].indexOf(action.payload);
      let selectedOffersListForExport = [...state.exportOfferList];
      selectedOffersListForExport.splice(index, 1);
      return {
        ...state,
        exportOfferList: selectedOffersListForExport,
      };

    case EXPORT_SAVED_OFFERS:
      const itemsForExportSavedOffersList = new Set([
        ...state.exportSavedOffersList,
        action.payload,
      ]);
      return {
        ...state,
        exportSavedOffersList: [...itemsForExportSavedOffersList],
      };

    case Remove_ITEM_FROM_EXPORT_SAVED_OFFERS_LIST:
      const indexofItem = [...state.exportSavedOffersList].indexOf(
        action.payload
      );
      let selectedSavedOffersListForExport = [...state.exportSavedOffersList];
      selectedSavedOffersListForExport.splice(indexofItem, 1);
      return {
        ...state,
        exportSavedOffersList: selectedSavedOffersListForExport,
      };

    case SELECT_ALL_OFFERS_FOR_EXPORT:
      return {
        ...state,
        exportOfferList: action.payload,
      };

    case SELECT_ALL_SAVED_OFFERS_FOR_EXPORT:
      return {
        ...state,
        exportSavedOffersList: action.payload,
      };

    case REMOVE_ALL_OFFERS_FROM_EXPORT_LIST:
      return {
        ...state,
        exportOfferList: [],
      };

    case REMOVE_ALL_SAVED_OFFERS_FROM_EXPORT_LIST:
      return {
        ...state,
        exportSavedOffersList:[]
      };

    default:
      return state;
  }
};

export default exportSavedOfferReducer;
