import React, { Component } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import {
  updatePageNumber,
  updatePageSize,
} from "../../store/actions/filterFormDataAction";
import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import UpdateJson from "../CommonFiles/UpdateJson";

class Show extends Component {
  constructor(props) {
    super(props);
    this.state={
      selValue:20,
    };
  }
  handleChange = (event) => {
    //const { value } = event.target.value;
    this.setState({ selValue: event.target.value });
       if (event.target.value !== 20) {
         UpdateJson([
           {
             key: "pageNo",
             value: 1,
           },
         ]);
       }
       UpdateJson([
         {
           key: "pageSize",
           value: event.target.value,
         },
       ]);
    const customEvent = new CustomEvent("pageSizeChange", {
      detail: {
        pageSize: event.target.value,
       // pageNo: userFilterData.pageNo,
      },
    });
    document.dispatchEvent(customEvent);
 
    this.props.getOfferByUserPermission();
  };
  componentDidMount(){
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    this.setState({ value: userFilterData.pageSize });
  }
  render() {
    return (
      <FormControl
        fullWidth
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputBase-root": {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            color: "#757474",
          },
        }}
      >
        <Select
          className="show-page-size"
          displayEmpty
          size="small"
          value={this.state.selValue}
          onChange={this.handleChange}
        >
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePageNumber: (newValue) => dispatch(updatePageNumber(newValue)),
    updatePageSize: (newValue) => dispatch(updatePageSize(newValue)),
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission("entry", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Show);
