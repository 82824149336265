import {
  GET_ADDITIONAL_INFO_REQUEST,
  GET_ADDITIONAL_INFO_SUCCESS,
  GET_ADDITIONAL_INFO_FAILURE,
} from "../actionTypes";

const initialState = {
  loading: true,
  additionalData: {},
  error: "",
};

const getAdditionalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDITIONAL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADDITIONAL_INFO_SUCCESS:
      return {
        loading: false,
        additionalData: action.payload,
        error: "",
      };
    case GET_ADDITIONAL_INFO_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getAdditionalInfoReducer;
