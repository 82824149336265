import {
  GET_OFFER_DETAILS_REQUEST,
  GET_OFFER_DETAILS_SUCCESS,
  GET_OFFER_DETAILS_FAILURE,
  IMAGE_POP_UP_OPEN,
} from "../actionTypes";

const initialState = {
  offerDetails: {},
  loading: false,
  error: "",
  imagePopUpOpen:false
};

const getOfferDetailsObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        offerDetails: action.payload,
        error: "",
      };

    case GET_OFFER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        offerDetails: {},
        error: action.payload,
      };

    case IMAGE_POP_UP_OPEN:
        return {
          ...state,
          imagePopUpOpen:action.payload,
        };  

    default:
      return state;
  }
};

export default getOfferDetailsObjectReducer;
