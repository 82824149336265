import React, { Component } from "react";
import { connect } from "react-redux";
import CartPreference from "./CartPreference";
import CartSort from "./CartSort";
import Delete from "./DeleteCart";
import { savedOfferCount } from "../../store/actions/savedOfferAction";
import SelectAllOffersCart from "./SelectAllOffersCart";
import TitlebarImageList from "./CartListDisplay";
import ApiCalls from "../ApiList/apiCalls";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import "./SavedOffer.css";
import { Grid } from "@mui/material";
import exportButtonIcon from "../../images/exportButtonIcon.svg";

import exportIcon from "../../images/exportIcon.svg";
import UnauthorisedUser from "../CommonFiles/Unauthorised";
import { updateSavedOfferCount } from "../../store/actions/userManagerAction";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));
const ApiList = new ApiCalls();

class AddtoCart extends Component {
  static displayName = AddtoCart.name;
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.postSavedOfferObject(this.props.savedOfferDataSorting);
    document.addEventListener("removecount", this.onChange.bind(this));
  }
  onChange(ev) {
    this.props.updateSavedOfferCount(ev.detail.removeCount);
  }
  handleExportSavedOffer = () => {
    const selectedSavedOfferIds = this.props.exportSavedOfferItems
      .map((OfferItem) => OfferItem.id)
      .toString();
    ApiList.getSelectedDataExport({
      offerId: selectedSavedOfferIds,
    }).then((response) => {
      UnauthorisedUser(response.status);
      this.setState({ loading: false });
      const filename = response.headers["content-disposition"]
        .split(";")[1]
        .split("=")[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      //const newToken = response.headers.token;
      //const userData = JSON.parse(localStorage.getItem("userData"));
      //const newUserData = { ...userData, token: newToken };
      //localStorage.setItem("userData", JSON.stringify(newUserData));
    });
  };
  render() {
    const { cartOfferData, exportSavedOfferItems, savedCount } = this.props;
    if (JSON.stringify(cartOfferData) != "{}") {
      let savedOffer = [];
      cartOfferData?.map((item) =>
        item.savedOffers.map((offer) => savedOffer.push(offer))
      );
      return (
        <>
          <section className="saved-offer-filters-main-section">
            <Grid container>
              <Grid
                item
                xs={5}
                className="saved-offer-filters-first-grid-style"
              >
                <SelectAllOffersCart />

                <CartPreference />
                {prmissionData && prmissionData.isExport ? (
                  <button
                    disabled={!exportSavedOfferItems.length}
                    onClick={() => {
                      exportSavedOfferItems.length
                        ? this.handleExportSavedOffer()
                        : console.log("");
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      position: "relative",
                      width: "100px",
                      height: "30px",
                      cursor: exportSavedOfferItems.length
                        ? "pointer"
                        : "inherit",
                      color: exportSavedOfferItems.length
                        ? "#0070C0"
                        : "#C4C4C4",
                      backgroundColor: "#FFF",
                      textTransform: "none",
                      border: exportSavedOfferItems.length
                        ? "1px solid #0070C0"
                        : "1px solid #C4C4C4",
                      borderRadius: "5px",
                      padding: "0px",
                    }}
                    variant="contained"
                  >
                    <img
                      src={
                        exportSavedOfferItems.length
                          ? exportIcon
                          : exportButtonIcon
                      }
                      alt=""
                    />
                    <span
                      style={{
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 500,
                        color: "#757474",
                        marginLeft: "8px",
                        color: exportSavedOfferItems.length
                          ? "#2E75B6"
                          : "#C4C4C4",
                      }}
                    >
                      Export
                    </span>
                  </button>
                ) : null}
              </Grid>
              <Grid item xs={3}>
                <span className="saved-offer-total-count">
                  Total Records :&nbsp;{savedOffer.length}
                </span>
              </Grid>
              <Grid
                item
                xs={4}
                className="saved-offer-filters-third-grid-style"
              >
                <CartSort />

                <Delete data={exportSavedOfferItems} />
              </Grid>
            </Grid>
          </section>
          <TitlebarImageList />
        </>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
    exportSavedOfferItems: state.exportSavedOffer.exportSavedOffersList,
    savedCount: state.savedOfferData.savedOfferCount,
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    savedOfferCount: (count) => dispatch(savedOfferCount(count)),
    postSavedOfferObject: (obj) => dispatch(postSavedOfferObject(obj, false)),
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddtoCart);
