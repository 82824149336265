import React, { Component } from "react";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import imageStyles from "../../styling/imageStyles";
const prmissionData = JSON.parse(localStorage.getItem("userPermissionData"));

class ImageDetailTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { offerDetails, offerRefineList, dispAttribute } = this.props;
    if (offerDetails) {
      return (
        <Table size="small" sx={{ width: "100%" }}>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ borderRadius: "5px 0px 0px 0px" }}
                style={imageStyles.thHeading}
              >
                Retailer
              </TableCell>
              <TableCell style={imageStyles.tbody}>
                {offerDetails.objOffferDetail.retailerNameEn}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={imageStyles.thHeading}
                component="th"
                scope="row"
              >
                Brand
              </TableCell>
              <TableCell style={imageStyles.tbody}>
                {offerRefineList?.brand}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={imageStyles.thHeading}
                component="th"
                scope="row"
              >
                Product
              </TableCell>
              <TableCell style={imageStyles.tbody}>
                {offerRefineList?.product}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={imageStyles.thHeading}
                component="th"
                scope="row"
              >
                Packsize
              </TableCell>
              <TableCell style={imageStyles.tbody}>
                {offerRefineList?.promoZones}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={imageStyles.thHeading}
                component="th"
                scope="row"
              >
                Offer Mechanic
              </TableCell>
              <TableCell style={imageStyles.tbody}>
                {offerRefineList?.promoMechanic}
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                display: prmissionData?.isAllowZones ? "" : "none",
              }}
            >
              <TableCell
                style={imageStyles.thHeading}
                component="th"
                scope="row"
              >
                Zone Coverage
              </TableCell>
              <TableCell style={imageStyles.tbody}>
                {offerDetails.objOffferDetail.promoZone}
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                display: prmissionData?.isAllowAttribute ? "" : "none",
              }}
            >
              <TableCell
                style={imageStyles.thHeading}
                component="th"
                scope="row"
              >
                Attributes
              </TableCell>
              <TableCell style={imageStyles.tbody}>{dispAttribute}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    return null;
  }
}

export default ImageDetailTable;
