import React, { Component } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import { connect } from "react-redux";
import { updateSavedOfferSorting } from "../../store/actions/savedOfferAction";

class CartSort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val: "L",
    };
  }

  
  render() {
    return (
      <FormControl
        sx={{
          marginLeft: "26.5%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiSelect-select": {
            height: "30px",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            color: "#757474",
          },
        }}
      >
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disabled={!this.props.cartOfferData.length}
          value={this.props.savedOfferDataSorting.sortOrder}
          onChange={(event) => {
            this.setState({ val: event.target.value });
            this.props.updateSavedOfferSorting({
              ...this.props.savedOfferDataSorting,
              sortOrder: event.target.value,
            });
            this.props.postSavedOfferObject(
              {
                ...this.props.savedOfferDataSorting,
                sortOrder: event.target.value,
              },
              true
            );
          }}
        >
          {this.props.savedOfferDataSorting.sortType == 5 && (
            <MenuItem sx={{ fontSize: "13px" }} value="L">
              Latest Offer
            </MenuItem>
          )}
          <MenuItem sx={{ fontSize: "13px" }} value="N">
            Offer New to Old
          </MenuItem>
          <MenuItem sx={{ fontSize: "13px" }} value="O">
            Offer old to New
          </MenuItem>
          <MenuItem sx={{ fontSize: "13px" }} value="HP">
            Price High to Low
          </MenuItem>
          <MenuItem sx={{ fontSize: "13px" }} value="LP">
            Price Low to High
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postSavedOfferObject: (sortType, sortOrder) =>
      dispatch(postSavedOfferObject(sortType, sortOrder)),

    updateSavedOfferSorting: (sorting) =>
      dispatch(updateSavedOfferSorting(sorting)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartSort);
