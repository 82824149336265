import React, { Component } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import ApiCalls from "../ApiList/apiCalls";
import { getSaveSearchData } from "../../store/actions/getSaveSearchAction";
import deleteIcon from "../../images/deleteIcon.svg";
import savedfilterdisable from "../../images/savedfilterdisable.svg";
import shareIcon from "../../images/shareIcon.svg";
import view from "../../images/view.svg";
import applyFiltersvg from "../../images/applyFiltersvg.svg";
import deletedisabled from "../../images/deletedisabled.svg";
import SaveFilterView from "./SavedSearchView";
import SavedFilterPagination from "./PaginationView";
import { withSnackbar } from "notistack";
import { savedOfferCount } from "../../store/actions/savedOfferAction";
import { getSavedSearchViewData } from "../../store/actions/savedSearchViewAction";
import {
  applyFilterDataFlag,
  getApplyFilterData,
} from "../../store/actions/applySavedFilterAction";
import savedBarFilterStyles from "../../styling/savedBarFilterStyles";
import "./savedFilterTable.css";
import SaveFilterShare from "./SaveFilterShare";
import { getSaveSearchShareData } from "../../store/actions/getSaveSearchShareAction";
import savedFilterViewHeaderIcon from "../../images/savedFilterViewHeaderIcon.svg";
import savedFilterShareHeaderIcon from "../../images/savedFilterShareHeaderIcon.svg";
import savedFilterDeleteHeaderIcon from "../../images/savedFilterDeleteHeaderIcon.svg";
import { Grid, Tooltip } from "@mui/material";
import UpdateJson from "../CommonFiles/UpdateJson";
import ExpiredRoundIcon from "@rsuite/icons/ExpiredRound";
import moment from "moment";

const ApiList = new ApiCalls();
const userData = JSON.parse(localStorage.getItem("userData"));
const permissionData = JSON.parse(localStorage.getItem("userPermissionData"));
class SavedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      open: false,
      deleteResponse: "",
      openEdit: false,
      searchByIdData: {},
      currentPage: 1,
      newTableRows: [],
      page: 1,
      shareItemId: 0,
      isExpired: false,
    };
  }
  componentDidMount() {
    this.props.getSaveSearchData();
    document.addEventListener("apply", this.onStateChange.bind(this));
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleSearch = (searchValue) => {
    this.setState({ searchText: searchValue });
  };

  handleClickOpenEdit = async (itemId) => {
    await this.props.getSaveSearchShareData(itemId, 1);
    this.setState({ shareItemId: itemId });
    this.setState({ openEdit: true });
  };

  handleClick = async (id, isExpired) => {
    this.setState({ isExpired: isExpired });
    this.props.getApplyFilterData(id);
  };
  getPreviousDate = (date = new Date()) => {
    const previousDate = new Date(date.getTime());
    previousDate.setDate(date.getDate() - 6);
    return previousDate;
  };
  //For Apply Filter
  onStateChange(ev) {
    const applyFilterData = ev.detail.ApplySavedFilterData;
    UpdateJson([
      { key: "cityIds", value: applyFilterData.cityIdsJson },
      { key: "retailerIds", value: applyFilterData.retailerIdsJson },
      { key: "categoryIds", value: applyFilterData.categoryIdsJson },
      { key: "subCategoryIds", value: applyFilterData.subCategoryIdsJson },
      { key: "itemIds", value: applyFilterData.itemIdsJson },
      { key: "product", value: applyFilterData.productIdsJson },
      { key: "brandIds", value: applyFilterData.brandIdsJson },
      { key: "packSize", value: applyFilterData.packSizeJson },
      { key: "promoMechanic", value: applyFilterData.promoMechanicJson },
      { key: "attribute", value: applyFilterData.attributeIdsJson },
      { key: "searchName", value: applyFilterData.searchName },
      { key: "description", value: applyFilterData.description },
      { key: "sorting", value: applyFilterData.sortPrefrence },

      {
        key: "promoPriceMin",
        value: parseFloat(applyFilterData.priceRangeFrom),
      },
      {
        key: "promoPriceMax",
        value: parseFloat(applyFilterData.priceRangeTo),
      },
      {
        key: "discountMin",
        value: parseFloat(applyFilterData.discountRangeFrom),
      },
      {
        key: "discountMax",
        value: parseFloat(applyFilterData.discountRangeTo),
      },
      {
        key: "saveSearchId",
        value: "" + applyFilterData.id,
      },
    ]);
    if (this.state.isExpired === true) {
      UpdateJson([
        { key: "fromDate", value: moment(new Date()).format("DD/MM/YYYY") },
        {
          key: "toDate",
          value: moment(new Date(this.getPreviousDate())).format("DD/MM/YYYY"),
        },
      ]);
    } else {
      UpdateJson([
        { key: "fromDate", value: applyFilterData.searchFromDate },
        { key: "toDate", value: applyFilterData.searchToDate },
      ]);
    }
    window.location.href = "/offers";
  }

  handleEditClose = () => {
    this.setState({ openEdit: false });
  };

  handleClose = (boolValue) => {
    this.setState({ open: false });
  };

  handleDelete = (itemId) => {
    ApiList.deleteSaveSearch(itemId, userData.userId).then((response) => {
      this.setState({ deleteResponse: response.data });
      const newToken = response.headers.token;
      const userData = JSON.parse(localStorage.getItem("userData"));
      const newUserData = { ...userData, token: newToken };
      localStorage.setItem("userData", JSON.stringify(newUserData));
      this.props.enqueueSnackbar("Saved filter deleted Successfully", {
        variant: "error",
      });
      this.props.getSaveSearchData();
    });
  };

  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { saveSearchData } = this.props;
    const dataPerPage = 100;
    const indexOfLastPost = this.state.currentPage * dataPerPage;
    const indexOfFirstPost = indexOfLastPost - dataPerPage;
    const currentData = saveSearchData.slice(indexOfFirstPost, indexOfLastPost);
    let searchedTableData = [];
    for (var i = 0; i < currentData.length; i++) {
      const element = currentData[i];
      if (
        element.searchName
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
      ) {
        searchedTableData.push(element);
      }
    }
    if (saveSearchData !== "{}") {
      return (
        <>
          <div style={{ border: "10px" }}>
            <input
              style={{
                display: "flex",
                flexDirection: "row",
                height: "30px",
                width: "453px",
                margin: "0 auto 15px",
                border: "1px solid #AAAAAA",
                borderRadius: "5px",
              }}
              className="srchBarPadding"
              id="free-solo-2-demo"
              value={this.props.filterValue}
              placeholder="Search .........."
              onChange={(event) => this.handleSearch(event.target.value)}
            />

            <span style={savedBarFilterStyles.totalSavedRecords}>
              Total Records:&nbsp;
              {saveSearchData.length}
            </span>
            <br></br>

            <Grid container>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  className="saved-filters-table-container"
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead style={{ height: "50px" }}>
                      <TableRow>
                        <TableCell
                          sx={{ width: 49 }}
                          className="saved-filters-table-cell"
                          component="th"
                          scope="column"
                        >
                          S.no
                        </TableCell>
                        <TableCell
                          sx={{ width: 120 }}
                          className="saved-filters-table-cell"
                        >
                          View Name
                        </TableCell>

                        <TableCell
                          sx={{ width: 222 }}
                          className="saved-filters-table-cell"
                        >
                          Description
                        </TableCell>

                        <TableCell
                          sx={{ width: 100 }}
                          className="saved-filters-table-cell"
                        >
                          Created By
                        </TableCell>
                        <TableCell
                          sx={{ width: 92 }}
                          className="saved-filters-table-cell"
                        >
                          Created Date
                        </TableCell>
                        <TableCell
                          sx={{ width: 92 }}
                          className="saved-filters-table-cell"
                        >
                          Updated By
                        </TableCell>
                        <TableCell
                          sx={{ width: 110 }}
                          className="saved-filters-table-cell"
                        >
                          Shared By
                        </TableCell>
                        <TableCell
                          sx={{ width: 138 }}
                          className="saved-filters-table-cell"
                        >
                          Shared Message
                        </TableCell>
                        <TableCell
                          sx={{ width: 110 }}
                          className="saved-filters-table-cell"
                        >
                          Apply Filter
                        </TableCell>
                        <TableCell
                          sx={{ width: 79 }}
                          className="saved-filters-table-cell"
                        >
                          <img
                            src={savedFilterViewHeaderIcon}
                            alt=""
                            style={{ marginBottom: "4px", marginRight: "6px" }}
                          />
                          View
                        </TableCell>

                        <TableCell
                          sx={{ width: 85 }}
                          className="saved-filters-table-cell"
                        >
                          <img
                            src={savedFilterShareHeaderIcon}
                            alt=""
                            style={{ marginBottom: "4px", marginRight: "6px" }}
                          />
                          Share
                        </TableCell>
                        <TableCell
                          sx={{ width: 80 }}
                          className="saved-filters-table-cell"
                        >
                          <img
                            src={savedFilterDeleteHeaderIcon}
                            alt=""
                            style={{ marginBottom: "4px", marginRight: "6px" }}
                          />
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {saveSearchData.length ? (
                      <TableBody style={{ height: "473" }}>
                        {searchedTableData.map((item, index) => {
                          const [day, month, year] =
                            item.createdDate.split("/");
                          const dateObject = new Date(
                            `${year}-${month}-${day}`
                          );
                          let createdDate = new Date(dateObject);
                          let validTo = new Date(permissionData?.validTo);
                          let validFrom = new Date(permissionData?.validFrom);
                          const isExpired = !(
                            createdDate >= validFrom && createdDate <= validTo
                          );
                          return (
                            <TableRow key={item.id}>
                              <TableCell
                                className="saved-filters-table-row"
                                component="th"
                                scope="row"
                              >
                                {(this.state.currentPage - 1) * dataPerPage +
                                  index +
                                  1}
                              </TableCell>

                              <TableCell className="saved-filters-table-row">
                                {item.searchName}
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                {item.description}
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                {item.createdByName}
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                {item.createdDate}
                              </TableCell>

                              <TableCell className="saved-filters-table-row">
                                {item.updatedByName}
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                {item.sharedBy}
                              </TableCell>

                              <TableCell className="saved-filters-table-row">
                                {item.sharedMessage}
                              </TableCell>

                              <TableCell className="saved-filters-table-row">
                                <div style={{ display: "flex" }}>
                                  <img
                                    onClick={() => {
                                      this.handleClick(item.id, isExpired);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      width: "100px",
                                    }}
                                    src={applyFiltersvg}
                                    alt=""
                                  />
                                  <Tooltip title="Saved date range access has expired. Default date selection will be applied.">
                                    <ExpiredRoundIcon
                                      style={{
                                        display: isExpired ? "block" : "none",
                                        marginTop: "7px",
                                        marginLeft: "3px",
                                        color: "#E24545",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.handleClickOpen();
                                    this.props.getSavedSearchViewData(item.id);
                                  }}
                                  src={view}
                                  alt=""
                                />
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                <img
                                  style={{
                                    cursor:
                                      item && !item.isShared
                                        ? "pointer"
                                        : "inherit",
                                  }}
                                  onClick={() => {
                                    item && !item.isShared
                                      ? this.handleClickOpenEdit(item.id)
                                      : console.log("");
                                  }}
                                  alt=""
                                  src={
                                    item && !item.isShared
                                      ? shareIcon
                                      : savedfilterdisable
                                  }
                                />
                              </TableCell>
                              <TableCell className="saved-filters-table-row">
                                <img
                                  style={{
                                    cursor:
                                      item && !item.isShared
                                        ? "pointer"
                                        : "inherit",
                                  }}
                                  alt=""
                                  src={
                                    item && !item.isShared
                                      ? deleteIcon
                                      : deletedisabled
                                  }
                                  onClick={() => {
                                    item && !item.isShared
                                      ? this.handleDelete(item.id)
                                      : console.log("");
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {
              <SaveFilterView
                handleClickOpen={this.handleClickOpen}
                open={this.state.open}
                handleClose={this.handleClose}
              />
            }
            {
              <SaveFilterShare
                shareItemId={this.state.shareItemId}
                SaveSearchShareData={this.state.searchByIdData}
                searchByIdData={this.state.searchByIdData}
                openEdit={this.state.openEdit}
                handleEditClose={this.handleEditClose}
              />
            }
            <SavedFilterPagination
              dataPerPage={dataPerPage}
              totalEntries={saveSearchData.length}
              currentPage={this.state.currentPage}
              paginate={this.paginate}
            />
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    saveSearchData: state.getSaveSearchData.saveSearchData,
    saveSearchDataCount: state.getSaveSearchData.savedSearchCount,
    applyFilterData: state.getApplySavedFilter.applyFilterData,
    SaveSearchShareData: state.getSaveSearchShareData.SaveSearchShareData,
    applyFilterFlag: state.getApplySavedFilter.savedFilterFlag,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSaveSearchData: () => dispatch(getSaveSearchData()),
    savedOfferCount: (count) => dispatch(savedOfferCount(count)),
    getSavedSearchViewData: (id) => dispatch(getSavedSearchViewData(id)),
    getApplyFilterData: (id) => dispatch(getApplyFilterData(id)),
    applyFilterDataFlag: (newValue) => dispatch(applyFilterDataFlag(newValue)),
    getSaveSearchShareData: (saveSearchId, companyId) =>
      dispatch(getSaveSearchShareData(saveSearchId, companyId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SavedFilter));
