import React, { Component } from "react";
import "../Login/LoginPage.css";
import ApiCalls from "../ApiList/apiCalls";
import { connect } from "react-redux";
import { getTnCObject } from "../../store/actions/termsAndConditionAction";
import { Link } from "react-router-dom";

const ApiList = new ApiCalls();
class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { initialize, profileView } = this.props;
    return (
      <section style={{ position: "relative" }}>
        <div
          className="login-page-footer"
          style={{
            height: "unset",
            position: "fixed",
          }}
        >
          <span className="copyright-text">
            Copyright ©{new Date().getFullYear()} ClicFlyer.com All Rights
            Reserved
          </span>
          <div className="first-seprating-line">|</div>
          <a
            className="terms-and-condition"
            onClick={() => {
              ApiList.getTermsandCondition(this.props.tAndC).then(
                (response) => {
                  const newToken = response.headers.token;
                  const userData = JSON.parse(localStorage.getItem("userData"));
                  const newUserData = { ...userData, token: newToken };
                  localStorage.setItem("userData", JSON.stringify(newUserData));
                }
              );
              window.location.href = "/termsandconditions";
            }}
          >
            Terms and Conditions
          </a>
          <div className="second-seprating-line">|</div>
          <Link to="/contact" className="contact-us">
            Contact Us
          </Link>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    TnCData: state.getTermsandCondition,
    initialize: state.userPermissionOfferData.initialize,
    offerData: state.userPermissionOfferData.imageData,
    profileView: state.getProfile.profileView,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTnCObject: (TnCData) => dispatch(getTnCObject(TnCData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
