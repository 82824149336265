const duplicateStyles = {
  divStyling: {
    display: "flex",
  },
  buttonSizeStyling: {
    display:"flex",
    justifyContent:"center",
   alignItems:"center", 
    width: "100%",
    height: "28px",
    color: "#2B2B2B",
    textTransform: "none",
  },
};

export default duplicateStyles;
