const profilePopupStyles = {
  profilePopupBoxStyling: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    bgcolor: "linear-gradient(112.31deg, #C5E5CA -1.22%, #BDDDE7 99.85%)",
  },
  paperPropsSizeStyling: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        bgcolor: "red",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  transformOriginStyling: { horizontal: "right", vertical: "top" },
  anchorOriginStyling: {
    horizontal: "right",
    vertical: "bottom",
  },
  divider: {
    background: "#757575",
    marginLeft: "12px",
    marginRight: "10px",
  },
};


export default profilePopupStyles