import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import deleteIcon from "../../images/deleteIcon.png";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import imageStyles from "../../styling/imageStyles";
import Checkbox from "@mui/material/Checkbox";
import { getSavedOfferObject } from "../../store/actions/savedOfferAction";
import ImagePopUp from "../OfferData/imagePopUp";
import {
  getOfferDetailsObject,
  getOfferDetailsSuccess,
} from "../../store/actions/offerDetailsAction";
import {
  removeCartSelectedOfferItem,
  removeSavedOfferObject,
  selectedCartOfferItems,
} from "../../store/actions/removeSavedOfferAction";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import {
  exportSavedOffersList,
  removeItemFromExportSavedOffersList,
} from "../../store/actions/exportSavedOfferAction";
import ApiCalls from "../ApiList/apiCalls";
import Loader from "../CommonFiles/Loader";
import Tooltip from "@mui/material/Tooltip";
import "./SavedOffer.css";
import { updateSavedOfferCount } from "../../store/actions/userManagerAction";

const ApiList = new ApiCalls();
class CartImageList extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      open: false,
      selected: false,
      loading: false,
    };
  }
  componentDidMount() {
    document.removeEventListener("removecount", this.onChange);
    document.addEventListener("removecount", this.onChange.bind(this));
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.cartOfferData !== prevProps.cartOfferData &&
      this.props.cartOfferData.length
    ) {
      this.myRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }
  onChange(ev) {
    this.props.postSavedOfferObject(this.props.savedOfferDataSorting, true);
    this.props.updateSavedOfferCount(ev.detail.removeCount);
  }
  handleClickOpen = async (offerImageId) => {
    await this.props.getOfferDetailsObject(offerImageId);
    this.setState({ open: true });
  };
  handleClose = (boolValue) => {
    this.setState({ open: false });
    this.props.getOfferDetailsSuccess({});
  };

  itemForExport = () => {
    const itemForExportList = this.props.cartOfferData?.map((offer) =>
      offer.savedOffers.filter(
        (item) => item.id === this.props.offerDetails.objOffferDetail.id
      )
    );
    return itemForExportList;
  };

  splitDate = (date) => {
    const dateValues = date.split("/");
    const dateUptoMonth = dateValues[0] + "/" + dateValues[1];
    return dateUptoMonth;
  };
  handleCheckBoxForSavedOffers = (event, item) => {
    if (event.target.checked) {
      this.props.exportSavedOffersList(item);
      this.props.selectedCartOfferItems(item);
    } else {
      this.props.removeItemFromExportSavedOffersList(item);
      this.props.removeCartSelectedOfferItem(item);
    }
  };

  savedOffersChecked = (itemId) => {
    const selectedOfferItemIds = this.props.savedOffersForRemoveList.map(
      (offer) => offer.id
    );
    if (selectedOfferItemIds.includes(itemId)) {
      return true;
    }
    return false;
  };

  handleDelete = async (itemId) => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await this.props.removeSavedOfferObject(itemId, true);
    await delay(300);
    const message = `Saved Offer Removed Successfully`;
    this.props.enqueueSnackbar(message, {
      variant: "error",
    });
  };

  render() {
    const {
      cartOfferData,
      exportSavedOfferItems,
      savedOfferDataSorting,
      savedOffersForRemoveList,
      offerDetails,
      itemForExportList,
      offerData,
      savedOffer,
      cartLoading,
      savedOfferLoading,
    } = this.props;

    if (JSON.stringify(cartOfferData) != "{}") {
      return (
        <>
          <Loader open={this.props.savedOfferLoading} />
          {cartOfferData.length ? (
            <>
              <div
                className="customContainer"
                ref={this.myRef}
                style={imageStyles.imageCartDivStyling}
              >
                {cartOfferData?.map((offer) => (
                  <Grid key={offer.id} item xs={12} className="we">
                    <Grid item xs={12}>
                      <br />
                      <b>
                        <span style={{}}>{offer.nameEn}</span>
                      </b>
                      <br />
                      <br />
                    </Grid>
                    <Grid container spacing={4} align="center">
                      {offer.savedOffers.map((item) => (
                        <Grid key={item.id} item xs={3}>
                          <div style={imageStyles.imageListDivStyle}>
                            {!this.props.savedOffersList && (
                              <div
                                style={{
                                  cursor: "pointer",
                                  paddingTop: "0px",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                  paddingBottom: ".7rem",
                                  color: "#fff",
                                  textTransform: "none",
                                  marginTop: "0.1rem",
                                  marginLeft: "0.1rem",
                                  height: "2rem",
                                  transform: "translate(1px,  1px)",
                                  width: "87px",
                                  height: "24px",
                                  borderRadius: "2px",
                                }}
                              ></div>
                            )}
                            <div style={{ position: "relative" }}>
                              <Checkbox
                                sx={{
                                  "&:hover": {
                                    color: "#E4EEFF",
                                    backgroundColor: "#E4EEFF",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "#0070C0",
                                  },
                                  backgroundColor: "#FFF",
                                }}
                                style={imageStyles.checkBoxStyling}
                                checked={this.savedOffersChecked(item.id)}
                                onChange={(event) => {
                                  this.handleCheckBoxForSavedOffers(
                                    event,
                                    item
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <Card sx={{ minHeight: 438, position: "relative" }}>
                            <CardMedia
                              onClick={() => {
                                this.handleClickOpen(item.id);
                              }}
                              sx={{ objectFit: "contain", cursor: "pointer" }}
                              component="img"
                              height="287"
                              image={`${item.image}`}
                              alt="green iguana"
                            />

                            <CardContent style={{ padding: "0px" }}>
                              <Tooltip title={item.nameEn}>
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    lineHeight: "19px",
                                    color: "#383737",
                                    marginBottom: "0px",
                                    padding: "14px 18px 0px 18px",
                                  }}
                                >
                                  {item.nameEn != null
                                    ? item.nameEn.length > 50
                                      ? `${item.nameEn.substring(0, 50)}...`
                                      : `${item.nameEn}`
                                    : ""}
                                </p>
                              </Tooltip>

                              <Grid
                                container
                                align="left"
                                sx={{ position: "absolute", bottom: "10%" }}
                              >
                                <Grid item xs={6} sx={{ paddingLeft: "18px" }}>
                                  <p style={{ display: "inline-grid" }}>
                                    {item.regularPrice > 0 ? (
                                      <span
                                        style={{
                                          color: "#A1A1A1",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          lineHeight: "20px",
                                          letterSpacing: "0.5px",
                                          textDecorationLine: "line-through",
                                        }}
                                      >
                                        {item.regularPrice > 0
                                          ? `${item.currency} ${item.regularPrice}`
                                          : null}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: "#A1A1A1",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          lineHeight: "20px",
                                          letterSpacing: "0.5px",
                                          textDecorationLine: "line-through",
                                        }}
                                      ></span>
                                    )}
                                    <span
                                      style={{
                                        color: "#373737",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        letterSpacing: "0.5px",
                                      }}
                                    >
                                      {item.promoPrice > 0 ? (
                                        `${item.currency} ${item.promoPrice}`
                                      ) : (
                                        <>&nbsp;</>
                                      )}
                                    </span>
                                  </p>

                                  <p></p>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: "flex",
                                    marginTop: ".4rem",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "17px",
                                      height: "34px",
                                      borderLeft: "solid 9px #FFF",
                                      borderBottom: "solid 16px transparent",
                                      borderTop: "solid 16px transparent",
                                      transform: "translate(16px, 0px)",
                                    }}
                                  ></div>

                                  {item.discount ? (
                                    <p
                                      style={{
                                        background: "#CC1E0D",
                                        width: "124px",
                                        height: "34px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          lineHeight: "16px",
                                          textAlign: "center",
                                          letterSpacing: "0.5px",
                                          color: "#FBF300",
                                          paddingLeft: "12px",
                                        }}
                                      >
                                        {Math.ceil(item.discount)}% off
                                      </span>
                                    </p>
                                  ) : null}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                sx={{
                                  padding: "0px 18px",
                                  position: "absolute",
                                  bottom: "5px",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={1.5}>
                                  <img
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      objectFit: "contain",
                                      background: "#FFFFFF",
                                      boxShadow:
                                        "0px 0px 3.27218px -0.654435px rgba(0, 0, 0, 0.25)",
                                    }}
                                    src={`${item.logo}`}
                                  />
                                </Grid>
                                <Grid item xs={9}>
                                  <p
                                    style={{
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "#2B2B2B",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    {`From ${this.splitDate(
                                      item.offerStartDate
                                    )}  To  ${this.splitDate(
                                      item.offerEndDate
                                    )}`}
                                  </p>
                                </Grid>

                                <Grid item xs={1.5}>
                                  <img
                                    className="saved-offer-delete-icon"
                                    variant="outlined"
                                    style={{}}
                                    src={deleteIcon}
                                    onClick={() => this.handleDelete(item.id)}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}

                {JSON.stringify(this.props.offerDetails) != "{}" ? (
                  offerDetails.objOffferDetail ? (
                    <ImagePopUp
                      savedOfferGrid={true}
                      handleClickOpen={this.handleClickOpen}
                      open={this.state.open}
                      offerDetails={offerDetails}
                      savedOffer={savedOffer}
                      getSavedOfferObject={getSavedOfferObject}
                      enqueueSnackbar={this.props.enqueueSnackbar}
                      handleClose={this.handleClose}
                      itemForExport={this.itemForExport()}
                    />
                  ) : null
                ) : null}
              </div>
            </>
          ) : (
            <div
              style={{
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <h1>
                <b>No Data Found</b>
              </h1>
            </div>
          )}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
    cartLoading: state.postSavedOfferData.loading,
    exportSavedOfferItems: state.exportSavedOffer.exportSavedOffersList,
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    savedOffersForRemoveList: state.removeSavedOffer.selectedCartOfferItems,
    offerDetails: state.getOfferDetails.offerDetails,
    savedOfferLoading: state.postSavedOfferData.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSavedOfferObject: (itemId) => dispatch(getSavedOfferObject(itemId)),
    getOfferDetailsObject: (offerId) =>
      dispatch(getOfferDetailsObject(offerId)),
    removeSavedOfferObject: (itemId) =>
      dispatch(removeSavedOfferObject(itemId, true)),
    postSavedOfferObject: (postObj) =>
      dispatch(postSavedOfferObject(postObj, true)),
    exportSavedOffersList: (offerItem) =>
      dispatch(exportSavedOffersList(offerItem)),
    selectedCartOfferItems: (savedOfferItem) =>
      dispatch(selectedCartOfferItems(savedOfferItem)),
    removeItemFromExportSavedOffersList: (item) =>
      dispatch(removeItemFromExportSavedOffersList(item)),
    removeCartSelectedOfferItem: (offerItem) =>
      dispatch(removeCartSelectedOfferItem(offerItem)),
    getOfferDetailsSuccess: (offer) => dispatch(getOfferDetailsSuccess(offer)),
    updateSavedOfferCount: (newValue) =>
      dispatch(updateSavedOfferCount(newValue)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CartImageList));
