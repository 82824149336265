const savedBarFilterStyles = {
  totalSavedRecords: {
    //marginLeft: "45%",
    marginLeft: "45%",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "12px",
    color: "#5A9E2D",
  },
};

export default savedBarFilterStyles;
