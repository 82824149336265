import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { postSavedOfferObject } from "../../store/actions/postSavedOfferAction";
import { updateSavedOfferSorting } from "../../store/actions/savedOfferAction";

class CartPreference extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <FormControl
          className="cart-select"
          sx={{
            width: "100px",
            "& .MuiSelect-select": {
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              color: "#757474",
            },

            "& .MuiInputBase-root": {
              height: "30px",
              paddingTop: "4px",
            },
          }}
        >
          {/* <InputLabel id="demo-simple-select-label">Sort</InputLabel> */}
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={5}
            disabled={!this.props.cartOfferData.length}
            value={this.props.savedOfferDataSorting.sortType}
            onChange={(event) => {
              {event.target.value != 5 &&
              this.props.savedOfferDataSorting.sortOrder == "L"
                ? this.props.updateSavedOfferSorting({
                    ...this.props.savedOfferDataSorting,
                    sortType: event.target.value,
                    sortOrder: "N",
                  })
                : this.props.updateSavedOfferSorting({
                    ...this.props.savedOfferDataSorting,
                    sortType: event.target.value,
                  });}
              
              this.props.postSavedOfferObject(
                {
                  ...this.props.savedOfferDataSorting,
                  sortType: event.target.value,
                },
                true
              );
              //var b = window.scrollHeight - window.clientHeight;
            }}
          >
            <MenuItem sx={{ fontSize: "13px" }} value={0}>
              Brand
            </MenuItem>
            <MenuItem sx={{ fontSize: "13px" }} value={1}>
              Category
            </MenuItem>
            <MenuItem sx={{ fontSize: "13px" }} value={2}>
              Item
            </MenuItem>
            <MenuItem sx={{ fontSize: "13px" }} value={3}>
              Retailer
            </MenuItem>
            <MenuItem sx={{ fontSize: "13px" }} value={5}>
              All Offer
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    savedOfferDataSorting: state.savedOfferData.savedOfferSorting,
    cartOfferData: state.postSavedOfferData.savedOfferObjectData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postSavedOfferObject: (sortType, sortOrder) =>
      dispatch(postSavedOfferObject(sortType, sortOrder)),
    updateSavedOfferSorting: (sorting) =>
      dispatch(updateSavedOfferSorting(sorting)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartPreference);
