import {
  GET_FLYER_PAGES_REQUEST,
  GET_FLYER_PAGES_SUCCESS,
  GET_FLYER_PAGES_FAILURE,
} from "../actionTypes";

const initialState = {
  loading: true,
  flyerData: {},
  error: "",
};

const getFlyerPagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLYER_PAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FLYER_PAGES_SUCCESS:
      return {
        loading: false,
        flyerData: action.payload,
        error: "",
      };
    case GET_FLYER_PAGES_FAILURE:
      return {
        loading: false,
        flyerData: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getFlyerPagesReducer;
