import React, { Component } from "react";
import "./WelcomeScreen.css";

class WelcomeScreen extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <section className="welcome-screen-main-section">
        <div className="welcome-screen-div">
          <p className="welcome-screen-heading">Welcome to Offer Bank</p>

          <p className="welcome-screen-para">
            Select your search criteria to view offers
          </p>
        </div>
      </section>
    );
  }
}

export default WelcomeScreen;
