import React, { Component } from "react";
import Button from "@mui/material/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { connect } from "react-redux";
import {
  clearSelectedOfferItems,
  getSavedOfferObject,
  selectedOfferItems,
} from "../../store/actions/savedOfferAction";
import { withSnackbar } from "notistack";

import { getOfferByUserPermission } from "../../store/actions/offerImageActions";
import Loader from "../CommonFiles/Loader";
import { removeAllOfferFromExportList } from "../../store/actions/exportSavedOfferAction";
import addToSaveOfferButtonIcon from "../../images/addToSaveOfferButtonIcon.svg";
import addToCart from "../../images/addToCart.svg";
import Tooltip from "@mui/material/Tooltip";

class AddToSaveOffer extends Component {
  render() {
    const {
      formData,
      savedOffer,
      selectedItems,
      getSavedOfferObject,
      enqueueSnackbar,
      exportOfferItems,
      getOfferByUserPermission,
      loadingByOfferPermission,
    } = this.props;
    return (
      <>
        <Loader open={loadingByOfferPermission} />

        <button
          style={{
            position: "relative",
            maxWidth: "92px",
            width: "100%",
            height: "30px",
            color: exportOfferItems.length ? "#208EDC" : "#C4C4C4",
            backgroundColor: "white",
            textTransform: "none",
            border: exportOfferItems.length
              ? "1px solid #208EDC"
              : "1px solid #C4C4C4",
            borderRadius: "5px",
            marginLeft: "7px",
            padding: "0 9px",
            display: "flex",
            flex: "1 1",
            justifyContent: "center",
          }}
          disabled={!exportOfferItems.length}
          checked={true}
          onClick={async () => {
            if (!selectedItems.length) {
              const message = "No Offers Selected.";
              enqueueSnackbar(message, {
                variant: "error",
              });
            } else {
              await getSavedOfferObject(selectedItems.toString());
              const message = `${selectedItems.length} Item(s) Added to Saved Offer Successfully`;
              enqueueSnackbar(message, {
                variant: "success",
              });
            }
            await this.props.clearSelectedOfferItems();
            await this.props.removeAllOfferFromExportList();
            await getOfferByUserPermission("main", true);
          }}
        >
          <img
            src={exportOfferItems.length ? addToCart : addToSaveOfferButtonIcon}
            className="add-to-save-offer-icon"
          />
          <Tooltip
            title={
              !exportOfferItems.length
                ? "Please select an offer"
                : "Click to Save"
            }
            TransitionProps={{ timeout: 200 }}
          >
            <span
              className="addToSaveOffer-buttontext"
              style={{
                color: exportOfferItems.length ? "#208EDC" : "#C4C4C4",
              }}
            >
              Save
            </span>
          </Tooltip>
        </button>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formData: state.filterForm,
    loadingByOfferPermission: state.userPermissionOfferData.loading,
    savedOffer: state.savedOfferData.savedOfferObject.saveOfferObj,
    selectedItems: state.savedOfferData.selectedOfferItems,
    exportOfferItems: state.exportSavedOffer.exportOfferList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectedOfferItems: (itemId) => dispatch(selectedOfferItems(itemId)),
    getSavedOfferObject: (itemId) => dispatch(getSavedOfferObject(itemId)),
    getOfferByUserPermission: () =>
      dispatch(getOfferByUserPermission("main", true)),
    clearSelectedOfferItems: () => dispatch(clearSelectedOfferItems()),
    removeAllOfferFromExportList: () =>
      dispatch(removeAllOfferFromExportList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AddToSaveOffer));
