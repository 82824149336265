import {
  FETCH_ALL_DATA_BY_PERMISSION_REQUEST,
  FETCH_ALL_DATA_BY_PERMISSION_SUCCESS,
  FETCH_ALL_DATA_BY_PERMISSION__FAILURE,
} from "../actionTypes";

const initialState = {
  loading: false,
  dataByPermission: {},
  error: "",
};

const getDataByPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_DATA_BY_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_DATA_BY_PERMISSION_SUCCESS:
      return {
        loading: false,
        dataByPermission: action.payload,
        error: "",
      };
    case FETCH_ALL_DATA_BY_PERMISSION__FAILURE:
      return {
        loading: false,
        dataByPermission: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getDataByPermissionReducer;
