import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";
import {
  GET_SAVE_SEARCH_SHARE_REQUEST,
  GET_SAVE_SEARCH_SHARE_SUCCESS,
  GET_SAVE_SEARCH_SHARE_FAILURE,
} from "../actionTypes";

const ApiList = new ApiCalls();
export const getSaveSearchShareRequest = () => {
  return {
    type: GET_SAVE_SEARCH_SHARE_REQUEST,
  };
};

export const getSaveSearchShareSuccess = (SaveSearchShareData) => {
  return {
    type: GET_SAVE_SEARCH_SHARE_SUCCESS,
    payload: SaveSearchShareData,
  };
};

export const getSaveSearchShareFailure = (error) => {
  return {
    type: GET_SAVE_SEARCH_SHARE_FAILURE,
    payload: error,
  };
};

export const getSaveSearchShareData = (saveSearchId, companyId) => {
  return (dispatch) => {
    dispatch(getSaveSearchShareRequest());
    ApiList.getSavedSearchShareById(saveSearchId, companyId)
      .then((response) => {
        const SaveSearchShareData = response.data;
        dispatch(getSaveSearchShareSuccess(SaveSearchShareData));
        const newToken = response.headers.token;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const newUserData = { ...userData, token: newToken };
        localStorage.setItem("userData", JSON.stringify(newUserData));

         const customEvent = new CustomEvent("SahreData", {
           detail: {
             data: response.data,
           },
         });
          document.dispatchEvent(customEvent);
      })
      .catch((error) => {
        UnauthorisedUser(error.response.status);
        dispatch(getSaveSearchShareFailure(error.message));
      });
  };
};
