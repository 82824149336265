import axios from "axios";
import ApiCalls from "../../components/ApiList/apiCalls";
import {
  OFFER_IMAGE_SUCCESS,
  OFFER_IMAGE_FAILURE,
  OFFER_IMAGE_REQUEST,
  UPDATE_VIEW_BY,
  UPDATE_VIEW,
  TOGGLE_INITIALIZE,
} from "../actionTypes";

import { store } from "../../index";
import UnauthorisedUser from "../../components/CommonFiles/Unauthorised";

const ApiList = new ApiCalls();

export const postFormDataRequest = (offerImageData) => {
  return {
    type: OFFER_IMAGE_REQUEST,
    payload: offerImageData,
  };
};

export const postFormDataSuccess = (offerImageData) => {
  return {
    type: OFFER_IMAGE_SUCCESS,
    payload: offerImageData,
  };
};

export const postFormDataFailure = (error) => {
  return {
    type: OFFER_IMAGE_FAILURE,
    payload: error,
  };
};

export const updateViewBy = (viewByData) => {
  return {
    type: UPDATE_VIEW_BY,
    payload: viewByData,
  };
};

export const updateView = (viewData) => {
  return {
    type: UPDATE_VIEW,
    payload: viewData,
  };
};

export const toggleInitialize = (value) => {
  return {
    type: TOGGLE_INITIALIZE,
    payload: value,
  };
};

export const getOfferByUserPermission = (
  src = "main",
  loading = false
) => {
  return (dispatch) => {
    const userFilterData = JSON.parse(localStorage.getItem("userFilterData"));
    if (src == "priceRange" && userFilterData.promoPriceMax <= 0) {
      const data = {
        facetModel: {
          inputPackSizeModel: [],
          inputBrandFacetModel: [],
          inputPromomechanicFacetModel: [],
          inputProductFacetModel: [],
          inputAttributeFacetModel: [],
          maxPromoPrice: 0.0,
          minPromoPrice: 0.0,
          discountMax: 0.0,
          discountMin: 0.0,
        },
        offerLibraryDisplays: [],
      };
      dispatch(postFormDataSuccess(data));
    } else {
      if (loading) {
        dispatch(postFormDataRequest());
      }
      ApiList.getOfferByUserPermission()
        .then(async (response) => {
          const newToken = response.headers.token;
          const userData = JSON.parse(localStorage.getItem("userData"));
          const newUserData = { ...userData, token: newToken };
          localStorage.setItem("userData", JSON.stringify(newUserData));
          const offerImageData = response.data;
          dispatch(toggleInitialize(true));
          await dispatch(postFormDataSuccess(offerImageData));
          if (offerImageData.length != 0) {
            const customEvent = new CustomEvent("offerdetails", {
              detail: {
                brand: offerImageData.facetModel.inputBrandFacetModel,
                product: offerImageData.facetModel.inputProductFacetModel,
                packsize: offerImageData.facetModel.inputPackSizeModel,
                promoType:
                  offerImageData.facetModel.inputPromomechanicFacetModel,
                attribute: offerImageData.facetModel.inputAttributeFacetModel,
                discountPriceRange: [
                  offerImageData.facetModel.discountMin,
                  offerImageData.facetModel.discountMax,
                ],
                offerPriceRange: [
                  offerImageData.facetModel.minPromoPrice,
                  offerImageData.facetModel.maxPromoPrice,
                ],
                source: src,
              },
            });
            document.dispatchEvent(customEvent);
          }
        })
        .catch((error) => {
          UnauthorisedUser(error.response.status);
          dispatch(postFormDataFailure(error.message));
        });
    };
}
};
